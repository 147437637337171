import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ModelSearch = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  const handleSearch = () => {
    if (searchTerm.trim()) {
      navigate(`/search?q=${encodeURIComponent(searchTerm.trim())}`);
    }
  };


  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <div className="px-0 pb-4">
      <div className="relative flex items-center">
        <input 
          type="text"
          placeholder="Find by name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyPress={handleKeyPress}
          className="bg-white w-full px-4 py-1.5 rounded-l-full border-t border-b border-l border-gray-300  focus:outline-none"
          style={{
            fontFamily: 'InterRegular'
          }}
        />
        <button 
          onClick={handleSearch}
          className="px-4 py-2.5 bg-white border-r border-t border-b border-gray-300 text-gray-500 rounded-r-full"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-4" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default ModelSearch;