import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import BASE_URL from '../config';
import '../styles/fonts.css';


const TypeSelector = ({ formData, setFormData }) => {
  return (
    <div className="space-y-2">
      <label className="block text-sm font-medium text-gray-700">
        Type
      </label>
      <div className="flex items-center">
        <button
          type="button"
          className="flex w-full rounded-md border-2 border-gray-300 overflow-hidden"
        >
          <span 
            onClick={() => setFormData(prev => ({
              ...prev,
              agency: prev.agency === 'false' ? '' : 'false'
            }))}
            className={`w-1/2 py-2 text-center transition-colors duration-200 
              ${formData.agency === 'false' 
                ? 'bg-red text-white' 
                : 'bg-white text-gray-700 hover:bg-gray-50'}`}
          >
            Escort
          </span>
          <span 
            onClick={() => setFormData(prev => ({
              ...prev,
              agency: prev.agency === 'true' ? '' : 'true'
            }))}
            className={`w-1/2 py-2 text-center transition-colors duration-200 
              ${formData.agency === 'true' 
                ? 'bg-red text-white' 
                : 'bg-white text-gray-700 hover:bg-gray-50'}`}
          >
            Agency
          </span>
        </button>
      </div>
    </div>
  );
};


const LoginPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from || '/Dashboard';
    const { login } = useAuth();
    const [isSigningUp, setIsSigningUp] = useState(false);
    const [isResetting, setIsResetting] = useState(false);
    const [resetEmail, setResetEmail] = useState('');
    const [resetMessage, setResetMessage] = useState('');
    const [userType, setUserType] = useState('');
    const [error, setError] = useState(null);
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        accountType: 'unpaid',
        firstName: '',
        lastName: '',
        agency: ''
    });

const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
        ...prev,
        [name]: value
    }));
};

const handlePasswordReset = async (e) => {
  e.preventDefault();
  setError('');
  setResetMessage('');
  
  try {
      const response = await fetch(`${BASE_URL}/api/auth/reset-password`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({ email: resetEmail })
      });

      const data = await response.json();
      
      if (!response.ok) {
          throw new Error(data.error || 'Password reset request failed');
      }
      
      setResetMessage('If an account exists with this email, you will receive reset instructions shortly.');
      setResetEmail('');
  } catch (error) {
      setError(error.message);
  }
};

// Updated handleSubmit in your LoginPage.jsx
const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    
    // Validate email and password before submitting
    if (!formData.email || !formData.password) {
        setError('Email and password are required');
        return;
    }

    
    try {
        let response;

        if (isSigningUp) {
            response = await fetch(`${BASE_URL}/api/users/signup`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify({
                    agency: formData.agency,
                    firstName: formData.firstName,
                    lastName: formData.lastName,
                    email: formData.email,
                    password: formData.password
                })
            });
        } else {
            // Make sure we're sending exactly the right data format
            const loginData = {
                email: formData.email,
                password: formData.password
            };
            

            response = await fetch(`${BASE_URL}/api/auth/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify(loginData)
            });
        }

        // First check if the response is OK
        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error || `${isSigningUp ? 'Sign Up' : 'Login'} failed`);
        }
        
        // Get user data from response
        const userData = await response.json();
        
        // Login with the user data (no token in body anymore)
        await login(userData);
        
        // Navigate to the destination
        navigate(from, { replace: true });
        
    } catch (error) {
        console.error(`${isSigningUp ? 'Sign Up' : 'Login'} error`, error);
        setError(error.message);
    }
};


return (
  <div className="min-h-screen bg-gray-50" style={{ fontFamily: 'InterSB' }}>
      <header className="bg-white shadow">
          <div className="max-w-7xl mx-auto py-6 px-4">
              <h1 className="text-3xl font-bold text-gray-900 text-center">
                  {isResetting ? 'Reset Password' : isSigningUp ? 'Sign Up' : 'Login'}
              </h1>
          </div>
      </header>

      <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          <div className="px-4 py-6 sm:px-0">
              <div className="bg-white shadow rounded-lg p-6">
                  {error && (
                      <div className="mb-4 p-4 text-red-700 bg-red-100 rounded-md">
                          {error}
                      </div>
                  )}
                  {resetMessage && (
                      <div className="mb-4 p-4 text-green-700 bg-green-100 rounded-md">
                          {resetMessage}
                      </div>
                  )}

                  {isResetting ? (
                      <form onSubmit={handlePasswordReset} className="space-y-6">
                          <div>
                              <label className="block text-sm font-medium text-gray-700">
                                  Email
                              </label>
                              <input
                                  type="email"
                                  required
                                  value={resetEmail}
                                  onChange={(e) => setResetEmail(e.target.value)}
                                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                              />
                          </div>
                          <div className="flex items-center justify-center space-x-4">
                              <button
                                  type="submit"
                                  className="bg-white text-gray-400 px-4 py-2 rounded-full border border-solid border-gray-400 text-sm font-medium hover:bg-gray-200 flex items-center"
                              >
                                  Send Reset Link
                              </button>
                              <button
                                  type="button"
                                  onClick={() => setIsResetting(false)}
                                  className="text-sm text-elink hover:text-indigo-500"
                              >
                                  Back to Login
                              </button>
                          </div>
                      </form>
                  ) : (
                      <form onSubmit={handleSubmit} className="space-y-6">
                          {isSigningUp && (
                              <>
                                  <div>
                                      <TypeSelector 
                                          formData={formData} 
                                          setFormData={setFormData} 
                                      />
                                  </div>

                                  <div>
                                      <label className="block text-sm font-medium text-gray-700">
                                          First Name
                                      </label>
                                      <input
                                          type="text"
                                          name="firstName"
                                          required
                                          value={formData.firstName}
                                          onChange={handleChange}
                                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                                      />
                                  </div>

                                  <div>
                                      <label className="block text-sm font-medium text-gray-700">
                                          Last Name
                                      </label>
                                      <input
                                          type="text"
                                          name="lastName"
                                          required
                                          value={formData.lastName}
                                          onChange={handleChange}
                                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                                      />
                                  </div>
                              </>
                          )}

                          <div>
                              <label className="block text-sm font-medium text-gray-700" style={{
                                  fontFamily: 'InterSB'
                              }}>
                                  Email
                              </label>
                              <input
                                  type="email"
                                  name="email"
                                  required
                                  value={formData.email}
                                  onChange={handleChange}
                                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                              />
                          </div>

                          <div>
                              <label className="block text-sm font-medium text-gray-700" style={{
                                  fontFamily: 'InterSB'
                              }}>
                                  Password
                              </label>
                              <input
                                  type="password"
                                  name="password"
                                  required
                                  value={formData.password}
                                  onChange={handleChange}
                                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                              />
                          </div>

                          <div className="flex items-center justify-center">
                              <button
                                  type="submit"
                                  className="bg-white text-gray-400 px-4 py-2 rounded-full border border-solid border-gray-400 text-sm font-medium hover:bg-gray-200 flex items-center"
                                  style={{
                                      fontFamily: 'InterSB'
                                  }}
                              >
                                  {isSigningUp ? 'Sign Up' : 'Login'}
                              </button>
                          </div>

                          <div className="text-center space-y-2">
                              <button
                                  type="button"
                                  onClick={() => setIsSigningUp(!isSigningUp)}
                                  className="text-sm text-elink hover:text-elgrad"
                                  style={{
                                      fontFamily: 'InterSB'
                                  }}
                              >
                                  {isSigningUp
                                      ? 'Already have an account? Login'
                                      : 'Need an account? Sign Up'}
                              </button>
                              {!isSigningUp && (
                                  <div>
                                      <button
                                          type="button"
                                          onClick={() => setIsResetting(true)}
                                          className="text-sm text-elink hover:text-elgrad"
                                          style={{
                                              fontFamily: 'InterSB'
                                          }}
                                      >
                                          Forgot your password?
                                      </button>
                                  </div>
                              )}
                          </div>
                      </form>
                  )}
              </div>
          </div>
      </main>
  </div>
);
};
export default LoginPage;