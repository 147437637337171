import React, { useState, useEffect, useMemo } from 'react';
import { Camera, Users, Award, Mail } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { io } from 'socket.io-client';
import { jwtDecode } from 'jwt-decode';
import '../styles/fonts.css';
import fireEmoji from './fireemoji.png';
import verified from './verified.svg';
import AdminModelCards from '../components/AdminModelCards';
import ModelCards from '../components/ModelCards'; 
import BASE_URL from '../config';
import Footer from '../components/Footer';
import FilterSection from '../components/Filter';
import { CACHE_KEY, CACHE_DURATION } from '../constants/cache';


const LandingPage = () => {
  const [socket, setSocket] = useState(null)
  const [selectedLocation, setSelectedLocation] = useState('All');
  const [uniqueLocations, setUniqueLocations] = useState(['Wellington', 'Auckland', 'South Island', 'Hamilton', 'Christchurch', 'Tauranga', 'Central North Island']);
  const [isLoading , setIsLoading] = useState(true);
  const [models, setModels] = useState([]);
  const [currentUserId, setCurrentUserId] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();
  const [selectedFilters, setSelectedFilters] = useState({
    displayMode: 'all',
    age: null,
    nationality: null,
    height: null,
    bodyType: null
  });

  const navigateToLocation = (location) => {
    if (location === 'All') {
      navigate('/');
    } else {
      navigate(`/locations/${location}`);
    }
  };

const handleModelClick = (modelId) => {
  navigate(`/view/${modelId}`);
};

const updateSingleModel = async (modelId) => {
  try {
    const response = await fetch(`${BASE_URL}/api/LPmodels/${modelId}`);
    const updatedModel = await response.json();
    
    setModels(prevModels => {
      const newModels = prevModels.map(model => 
        model.modelId === modelId ? updatedModel : model
      );
      
      // Update cache with new data
      const cachedData = localStorage.getItem(CACHE_KEY);
      if (cachedData) {
        const { timestamp } = JSON.parse(cachedData);
        localStorage.setItem(CACHE_KEY, JSON.stringify({
          timestamp,
          data: newModels
        }));
      }
      
      return newModels;
    });
  } catch (error) {
    console.error('Error updating single model:', error);
  }
};

const updateSingleModelInCache = (modelId, updatedData) => {
  const cachedData = localStorage.getItem(CACHE_KEY);
  if (cachedData) {
    const { timestamp, data } = JSON.parse(cachedData);
    const updatedModels = data.map(model => 
      model.modelId === modelId ? { ...model, ...updatedData } : model
    );
    
    localStorage.setItem(CACHE_KEY, JSON.stringify({
      timestamp: Date.now(), // Update timestamp to reflect the change
      data: updatedModels
    }));
    
    setModels(updatedModels);
  }
};

const removeSingleModelFromCache = (modelId) => {
  const cachedData = localStorage.getItem(CACHE_KEY);
  if (cachedData) {
    const { timestamp, data } = JSON.parse(cachedData);
    const updatedModels = data.filter(model => model.modelId !== modelId);
    
    localStorage.setItem(CACHE_KEY, JSON.stringify({
      timestamp: Date.now(),
      data: updatedModels
    }));
    
    setModels(updatedModels);
  }
};


// useEffect(() => {
//  const getModels = async () => {
//   try{
//     const response = await fetch(`${BASE_URL}/api/LPmodels`);
//     const data = await response.json();
//     setModels(data);
//   } catch (error) {
//     console.error('Error fetching models:', error);
//   } finally {
//     setIsLoading(false);
//   }
//  }; 

// const getModels = async () => {
//   try {
//     const response = await fetch(`${BASE_URL}/api/LPmodels`);
//     const data = await response.json();
//     // console.log('Received data:', data); // Check data structure
//     // console.log('Is array?', Array.isArray(data)); // Check if it's an array
//     setModels(Array.isArray(data) ? data : []);
//   } catch (error) {
//     console.error('Error fetching models:', error);
//     setModels([]);
//   } finally {
//     setIsLoading(false);
//   }
// };

///12/2/25
// useEffect(() => {
// const getModels = async () => {
//   try {
//     console.log('Fetching models from:', `${BASE_URL}/api/LPmodels`);
//     const response = await fetch(`${BASE_URL}/api/LPmodels`);
//     const data = await response.json();
//     console.log('Raw models data:', {
//       count: data.length,
//       sampleImage: data[0]?.ImageUrls,
//       allUrls: data.slice(0, 2).map(m => ({
//         modelId: m.modelId,
//         imageUrls: m.ImageUrls
//       }))
//     });
//     setModels(Array.isArray(data) ? data : []);
//   } catch (error) {
//     console.error('Error fetching models:', error);
//     setModels([]);
//   } finally {
//     setIsLoading(false);
//   }
// };
//   getModels();
// }, []);
//  useEffect(() => {
//     const getModels = async () => {
//       try {
//         setIsLoading(true);
        
//         // Check cache first
//         const cachedData = localStorage.getItem(CACHE_KEY);
//         if (cachedData) {
//           const { timestamp, data } = JSON.parse(cachedData);
//           const isExpired = Date.now() - timestamp > CACHE_DURATION;
          
//           if (!isExpired && Array.isArray(data)) {
//             console.log('Using cached data');
//             setModels(data);
//             setIsLoading(false);
//             return;
//           }
//         }

//         // Fetch fresh data if cache is expired or empty
//         console.log('Fetching fresh data from:', `${BASE_URL}/api/LPmodels`);
//         const response = await fetch(`${BASE_URL}/api/LPmodels`, {
//           headers: {
//             'Cache-Control': 'max-age=3600'
//           }
//         });
        
//         const data = await response.json();
        
//         // Update cache
//         localStorage.setItem(CACHE_KEY, JSON.stringify({
//           timestamp: Date.now(),
//           data: Array.isArray(data) ? data : []
//         }));
        
//         setModels(Array.isArray(data) ? data : []);
//       } catch (error) {
//         console.error('Error fetching models:', error);
//         setModels([]);
//       } finally {
//         setIsLoading(false);
//       }
//     };

//     getModels();
//   }, []);


// Socket connection with update handlers
// useEffect(() => {
//   const socketUrl = window.location.origin;
//   const newSocket = io(socketUrl, {
//     withCredentials: true,
//     transports: ['polling', 'websocket'],
//     path: '/socket.io/',
//     reconnection: true,
//     reconnectionAttempts: 10,
//     reconnectionDelay: 1000,
//     reconnectionDelayMax: 5000,
//     timeout: 20000,
//     autoConnect: true,
//     debug: false,
//     logger: false
//   });

//   newSocket.on('connect', () => {
//     console.log('Socket connected');
//   });

//   // Add handlers for profile updates
//   newSocket.on('profileUpdate', (data) => {
//     console.log('Profile update received:', data);
//     updateSingleModel(data.modelId);
//   });

//   newSocket.on('profileDelete', (modelId) => {
//     setModels(prevModels => {
//       const newModels = prevModels.filter(model => model.modelId !== modelId);
      
//       // Update cache to remove deleted model
//       const cachedData = localStorage.getItem(CACHE_KEY);
//       if (cachedData) {
//         const { timestamp } = JSON.parse(cachedData);
//         localStorage.setItem(CACHE_KEY, JSON.stringify({
//           timestamp,
//           data: newModels
//         }));
//       }
      
//       return newModels;
//     });
//   });

//   setSocket(newSocket);

//   const token = localStorage.getItem('token');
//   if (token) {
//     const decodedToken = jwtDecode(token);
//     setCurrentUserId(decodedToken.userId);
//   }

//   return () => {
//     if (newSocket) newSocket.disconnect();
//   };
// }, []);



// const getModels = async () => {
//   try {
//     setIsLoading(true);
    
//     // Check cache first
//     const cachedData = localStorage.getItem(CACHE_KEY);
//     if (cachedData) {
//       const { timestamp, data } = JSON.parse(cachedData);
//       const isExpired = Date.now() - timestamp > CACHE_DURATION;
      
//       if (!isExpired && Array.isArray(data)) {
//         console.log('Using cached data');
//         setModels(data);
//         setIsLoading(false);
//         return;
//       }
//     }

//     console.log('Fetching fresh data from:', `${BASE_URL}/api/LPmodels`);
//     const response = await fetch(`${BASE_URL}/api/LPmodels`, {
//       headers: {
//         'Cache-Control': 'max-age=3600'
//       }
//     });
    
//     const data = await response.json();
    
//     // Update cache
//     localStorage.setItem(CACHE_KEY, JSON.stringify({
//       timestamp: Date.now(),
//       data: Array.isArray(data) ? data : []
//     }));
    
//     setModels(Array.isArray(data) ? data : []);
//   } catch (error) {
//     console.error('Error fetching models:', error);
//     setModels([]);
//   } finally {
//     setIsLoading(false);
//   }
// };
const getModels = async () => {
  try {
    setIsLoading(true);
    
    const cachedData = localStorage.getItem(CACHE_KEY);
    if (cachedData) {
      const { timestamp, data } = JSON.parse(cachedData);
      const isExpired = Date.now() - timestamp > CACHE_DURATION;
      
      if (!isExpired && Array.isArray(data)) {
        console.log('Using cached data');
        setModels(data);
        setIsLoading(false);
        return;
      }
    }

    // Fetch fresh data if cache is expired or empty
    const response = await fetch(`${BASE_URL}/api/LPmodels`);
    const data = await response.json();
    
    localStorage.setItem(CACHE_KEY, JSON.stringify({
      timestamp: Date.now(),
      data: Array.isArray(data) ? data : []
    }));
    
    setModels(Array.isArray(data) ? data : []);
  } catch (error) {
    console.error('Error fetching models:', error);
    setModels([]);
  } finally {
    setIsLoading(false);
  }
};


useEffect(() => {
  const checkRecentUpdate = () => {
    const lastUpdate = localStorage.getItem('profileUpdated');
    if (lastUpdate) {
      const updateTime = parseInt(lastUpdate);
      const fiveMinutesAgo = Date.now() - (5 * 60 * 1000);
      
      if (updateTime > fiveMinutesAgo) {
        // Clear both flags and fetch fresh data
        localStorage.removeItem('profileUpdated');
        localStorage.removeItem(CACHE_KEY);
        getModels();
      }
    }
  };

  checkRecentUpdate();
}, []); 

// Socket and auth setup
useEffect(() => {
  const socketUrl = window.location.origin;
  const newSocket = io(socketUrl, {
    withCredentials: true,
    transports: ['polling', 'websocket'],
    path: '/socket.io/',
    reconnection: true,
    reconnectionAttempts: 10,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    timeout: 20000,
    autoConnect: true,
    debug: false,
    logger: false
  });

  newSocket.on('connect', () => {
    console.log('Socket connected');
  });

  // Use updateSingleModel for profile updates
//   newSocket.on('profileUpdate', (data) => {
//     console.log('Profile update received:', data);
//     updateSingleModel(data.modelId);
//   });

//   newSocket.on('profileDelete', (modelId) => {
//     setModels(prevModels => {
//       const newModels = prevModels.filter(model => model.modelId !== modelId);
      
//       // Update cache to remove deleted model
//       const cachedData = localStorage.getItem(CACHE_KEY);
//       if (cachedData) {
//         const { timestamp } = JSON.parse(cachedData);
//         localStorage.setItem(CACHE_KEY, JSON.stringify({
//           timestamp,
//           data: newModels
//         }));
//       }
      
//       return newModels;
//     });
//   });

//   setSocket(newSocket);

//   return () => {
//     if (newSocket) newSocket.disconnect();
//   };
// }, []);




newSocket.on('profileUpdate', async (data) => {
  console.log('Profile update received:', data);
  
  // Clear cache and fetch fresh data
  localStorage.removeItem(CACHE_KEY);
  getModels();
});

return () => {
  if (newSocket) newSocket.disconnect();
};
}, []);

// Initial data fetch
useEffect(() => {
  getModels();
}, []);

useEffect(() => {
  const checkAdminStatus = async () => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = jwtDecode(token);
      try {
        const response = await fetch(`/api/type/${decodedToken.userId}`);
        const data = await response.json();
        setIsAdmin(data[0].accountType === 'admin');
        
        // console.log(`response: ${response}`);
        // console.log(`data: ${JSON.stringify(data)}`);
        // console.log(`decodedToken.userId: ${decodedToken.userId}`);
      } catch (error) {
        console.error('Error checking admin status:', error);
      }
    }
  };
  
  checkAdminStatus();
}, []);

// console.log(`TESTTESTTEST`);

// const filteredModels = selectedLocation === 'All'
//   ? models
//   : models.filter(s => s.location === selectedLocation);
const filteredModels = useMemo(() => {
  let filtered = selectedLocation === 'All'
    ? models
    : models.filter(s => s.location === selectedLocation);

  // Apply additional filters if any are selected
  if (Object.keys(selectedFilters).length > 0) {
    filtered = filtered.filter(model => {
      const ageMatch = !selectedFilters.age || 
        (model.age >= selectedFilters.age[0] && model.age <= selectedFilters.age[1]);
      
      const nationalityMatch = !selectedFilters.nationality || 
        model.nationality === selectedFilters.nationality;
      
      const heightMatch = !selectedFilters.height || 
        (model.height >= selectedFilters.height[0] && model.height <= selectedFilters.height[1]);
      
      const bodyTypeMatch = !selectedFilters.bodyType || 
        model.bodyType === selectedFilters.bodyType;

      return ageMatch && nationalityMatch && heightMatch && bodyTypeMatch;
    });
  }

  return filtered;
}, [models, selectedLocation, selectedFilters]);

const handleFilterChange = (filters) => {
  setSelectedFilters(filters);
};

return (
  <div className="min-h-screen bg-gray-100 flex flex-col">
    <div className="bg-white">

    <div className="w-full bg-white">
          <div className="max-w-7xl mx-auto py-2 px-4 sm:px-6 lg:px-8">
            <div className="flex flex-wrap gap-4 justify-center text-sm text-gray-600">
              {uniqueLocations.filter(loc => loc !== 'All').map(location => {
                const count = models.filter(model => model.location === location).length;
                return (
                  <button
                    key={location}
                    onClick={() => navigateToLocation(location)}
                    className={`hover:text-gray-900 transition-colors ${
                      selectedLocation === location ? 'font-semibold text-gray-900' : ''
                    }`}
                  >
                    {location} {count}
                  </button>
                );
              })}
            </div>
          </div>
        </div>

  <div className="w-full bg-white">

    <div className="container mx-auto py-6 px-6 sm:px-6 lg:px-0=4">
    <h1 className="text-3xl font-light text-elblack text-left pb-4" style={{
              fontFamily: 'InterSB'
            }}>{selectedLocation} Escorts</h1>
      
      <p className="text-gray-600">
        Welcome to Working Girl, here you can find adult and escort advertisements 
        from independent New Zealand escorts, agencies, sensual massage providers 
        and other adult entertainers.
      </p>
    </div>
  </div>
  </div>

<div className='bg-white border-b py-2'>
  <div className='container mx-auto  py-2 px-4'>
  <div className='flex items-center gap-2'> 
    <div className='w-6 h-6 bg-custom-gold rounded-full flex items-center justify-center text-dgold'>
    <svg width="19" height="19" viewBox="0 0 19 19" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.33398 14.2502L9.50065 11.8356L12.6673 14.2502L11.4798 10.3314L14.6465 8.07516H10.7673L9.50065
    3.9585L8.23398 8.07516H4.35482L7.52148 10.3314L6.33398 14.2502ZM9.50065 17.4168C8.40551 17.4168 7.37635
      17.209 6.41315 16.7934C5.44996 16.3778 4.61211 15.8137 3.89961 15.1012C3.18711 14.3887 2.62305 13.5509
      2.20742 12.5877C1.7918 11.6245 1.58398 10.5953 1.58398 9.50016C1.58398 8.40502 1.7918 7.37586 2.20742
        6.41266C2.62305 5.44947 3.18711 4.61162 3.89961 3.89912C4.61211 3.18662 5.44996 2.62256 6.41315
        2.20693C7.37635 1.79131 8.40551 1.5835 9.50065 1.5835C10.5958 1.5835 11.625 1.79131 12.5882
          2.20693C13.5513 2.62256 14.3892 3.18662 15.1017 3.89912C15.8142 4.61162 16.3783 5.44947
          16.7939 6.41266C17.2095 7.37586 17.4173 8.40502 17.4173 9.50016C17.4173 10.5953 17.2095
            11.6245 16.7939 12.5877C16.3783 13.5509 15.8142 14.3887 15.1017 15.1012C14.3892 15.8137
            13.5513 16.3778 12.5882 16.7934C11.625 17.209 10.5958 17.4168 9.50065 17.4168Z"/>
    </svg>
  </div>
  <h1>
  <span className="text-2xl font-semibold text-gray-800" style={{
      fontFamily: 'InterSB'
    }}>Featured Girls</span>


</h1>
</div>
</div>
</div>
<FilterSection 
        onFilterChange={handleFilterChange}
        models={models}
        // selectedFilters={selectedFilters}
      />
<div className="bg-gray-100 bg-white flex-grow">
<div className="container px-2 md:px-6 mx-auto py-0 pb-12">

  {isAdmin ? (
  <AdminModelCards filteredModels={filteredModels} 
                  onModelClick={handleModelClick} 
                  isLoading={isLoading}/>
) : (
  <ModelCards filteredModels={  filteredModels} 
              onModelClick={handleModelClick} 
              isLoading={isLoading} />
)}

  </div>
  </div>
  <Footer />
  </div>

  );
};

export default LandingPage;