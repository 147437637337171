import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import '../styles/fonts.css';
import AvatarB from '../components/avatarb.svg';
import plussign from '../components/plussign.svg';
import logo from '../components/logo.png';
import MobileHeader from '../components/MobileHeader';
import ModelSearch from './ModelSearch';
import UserMenu from '../components/UserMenu';
import { heartEventEmitter, getHeartedModels } from './HeartSystem';
import ModelCards from './ModelCards';
import { Heart, HeartOff } from 'lucide-react';

const Header = ({ models }) => {
    const { user, logout } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const [showNavbar, setShowNavbar] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);
    const [heartedCount, setHeartedCount] = useState(0);

    const handleLogout = async () => {
        try {
            await logout();
            navigate('/login');
        } catch (error) {
            console.error('Logout error:', error);
        }
    };

    const handleModelClick = (userId) => {
      navigate(`/profile/${userId}`);
  };

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
  };

const [mobile, setMobile] = useState(window.innerWidth < 768);

useEffect(() => {
  const updateHeartedCount = () => {
      const count = getHeartedModels().length;
      setHeartedCount(count);
  };

  updateHeartedCount(); 

  const unsubscribe = heartEventEmitter.subscribe(updateHeartedCount);

  return () => {
      unsubscribe();
  };
}, []);


useEffect(() => {
    const handleResize = () => setMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => {
        window.removeEventListener('resize', handleResize);
    };
}, []);


    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;
            if (currentScrollY > lastScrollY && currentScrollY > 100) {
                setShowNavbar(false);
            } else {
                setShowNavbar(true); 
            }
            setLastScrollY(currentScrollY);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollY]);

return (
  <>
      {mobile ? (
          <MobileHeader />
      ) : (
          <header
              className={`sticky top-0 bg-white shadow-md z-50 transition-transform duration-300 ${
                  showNavbar ? 'transform translate-y-0' : 'transform -translate-y-full'
              }`}
          >
              <nav className="px-4 sm:px-6 lg:px-4">
                  <div className="flex h-16 justify-between items-center">
                      <div className="flex items-baseline">
                          <Link to="/" className="flex items-center">
                              <img src={logo} className="w-44" alt="Logo" />
                          </Link>
                          <div
                              className="hidden sm:ml-6 sm:flex sm:space-x-8 items-center h-full"
                              style={{
                                  fontFamily: 'InterRegular',
                                  lineHeight: '1.5',
                              }}
                          >

                          </div>
                      </div>

                      <div className="hidden gap-2 sm:ml-6 sm:flex sm:items-center">

                      
                        <div className="pt-4">
                          <ModelSearch 
                              models={models} 
                              onModelClick={handleModelClick} 
                              onClose={() => setIsSearchOpen(false)}  
                          />
                          </div>
                      

                          {user ? (
                <UserMenu handleLogout={handleLogout} />
                ) : (
                <Link
                    to="/login"
                    className="bg-white text-gray-400 px-4 py-2 rounded-full border border-solid border-gray-400 text-sm font-medium hover:bg-gray-200 flex items-center"
                >
                    Login
                </Link>
                )}

                <Link 
                        to="/favorites" 
                        className="flex items-center gap-2 px-2 py-2 rounded-full hover:bg-gray-100 transition-colors"
                    >
                        <Heart 
                        className={
                            heartedCount > 0 
                            ? 'w-5 h-5 text-heart fill-heart [&>path]:stroke-none' 
                            : 'w-5 h-5 text-gray-300 stroke-current'
                        } 
                        />
                    </Link>
                      </div>
                  </div>
              </nav>
          </header>
      )}
  </>
);
}
export default Header;