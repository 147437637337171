// import React, { useEffect } from 'react';
// import '../styles/fonts.css';
// import fireEmoji from './fireemoji.png';
// import verified from './verified.svg';
// import HeartSystem from './HeartSystem';

// const imageCache = new Map();

// const ModelCards = ({ filteredModels, onModelClick, isLoading }) => {
//   useEffect(() => {
//     if (filteredModels) {
//         console.log('Received models data:', {
//             count: filteredModels.length,
//             sampleUrls: filteredModels.slice(0, 2).map(m => m.ImageUrls)
//         });
//     }
// }, [filteredModels]);

// useEffect(() => {
//   if (filteredModels?.length) {
//     const preloadImages = (models) => {
//       models.forEach(model => {
//         if (model.ImageUrl) {
//           const img = new Image();
//           img.src = getImageUrl(model.ImageUrl);
//           imageCache.set(model.ImageUrl, img);
//         }
//       });
//     };
    
//     preloadImages(filteredModels);
//   }
// }, [filteredModels]);


//   if (isLoading) {
//     return (
//       <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-2 px-0 max-w-8xl mx-auto">
//         {[...Array(6)].map((_, index) => (
//           <div key={index} className="models bg-white overflow-hidden transition-shadow duration-300">
//             <div className="aspect-[3/4] relative overflow-hidden max-h-128 bg-gray-200 animate-pulse" />
//             <div className="pt-4 pb-2 relative">
//               <div className="w-2/3 h-4 bg-gray-200 rounded animate-pulse mb-2" />
//               <div className="w-1/2 h-4 bg-gray-200 rounded animate-pulse" />
//             </div>
//             <div className="space-y-1">
//               <div className="w-full h-4 bg-gray-200 rounded animate-pulse" />
//             </div>
//             <div className="px-4 py-3 bg-white flex justify-between items-center">
//               <div className="w-1/3 h-4 bg-gray-200 rounded animate-pulse" />
//               <div className="w-1/4 h-4 bg-gray-200 rounded animate-pulse" />
//             </div>
//           </div>
//         ))}
//       </div>
//     );
//   }
  

//   if (!filteredModels || filteredModels.length === 0) {
//     return <div className="p-6">No models available</div>;
//   }

// //   const getImageUrl = (imageUrl) => {
// //     return process.env.NODE_ENV === 'production'
// //         ? imageUrl  
// //         : `http://localhost:3001${imageUrl}`; 
// // };

// //   const processedModels = filteredModels.map(model => {
// //     const imageUrlArray = model.ImageUrls ? model.ImageUrls.split(',') : [];
    
// //     const positionsArray = model.positions ? model.positions.split(',').map(Number) : [];
    
// //     let primaryImageUrl = imageUrlArray[0];
// //     if (positionsArray.includes(1)) {
// //       const primaryIndex = positionsArray.indexOf(1);
// //       primaryImageUrl = imageUrlArray[primaryIndex];
// //     }

// //     console.log('Processing model:', {
// //       userId: model.userId,
// //       imageCount: imageUrlArray.length,
// //       primaryImageUrl
// //     });

// //     return {
// //       ...model,
// //       ImageUrl: primaryImageUrl
// //     };
// //   });


// const getImageUrl = (imageUrl) => {
//     // Check if it's already a CDN URL
//     if (imageUrl && (imageUrl.includes('cloudfront.net') || imageUrl.includes('s3.amazonaws.com'))) {
//         return imageUrl;
//     }
    
//     // For development environment
//     if (process.env.NODE_ENV !== 'production') {
//         return `http://localhost:3001${imageUrl}`;
//     }
    
//     // For production, return the URL as is if it's not a CDN URL
//     return imageUrl;
// };




// const liveModels = filteredModels.filter(model => model.live === true);

//   const processedModels = liveModels.map((model) => {
//     const imageUrlArray = model.ImageUrls ? model.ImageUrls.split(',') : [];
//     const positionsArray = model.positions ? model.positions.split(',') : [];

//     const primaryImageIndex = positionsArray.indexOf('1');
//     const primaryImageUrl = primaryImageIndex !== -1 ? imageUrlArray[primaryImageIndex] : imageUrlArray[0];

//     return {
//       ...model,
//       ImageUrl: primaryImageUrl,
//     };
//   });
//     // const processedModels = filteredModels.map((model) => {
//     //   const imageUrlArray = model.ImageUrls ? model.ImageUrls.split(',') : [];
//     //   const positionsArray = model.positions ? model.positions.split(',') : [];

//     //   const primaryImageIndex = positionsArray.indexOf('1');
//     //   const primaryImageUrl = primaryImageIndex !== -1 ? imageUrlArray[primaryImageIndex] : imageUrlArray[0];

//     //   return {
//     //     ...model,
//     //     ImageUrl: primaryImageUrl,
//     //   };
//     // });

//   const modelCards = processedModels.reduce((acc, model) => {
//     if (!acc.some(m => m.modelId === model.modelId)) {
//       acc.push(model);
//     }
//     return acc;
//   }, []);

//   if (modelCards.length === 0) {
//     return <div className="p-6">No models with primary images found</div>;
//   }



//   return (
//     <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-2 px-0 max-w-8xl mx-auto">
//       {modelCards.map((model) => (
//         <div 
//           key={model.modelId} 
//           className="models bg-white overflow-hidden transition-shadow duration-300"
//           onClick={() => onModelClick(model.modelId)}
//         >
//           {/* <div className="aspect-[3/4] relative overflow-hidden max-h-128">
//           <HeartSystem modelId={model.modelId} />
//             {model.accountType === 'featured' && ( */}
//                       <div className="aspect-[3/4] relative overflow-hidden max-h-128">
//             <div className="absolute top-2 right-2 z-10">
//               <HeartSystem modelId={model.modelId} />
//             </div>
//             {model.accountType === 'featured' && (
//               <div className="bg-custom-gold h-22 absolute top-2 left-2 z-10 flex items-center gap-1 rounded-full px-1 py-0">
//                 <div className='w-5 h-5 rounded-full flex items-center justify-center text-dgold'>
//                   <svg width="18" height="18" viewBox="0 0 19 19" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
//                     <path d="M6.33398 14.2502L9.50065 11.8356L12.6673 14.2502L11.4798 10.3314L14.6465 8.07516H10.7673L9.50065 3.9585L8.23398 8.07516H4.35482L7.52148 10.3314L6.33398 14.2502ZM9.50065 17.4168C8.40551 17.4168 7.37635 17.209 6.41315 16.7934C5.44996 16.3778 4.61211 15.8137 3.89961 15.1012C3.18711 14.3887 2.62305 13.5509 2.20742 12.5877C1.7918 11.6245 1.58398 10.5953 1.58398 9.50016C1.58398 8.40502 1.7918 7.37586 2.20742 6.41266C2.62305 5.44947 3.18711 4.61162 3.89961 3.89912C4.61211 3.18662 5.44996 2.62256 6.41315 2.20693C7.37635 1.79131 8.40551 1.5835 9.50065 1.5835C10.5958 1.5835 11.625 1.79131 12.5882 2.20693C13.5513 2.62256 14.3892 3.18662 15.1017 3.89912C15.8142 4.61162 16.3783 5.44947 16.7939 6.41266C17.2095 7.37586 17.4173 8.40502 17.4173 9.50016C17.4173 10.5953 17.2095 11.6245 16.7939 12.5877C16.3783 13.5509 15.8142 14.3887 15.1017 15.1012C14.3892 15.8137 13.5513 16.3778 12.5882 16.7934C11.625 17.209 10.5958 17.4168 9.50065 17.4168Z"/>
//                   </svg>
//                 </div>
//                 <span className="text-gold" style={{ fontFamily: 'InterSB' }}>FEATURED</span>
//               </div>
//             )}
//             {model.hot === 'true' && (
//               <div className="bg-white shadow-md shadow-lightred w-97 h-22 absolute top-2 left-2 z-10 flex items-center gap-2 rounded-full px-2 py-0">
//                 <img src={fireEmoji} className="w-3" alt="hot"/>
//                 <span className="text-red" style={{ fontFamily: 'InterSB' }}>Hot</span>
//               </div>
//             )}

//           {model.active && (
//               <div className="absolute bottom-2 left-2 z-10">
//                 <div className="flex items-center bg-black bg-opacity-25 rounded-full px-3 py-1">
//                   <div className="relative mr-2">
//                     <div className="absolute w-3 h-3 bg-green bg-opacity-50 rounded-full top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"></div>
                    
//                     <div className="absolute w-1.5 h-1.5 bg-green rounded-full top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"></div>
//                   </div>
//                   <span className="text-white text-xs pl-1" style={{ fontFamily: 'InterRegular' }}>Active Now</span>
//                 </div>
//               </div>
//             )}
//             {model.ImageUrl ? (
//               <img
//                 src={getImageUrl(model.ImageUrl)}
//                 alt={`${model.firstName || 'Model'}`}
//                 className="object-cover w-full h-full hover:scale-105 transition-transform duration-300"
//                 loading="lazy"
//                 onError={(e) => {
//                   // console.log('Image failed to load:', model.ImageUrl);
//                   e.target.src = '/api/placeholder/300/400';
//                 }}
//               />
//             ) : (
//               <div className="w-full h-full bg-gray-200 flex items-center justify-center">
//                 No Image Available
//               </div>
//             )}
//           </div>
          

//           {/* <div className="pt-4 pb-2 relative">
//             <div className="flex justify-between items-center">
//               <div>
//                 <h3 className="text-xl font-semibold text-gray-800 inline-flex gap-2" style={{
//                 fontFamily: 'InterSB'
//                   }}>
//                   {model.firstName + ' ' + model.lastName || 'Name Not Available'}
//                   <span className="text-gray-500 flex items-center gap-1">
//                     {model.age}
//                     {model.verified === 'true' && (
//                       <img src={verified} className="w-5 h-5 inline-block translate-y-0.5" alt="verified"/>
//                     )}
//                   </span>
//                 </h3>
//               </div>
//             </div>
//           </div>

//           <div className="space-y-1">
//             <div className="text-sm" style={{ fontFamily: 'InterRegular' }}>
//               <div>
//                 <p className="font-medium text-gray-800 pb-4 border-b">{model.tagLine || ''}</p>
//               </div>
//             </div>
//           </div> */}
//           <div className="pt-4 pb-2 relative">
//             <div className="flex justify-between items-center">
//               <div>
//                 <h3 className="text-xl font-semibold text-gray-800 inline-flex gap-2" style={{
//                   fontFamily: 'InterSB'
//                 }}>
//                   {model.firstName + ' ' + model.lastName || 'Name Not Available'}
//                   <span className="text-gray-500 flex items-center gap-1">
//                     {model.age}
//                     {model.verified === 'true' && (
//                       <img src={verified} className="w-5 h-5 inline-block translate-y-0.5" alt="verified"/>
//                     )}
//                   </span>
//                 </h3>
//               </div>
//             </div>
//           </div>

//           <div className="relative h-6 px-4"> {/* Fixed height container */}
//             <div className="absolute inset-0">
//               <p className="text-sm text-gray-800" style={{ fontFamily: 'InterRegular' }}>
//                 {model.tagLine || ''}
//               </p>
//               <div className="absolute bottom-0 inset-x-0 h-1 bg-gradient-to-t from-white to-transparent"></div>
//             </div>
//           </div>
//           <div className="p-4 space-y-4">
//             <div className="grid grid-cols-2 gap-4 text-sm">
//             </div>
//           </div>

//           <div className="px-4 py-3 bg-white flex justify-between items-center">
//             <div className="text-sm">
//               <span className="text-gray-500 text-top">
//                 {model.location || 'N/A'}
//               </span>
//             </div>
//             <div className="text-sm text-black text-right">
//               {`$${model.rate60}` || 'N/A'}
//               <p>1 Hour</p>
//             </div>
//           </div>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default ModelCards;
import React, { useEffect } from 'react';
import '../styles/fonts.css';
import fireEmoji from './fireemoji.png';
import verified from './verified.svg';
import HeartSystem from './HeartSystem';

const imageCache = new Map();

const ModelCards = ({ filteredModels, onModelClick, isLoading }) => {
  useEffect(() => {
    if (filteredModels) {
        console.log('Received models data:', {
            count: filteredModels.length,
            sampleUrls: filteredModels.slice(0, 2).map(m => m.ImageUrls)
        });
    }
  }, [filteredModels]);

  useEffect(() => {
    if (filteredModels?.length) {
      const preloadImages = (models) => {
        models.forEach(model => {
          if (model.ImageUrl) {
            const img = new Image();
            img.src = getImageUrl(model.ImageUrl);
            imageCache.set(model.ImageUrl, img);
          }
        });
      };
      
      preloadImages(filteredModels);
    }
  }, [filteredModels]);

  if (isLoading) {
    return (
      <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-2 px-0 max-w-8xl mx-auto">
        {[...Array(6)].map((_, index) => (
          <div key={index} className="models bg-white overflow-hidden transition-shadow duration-300">
            <div className="aspect-[3/4] relative overflow-hidden max-h-128 bg-gray-200 animate-pulse" />
            <div className="pt-4 pb-2 relative">
              <div className="w-2/3 h-4 bg-gray-200 rounded animate-pulse mb-2" />
              <div className="w-1/2 h-4 bg-gray-200 rounded animate-pulse" />
            </div>
            <div className="space-y-1">
              <div className="w-full h-4 bg-gray-200 rounded animate-pulse" />
            </div>
            <div className="px-4 py-3 bg-white flex justify-between items-center">
              <div className="w-1/3 h-4 bg-gray-200 rounded animate-pulse" />
              <div className="w-1/4 h-4 bg-gray-200 rounded animate-pulse" />
            </div>
          </div>
        ))}
      </div>
    );
  }
  
  if (!filteredModels || filteredModels.length === 0) {
    return <div className="p-6">No models available</div>;
  }

  const getImageUrl = (imageUrl) => {
    // Check if it's already a CDN URL
    if (imageUrl && (imageUrl.includes('cloudfront.net') || imageUrl.includes('s3.amazonaws.com'))) {
        return imageUrl;
    }
    
    // For development environment
    if (process.env.NODE_ENV !== 'production') {
        return `http://localhost:3001${imageUrl}`;
    }
    
    // For production, return the URL as is if it's not a CDN URL
    return imageUrl;
  };

  const liveModels = filteredModels.filter(model => model.live === true);

  const processedModels = liveModels.map((model) => {
    const imageUrlArray = model.ImageUrls ? model.ImageUrls.split(',') : [];
    const positionsArray = model.positions ? model.positions.split(',') : [];

    const primaryImageIndex = positionsArray.indexOf('1');
    const primaryImageUrl = primaryImageIndex !== -1 ? imageUrlArray[primaryImageIndex] : imageUrlArray[0];

    return {
      ...model,
      ImageUrl: primaryImageUrl,
    };
  });

  const modelCards = processedModels.reduce((acc, model) => {
    if (!acc.some(m => m.modelId === model.modelId)) {
      acc.push(model);
    }
    return acc;
  }, []);

  if (modelCards.length === 0) {
    return <div className="p-6">No models with primary images found</div>;
  }

  return (
    <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-2 px-0 max-w-8xl mx-auto">
      {modelCards.map((model) => (
        <div 
          key={model.modelId} 
          className="models bg-white overflow-hidden transition-shadow duration-300 flex flex-col"
          onClick={() => onModelClick(model.modelId)}
        >
          <div className="aspect-[3/4] relative overflow-hidden max-h-128">
            <div className="absolute top-2 right-2 z-10">
              <HeartSystem modelId={model.modelId} />
            </div>
            {model.accountType === 'featured' && (
              <div className="bg-custom-gold h-22 absolute top-2 left-2 z-10 flex items-center gap-1 rounded-full px-1 py-0">
                <div className='w-5 h-5 rounded-full flex items-center justify-center text-dgold'>
                  <svg width="18" height="18" viewBox="0 0 19 19" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.33398 14.2502L9.50065 11.8356L12.6673 14.2502L11.4798 10.3314L14.6465 8.07516H10.7673L9.50065 3.9585L8.23398 8.07516H4.35482L7.52148 10.3314L6.33398 14.2502ZM9.50065 17.4168C8.40551 17.4168 7.37635 17.209 6.41315 16.7934C5.44996 16.3778 4.61211 15.8137 3.89961 15.1012C3.18711 14.3887 2.62305 13.5509 2.20742 12.5877C1.7918 11.6245 1.58398 10.5953 1.58398 9.50016C1.58398 8.40502 1.7918 7.37586 2.20742 6.41266C2.62305 5.44947 3.18711 4.61162 3.89961 3.89912C4.61211 3.18662 5.44996 2.62256 6.41315 2.20693C7.37635 1.79131 8.40551 1.5835 9.50065 1.5835C10.5958 1.5835 11.625 1.79131 12.5882 2.20693C13.5513 2.62256 14.3892 3.18662 15.1017 3.89912C15.8142 4.61162 16.3783 5.44947 16.7939 6.41266C17.2095 7.37586 17.4173 8.40502 17.4173 9.50016C17.4173 10.5953 17.2095 11.6245 16.7939 12.5877C16.3783 13.5509 15.8142 14.3887 15.1017 15.1012C14.3892 15.8137 13.5513 16.3778 12.5882 16.7934C11.625 17.209 10.5958 17.4168 9.50065 17.4168Z"/>
                  </svg>
                </div>
                <span className="text-gold" style={{ fontFamily: 'InterSB' }}>FEATURED</span>
              </div>
            )}
            {model.hot === 'true' && (
              <div className="bg-white shadow-md shadow-lightred w-97 h-22 absolute top-2 left-2 z-10 flex items-center gap-2 rounded-full px-2 py-0">
                <img src={fireEmoji} className="w-3" alt="hot"/>
                <span className="text-red" style={{ fontFamily: 'InterSB' }}>Hot</span>
              </div>
            )}

            {model.active && (
              <div className="absolute bottom-2 left-2 z-10">
                <div className="flex items-center bg-black bg-opacity-25 rounded-full px-3 py-1">
                  <div className="relative mr-2">
                    <div className="absolute w-3 h-3 bg-green bg-opacity-50 rounded-full top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"></div>
                    
                    <div className="absolute w-1.5 h-1.5 bg-green rounded-full top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"></div>
                  </div>
                  <span className="text-white text-xs pl-1" style={{ fontFamily: 'InterRegular' }}>Active Now</span>
                </div>
              </div>
            )}
            {model.ImageUrl ? (
              <img
                src={getImageUrl(model.ImageUrl)}
                alt={`${model.firstName || 'Model'}`}
                className="object-cover w-full h-full hover:scale-105 transition-transform duration-300"
                loading="lazy"
                onError={(e) => {
                  e.target.src = '/api/placeholder/300/400';
                }}
              />
            ) : (
              <div className="w-full h-full bg-gray-200 flex items-center justify-center">
                No Image Available
              </div>
            )}
          </div>
          
          {/* Fixed name and age section with proper alignment and adaptive text sizing */}
          <div className="pt-3 pb-1 sm:pt-4 sm:pb-2 relative px-4">
            <div className="flex justify-between items-baseline">
              <h3 className="text-sm md:text-base lg:text-lg xl:text-xl font-semibold text-gray-800 truncate max-w-[65%]" style={{
                fontFamily: 'InterSB'
              }}>
                {model.firstName + ' ' + model.lastName || 'Name Not Available'}
              </h3>
              <div className="flex items-center flex-shrink-0 ml-1">
                <span className="text-gray-500 text-sm sm:text-base">
                  {model.age}
                </span>
                {model.verified === 'true' && (
                  <img src={verified} className="w-4 h-4 sm:w-5 sm:h-5 inline-block ml-1" alt="verified"/>
                )}
              </div>
            </div>
          </div>

          {/* Tagline with fixed height and fade effect - more compact on mobile */}
          <div className="relative h-5 sm:h-6 px-4 mb-1 sm:mb-2"> 
            <div className="inset-0">
              <p className="text-xs sm:text-sm text-gray-800 overflow-hidden text-ellipsis whitespace-nowrap" style={{ fontFamily: 'InterRegular' }}>
                {model.tagLine || ''}
              </p>
            </div>
          </div>
          
          {/* Empty space removed for mobile */}
          <div className="hidden sm:block sm:p-4 sm:space-y-4">
            <div className="grid grid-cols-2 gap-4 text-sm">
            </div>
          </div>

          {/* Location and price with reduced padding on mobile */}
          <div className="px-4 py-2 sm:py-3 bg-white flex justify-between items-center mt-auto">
            <div className="text-xs sm:text-sm">
              <span className="text-gray-500">
                {model.location || 'N/A'}
              </span>
            </div>
            <div className="text-xs sm:text-sm text-black text-right">
              <div>{`${model.rate60}` || 'N/A'}</div>
              <div className="text-xs">1 Hour</div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ModelCards;