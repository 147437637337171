import React, { useState, useRef, useEffect } from 'react';
import { MoreVertical } from 'lucide-react';

const ProfileActionsMenu = ({ 
  model, 
  onToggleLive, 
  onToggleActive, 
  onDelete,
  isAgency 
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteType, setDeleteType] = useState(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleDelete = async () => {
    onDelete(model.modelId, deleteType);
    setShowDeleteDialog(false);
    setShowDropdown(false);
  };
  return (
    <div className="relative" ref={dropdownRef}>
      <button 
        onClick={() => setShowDropdown(!showDropdown)}
        className="p-2 hover:bg-gray-100 rounded-full"
      >
        <MoreVertical className="w-5 h-5 text-gray-600" />
      </button>

      {showDropdown && (
          <div
          className={`absolute right-0 top-full mt-1 w-56 bg-white rounded-lg shadow-lg border z-50 py-1
            transition-all duration-200 ease-in-out
            ${showDropdown 
              ? 'opacity-100 translate-y-0 scale-100' 
              : 'opacity-0 -translate-y-2 scale-95 pointer-events-none'
            }`}
        >
            
            
                {model.verified && (
                    <>
                    <div className="px-4 py-2 ">
                    <div className="flex justify-between items-center ">
                        <span className="text-sm">Live</span>
                        <label className="relative inline-flex items-center cursor-pointer">
                        <input
                            type="checkbox"
                            checked={model.live}
                            onChange={() => onToggleLive(model.modelId, model.live)}
                            className="sr-only peer"
                        />
                        <div className="w-9 h-5 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full peer-checked:bg-gradient-to-b peer-checked:from-elgrad peer-checked:to-elink after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:rounded-full after:h-4 after:w-4 after:transition-all"></div>
                        </label>
                    </div>

                    <div className="flex justify-between items-center mt-2">
                        <span className="text-sm text-gray-700">Active</span>
                        <label className="relative inline-flex items-center cursor-pointer">
                        <input
                            type="checkbox"
                            checked={model.active}
                            onChange={() => onToggleActive(model.modelId, model.active)}
                            className="sr-only peer"
                        />
                        <div className="w-9 h-5 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full peer-checked:bg-gradient-to-b peer-checked:from-elgrad peer-checked:to-elink after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:rounded-full after:h-4 after:w-4 after:transition-all"></div>
                        </label>
                    </div>
                    </div>
                    </>
                )}
                

          <button
            onClick={() => {
              setDeleteType('listing');
              setShowDeleteDialog(true);
              setShowDropdown(false);
            }}
            className="w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100"
          >
            Delete Listing
          </button>

          {(isAgency ? true : model.userId === model.agencyId) && (
            <button
              onClick={() => {
                setDeleteType('account');
                setShowDeleteDialog(true);
                setShowDropdown(false);
              }}
              className="w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100"
            >
              Delete Account
            </button>
          )}
        </div>
      )}

      {showDeleteDialog && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
            <h3 className="text-xl font-semibold mb-4">
              {deleteType === 'listing' ? 'Delete Listing?' : 'Delete Account?'}
            </h3>
            <p className="text-gray-600 mb-6">
              {deleteType === 'listing' 
                ? 'This will permanently delete this listing. This action cannot be undone.'
                : 'This will permanently delete your entire account and all associated data. This action cannot be undone.'}
            </p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setShowDeleteDialog(false)}
                className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-full"
              >
                Cancel
              </button>
              <button
                onClick={handleDelete}
                className="px-4 py-2 bg-elink text-white hover:bg-elgrad rounded-full"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileActionsMenu;

