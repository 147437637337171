import React, { useState, useEffect } from 'react';

const ValidationMessage = ({ field, message, onClose }) => {
  return (
    <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 z-50">
      <div className="bg-white border border-red-200 shadow-lg rounded-lg px-4 py-3 flex items-center gap-2">
        <div className="text-red-500">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM11 15H9V13H11V15ZM11 11H9V5H11V11Z" fill="currentColor"/>
          </svg>
        </div>
        <span className="text-sm text-gray-700">
          {message}
        </span>
        <button 
          onClick={onClose}
          className="ml-2 text-gray-400 hover:text-gray-600"
        >
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" stroke="currentColor">
            <path d="M12 4L4 12M4 4L12 12" strokeWidth="2" strokeLinecap="round"/>
          </svg>
        </button>
      </div>
    </div>
  );
};

export default function InputWithValidation({ 
  label,
  value,
  onChange,
  isEditing,
  type = "text",
  validation,
  placeholder,
  min,
  max
}) {
  const [error, setError] = useState(null);
  const [showNotification, setShowNotification] = useState(false);

  const handleChange = (e) => {
    const newValue = e.target.value;
    onChange(newValue);  // Just pass the value through without validation
  };

  useEffect(() => {
    let timer;
    if (showNotification) {
      timer = setTimeout(() => {
        setShowNotification(false);
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [showNotification]);

  return (
    <div className="relative pb-4">
      {label && (
        <label className="block text-sm font-medium text-gray-700 mb-1">
          {label}
        </label>
      )}
      <input
        type={type}
        className={`w-full px-4 py-2.5 bg-white border rounded-lg text-gray-700 text-sm 
          transition-colors hover:border-gray-300 focus:border-elink focus:ring-2 
          focus:ring-elink/20 outline-none disabled:bg-gray-50 disabled:cursor-not-allowed
          ${error ? 'border-red-300' : 'border-gray-200'}`}
        value={value || ''}
        onChange={handleChange}
        disabled={!isEditing}
        placeholder={placeholder}
        min={min}
        max={max}
      />
      
      {showNotification && error && (
        <ValidationMessage 
          message={error}
          onClose={() => setShowNotification(false)}
        />
      )}
    </div>
  );
}