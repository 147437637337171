// import React, { useState, useEffect } from 'react';

// const FilterSection = ({ onFilterChange, models }) => {
//   const [selectedFilters, setSelectedFilters] = useState({
//     displayMode: 'all',
//     age: null,
//     nationality: null,
//     height: null,
//     bodyType: null
//   });
//   const [showAllFilters, setShowAllFilters] = useState(false);

//   const getUniqueValues = (field) => {
//     const values = new Set(models.map(model => model[field]).filter(Boolean));
//     return Array.from(values);
//   };

//   // Function to get age ranges that have models
//   const getAgeRanges = () => {
//     const ages = models.map(model => model.age).filter(Boolean);
//     const ranges = [
//       { label: '18 - 24', value: [18, 24] },
//       { label: '24 - 28', value: [24, 28] },
//       { label: '28 - 35', value: [28, 35] },
//       { label: '35+', value: [35, 100] }
//     ];
//     return ranges.filter(range => 
//       ages.some(age => age >= range.value[0] && age <= range.value[1])
//     );
//   };

//   // Function to get height ranges that have models
//   const getHeightRanges = () => {
//     const heights = models.map(model => model.height).filter(Boolean);
//     const ranges = [
//       { label: 'Under 150cm', value: [0, 150] },
//       { label: '150 - 165cm', value: [150, 165] },
//       { label: '165 - 175cm', value: [165, 175] },
//       { label: '175cm +', value: [175, 999] }
//     ];
//     return ranges.filter(range => 
//       heights.some(height => height >= range.value[0] && height <= range.value[1])
//     );
//   };



//   const handleFilterClick = (category, value) => {
//     const newFilters = {
//       ...selectedFilters,
//       [category]: selectedFilters[category]?.toString() === value?.toString() ? null : value
//     };
    
//     if (category === 'displayMode' && value === 'all') {
//       Object.keys(newFilters).forEach(key => {
//         if (key !== 'displayMode') newFilters[key] = null;
//       });
//     } else {
//       newFilters.displayMode = 'filtered';
//     }

//     setSelectedFilters(newFilters);
//     onFilterChange(newFilters);
//   };

//   // Main filter options that are always visible
// //   const mainFilters = {
// //     nationality: ['Asian'],
// //     bodyType: ['Curvy'],
// //     age: [{ label: '18 - 24', value: [18, 24] }],
// //     height: [{ label: '150 - 165cm', value: [160, 170] }]
// //   };
// const mainFilters = {
//     nationality: getUniqueValues('nationality').slice(0, 1),
//     bodyType: getUniqueValues('bodyType').slice(0, 1), // Just take the first one for main filters
//     age: getAgeRanges().slice(0, 1),
//     height: getHeightRanges().slice(0, 1)
//   };

//   // Additional filter options shown when expanded
// //   const additionalFilters = {
// //     nationality: ['European', 'New Zealand', 'Latin American', 'Māori', 'Australian', 'British', 'Eastern European', 'Indian',  'Pacific Islander'],
// //     age: [
// //       { label: '24 - 28', value: [25, 35] },
// //       { label: '28 - 35', value: [28, 25] },
// //       { label: '35+', value: [35, 100] }
// //     ],
// //     height: [
// //       { label: 'Under 150cm', value: [0, 150] },
// //       { label: '165 - 175cm', value: [165, 175] },
// //       { label: '175cm +', value: [175, 999] }
// //     ],

// //     bodyType: ['Slim', 'Busty', 'BBW', 'Petite', 'Athletic', 'Tall', 'Short']
// //   };


// const additionalFilters = {
//     nationality: getUniqueValues('nationality').slice(1), // Skip the first one which is in main filters
//     age: getAgeRanges().slice(1),
//     height: getHeightRanges().slice(1),
//     bodyType: getUniqueValues('bodyType').slice(1)
//   };

//   const filterCategories = {
//     nationality: 'Nationality',
//     bodyType: 'Body Type',
//     age: 'Age',
//     height: 'Height'
    
//   };

//   const isSelected = (category, value) => {
//     const selectedValue = selectedFilters[category];
//     if (Array.isArray(value)) {
//       return JSON.stringify(selectedValue) === JSON.stringify(value);
//     }
//     return selectedValue === value;
//   };

//   return (
//     <div className="bg-white border-t border-gray-200">
//       <div className="container mx-auto py-4 px-4">
//         {/* Header with Refine icon */}
//         <div className="flex items-center justify-between mb-4">
//           <div className="flex items-center gap-2">
//             <svg 
//               width="20" 
//               height="20" 
//               viewBox="0 0 20 20" 
//               fill="none" 
//               xmlns="http://www.w3.org/2000/svg"
//               className="text-gray-500"
//             >
//               <g clipPath="url(#clip0_119_549)">
//                 <path d="M2.5 14.1667V15.8333H7.5V14.1667H2.5ZM2.5 4.16667V5.83333H10.8333V4.16667H2.5ZM10.8333 17.5V15.8333H17.5V14.1667H10.8333V12.5H9.16667V17.5H10.8333ZM5.83333 7.5V9.16667H2.5V10.8333H5.83333V12.5H7.5V7.5H5.83333ZM17.5 10.8333V9.16667H9.16667V10.8333H17.5ZM12.5 7.5H14.1667V5.83333H17.5V4.16667H14.1667V2.5H12.5V7.5Z" 
//                 fill="currentColor"
//               />
//               </g>
//             </svg>
//             <span className="text-gray-600 font-medium">Refine</span>
//           </div>
//           <button
//             onClick={() => setShowAllFilters(!showAllFilters)}
//             className="text-gray-600 hover:text-gray-900 flex items-center gap-1"
//           >
//             {showAllFilters ? (
//               <>
//                 <span>Show Less</span>
//                 <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
//                   <path d="M19 15L12 8L5 15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
//                 </svg>
//               </>
//             ) : (
//               <>
//                 <span>Show All</span>
//                 <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
//                   <path d="M5 9L12 16L19 9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
//                 </svg>
//               </>
//             )}
//           </button>
//         </div>

//         {/* Main filters row */}
//         <div className="flex flex-wrap items-center gap-2">
//           <button
//             onClick={() => handleFilterClick('displayMode', 'all')}
//             className={`px-4 py-2 rounded-full text-sm font-medium transition-colors
//               ${selectedFilters.displayMode === 'all'
//                 ? 'bg-gray-500 text-white'
//                 : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
//               }`}
//           >
//             All Escorts
//           </button>

//           {Object.entries(mainFilters).map(([category, options]) => (
//             options.map((option) => {
//               const value = typeof option === 'object' ? option.value : option;
//               const label = typeof option === 'object' ? option.label : option;
//               return (
//                 <button
//                   key={`${category}-${label}`}
//                   onClick={() => handleFilterClick(category, value)}
//                   className={`px-4 py-2 rounded-full text-sm font-medium transition-colors
//                     ${isSelected(category, value)
//                       ? 'bg-gray-500 text-white'
//                       : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
//                     }`}
//                 >
//                   {label}
//                 </button>
//               );
//             })
//           ))}
//         </div>

//         {/* Additional filters */}
//         {showAllFilters && (
//           <div className="grid grid-cols-5 gap-6 mt-6 max-w-5xl">
//             {Object.entries(filterCategories).map(([category, label]) => (
//               <div key={category} className="space-y-2">
//                 <h3 className="font-medium text-gray-900 mb-3">{label}</h3>
//                 <div className="flex flex-col gap-2">
//                   {additionalFilters[category]?.map((option) => {
//                     const value = typeof option === 'object' ? option.value : option;
//                     const label = typeof option === 'object' ? option.label : option;
//                     return (
//                       <button
//                         key={`${category}-${label}`}
//                         onClick={() => handleFilterClick(category, value)}
//                         className={`px-4 py-2 rounded-full text-sm font-medium transition-colors text-left
//                           ${isSelected(category, value)
//                             ? 'bg-gray-500 text-white'
//                             : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
//                           }`}
//                       >
//                         {label}
//                       </button>
//                     );
//                   })}
//                 </div>
//               </div>
//             ))}
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default FilterSection;

import React, { useState, useEffect, useRef } from 'react';

const FilterSection = ({ onFilterChange, models }) => {
  const [selectedFilters, setSelectedFilters] = useState({
    displayMode: 'all',
    age: null,
    nationality: null,
    height: null,
    bodyType: null
  });
  
  const [showAllFilters, setShowAllFilters] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);
  const dropdownRefs = useRef({});

  // Close dropdowns when clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (openDropdown && 
          dropdownRefs.current[openDropdown] && 
          !dropdownRefs.current[openDropdown].contains(event.target)) {
        setOpenDropdown(null);
      }
    }
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [openDropdown]);

  const toggleDropdown = (name) => {
    setOpenDropdown(openDropdown === name ? null : name);
  };
  
  const getUniqueValues = (field) => {
    const values = new Set(models.map(model => model[field]).filter(Boolean));
    return Array.from(values);
  };

  // Function to get age ranges that have models
  const getAgeRanges = () => {
    const ages = models.map(model => model.age).filter(Boolean);
    const ranges = [
      { label: '18 - 24', value: [18, 24] },
      { label: '24 - 28', value: [24, 28] },
      { label: '28 - 35', value: [28, 35] },
      { label: '35+', value: [35, 100] }
    ];
    return ranges.filter(range => 
      ages.some(age => age >= range.value[0] && age <= range.value[1])
    );
  };

  // Function to get height ranges that have models
  const getHeightRanges = () => {
    const heights = models.map(model => model.height).filter(Boolean);
    const ranges = [
      { label: 'Under 150cm', value: [0, 150] },
      { label: '150 - 165cm', value: [150, 165] },
      { label: '165 - 175cm', value: [165, 175] },
      { label: '175cm +', value: [175, 999] }
    ];
    return ranges.filter(range => 
      heights.some(height => height >= range.value[0] && height <= range.value[1])
    );
  };

  const handleFilterClick = (category, value) => {
    const newFilters = {
      ...selectedFilters,
      [category]: selectedFilters[category]?.toString() === value?.toString() ? null : value
    };
    
    if (category === 'displayMode' && value === 'all') {
      Object.keys(newFilters).forEach(key => {
        if (key !== 'displayMode') newFilters[key] = null;
      });
    } else {
      newFilters.displayMode = 'filtered';
    }

    setSelectedFilters(newFilters);
    onFilterChange(newFilters);
    setOpenDropdown(null); // Close dropdown after selection
  };

  // Main filter options that are always visible
  const mainFilters = {
    nationality: getUniqueValues('nationality').slice(0, 1),
    bodyType: getUniqueValues('bodyType').slice(0, 1),
    age: getAgeRanges().slice(0, 1),
    height: getHeightRanges().slice(0, 1)
  };

  // Additional filter options shown when expanded
  const additionalFilters = {
    nationality: getUniqueValues('nationality').slice(1),
    age: getAgeRanges().slice(1),
    height: getHeightRanges().slice(1),
    bodyType: getUniqueValues('bodyType').slice(1)
  };

  const filterCategories = {
    nationality: 'Nationality',
    bodyType: 'Body Type',
    age: 'Age',
    height: 'Height'
  };

  const isSelected = (category, value) => {
    const selectedValue = selectedFilters[category];
    if (Array.isArray(value)) {
      return JSON.stringify(selectedValue) === JSON.stringify(value);
    }
    return selectedValue === value;
  };

  // Helper function to get selected filter label
  const getSelectedLabel = (category) => {
    if (!selectedFilters[category]) return filterCategories[category];
    
    if (category === 'age' || category === 'height') {
      const ranges = category === 'age' ? getAgeRanges() : getHeightRanges();
      const selectedRange = ranges.find(range => 
        JSON.stringify(range.value) === JSON.stringify(selectedFilters[category])
      );
      return selectedRange ? selectedRange.label : filterCategories[category];
    }
    
    return selectedFilters[category];
  };

  return (
    <div className="bg-white border-t border-gray-200">
      <div className="container mx-auto py-4 px-4">
        {/* Header with Refine icon */}
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center gap-2">
            <svg 
              width="20" 
              height="20" 
              viewBox="0 0 20 20" 
              fill="none" 
              xmlns="http://www.w3.org/2000/svg"
              className="text-gray-500"
            >
              <g clipPath="url(#clip0_119_549)">
                <path d="M2.5 14.1667V15.8333H7.5V14.1667H2.5ZM2.5 4.16667V5.83333H10.8333V4.16667H2.5ZM10.8333 17.5V15.8333H17.5V14.1667H10.8333V12.5H9.16667V17.5H10.8333ZM5.83333 7.5V9.16667H2.5V10.8333H5.83333V12.5H7.5V7.5H5.83333ZM17.5 10.8333V9.16667H9.16667V10.8333H17.5ZM12.5 7.5H14.1667V5.83333H17.5V4.16667H14.1667V2.5H12.5V7.5Z" 
                fill="currentColor"
              />
              </g>
            </svg>
            <span className="text-gray-600 font-medium" style={{ fontFamily: 'InterSB' }}>Refine</span>
          </div>
          <button
            onClick={() => setShowAllFilters(!showAllFilters)}
            className="text-gray-500 font-medium hover:text-gray-800 flex items-center gap-1" style={{ fontFamily: 'InterSB' }}
          >
            {showAllFilters ? (
              <>
                <span>Show Less</span>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19 15L12 8L5 15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </>
            ) : (
              <>
                <span>Show All</span>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5 9L12 16L19 9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </>
            )}
          </button>
        </div>

        {/* Desktop View: Main filters row */}
        <div className="hidden md:flex flex-wrap items-center gap-2">
          <button
            onClick={() => handleFilterClick('displayMode', 'all')}
            className={`px-4 py-2 rounded-full text-sm font-medium transition-colors
              ${selectedFilters.displayMode === 'all'
                ? 'bg-gray-500 text-white'
                : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
              }`}
            style={{ fontFamily: 'InterSB' }}
          >
            All Escorts
          </button>

          {Object.entries(mainFilters).map(([category, options]) => (
            options.map((option) => {
              const value = typeof option === 'object' ? option.value : option;
              const label = typeof option === 'object' ? option.label : option;
              return (
                <button
                  key={`${category}-${label}`}
                  onClick={() => handleFilterClick(category, value)}
                  className={`px-4 py-2 rounded-full text-sm font-medium transition-colors
                    ${isSelected(category, value)
                      ? 'bg-gray-500 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                    }`}
                  style={{ fontFamily: 'InterSB' }}
                >
                  {label}
                </button>
              );
            })
          ))}
        </div>
        
        {/* Mobile View: Dropdown filters */}
        <div className="md:hidden flex flex-wrap items-center gap-2">
          <button
            onClick={() => handleFilterClick('displayMode', 'all')}
            className={`px-4 py-2 rounded-full text-sm font-medium transition-colors
              ${selectedFilters.displayMode === 'all'
                ? 'bg-gray-500 text-white'
                : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
              }`}
            style={{ fontFamily: 'InterSB' }}
          >
            All Escorts
          </button>
          
          {Object.entries(filterCategories).map(([category, label]) => (
            <div 
              key={category}
              className="relative inline-block"
              ref={el => dropdownRefs.current[category] = el}
            >
              <button
                onClick={() => toggleDropdown(category)}
                className={`inline-flex justify-between items-center w-28 px-3 py-1.5 text-sm font-medium rounded-full
                  ${selectedFilters[category] 
                    ? 'bg-gray-500 text-white' 
                    : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                style={{ fontFamily: 'InterSB' }}
              >
                <span className="truncate">{getSelectedLabel(category)}</span>
                <svg className="h-4 w-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
              </button>
              
              {openDropdown === category && (
                <div className="absolute left-0 z-10 mt-1 w-48 max-h-60 overflow-y-auto rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                  <div className="py-1" role="menu">
                    {/* Clear filter option */}
                    <button
                      onClick={() => handleFilterClick(category, null)}
                      className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      style={{ fontFamily: 'InterSB' }}
                    >
                      Any {label}
                    </button>
                    
                    {/* Main filters */}
                    {mainFilters[category]?.map((option) => {
                      const value = typeof option === 'object' ? option.value : option;
                      const label = typeof option === 'object' ? option.label : option;
                      return (
                        <button
                          key={`main-${category}-${label}`}
                          onClick={() => handleFilterClick(category, value)}
                          className={`block w-full text-left px-4 py-2 text-sm ${
                            isSelected(category, value)
                              ? 'bg-gray-100 font-medium text-gray-900'
                              : 'text-gray-700 hover:bg-gray-50'
                          }`}
                          style={{ fontFamily: 'InterSB' }}
                        >
                          {label}
                        </button>
                      );
                    })}
                    
                    {/* Additional filters */}
                    {additionalFilters[category]?.map((option) => {
                      const value = typeof option === 'object' ? option.value : option;
                      const label = typeof option === 'object' ? option.label : option;
                      return (
                        <button
                          key={`additional-${category}-${label}`}
                          onClick={() => handleFilterClick(category, value)}
                          className={`block w-full text-left px-4 py-2 text-sm ${
                            isSelected(category, value)
                              ? 'bg-gray-100 font-medium text-gray-900'
                              : 'text-gray-700 hover:bg-gray-50'
                          }`}
                          style={{ fontFamily: 'InterSB' }}
                        >
                          {label}
                        </button>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>

        {/* Desktop: Additional filters grid */}
        {showAllFilters && (
          <div className="hidden md:grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-4 md:gap-6 mt-6">
            {Object.entries(filterCategories).map(([category, label]) => (
              <div key={category} className="space-y-2">
                <h3 className="font-medium text-gray-900 mb-3" style={{ fontFamily: 'InterSB' }}>{label}</h3>
                <div className="flex flex-col gap-2">
                  {additionalFilters[category]?.map((option) => {
                    const value = typeof option === 'object' ? option.value : option;
                    const label = typeof option === 'object' ? option.label : option;
                    return (
                      <button
                        key={`${category}-${label}`}
                        onClick={() => handleFilterClick(category, value)}
                        className={`px-4 py-2 rounded-full text-sm font-medium transition-colors text-left
                          ${isSelected(category, value)
                            ? 'bg-gray-500 text-white'
                            : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                          }`}
                        style={{ fontFamily: 'InterSB' }}
                      >
                        {label}
                      </button>
                    );
                  })}
                </div>
              </div>
            ))}
          </div>
        )}
        
        {/* Mobile: Additional filters accordion */}
        {showAllFilters && (
          <div className="md:hidden space-y-4 mt-4">
            {Object.entries(filterCategories).map(([category, label]) => (
              <div key={category} className="border rounded-lg overflow-hidden">
                <h3 className="font-medium text-gray-900 p-3 bg-gray-50" style={{ fontFamily: 'InterSB' }}>{label}</h3>
                <div className="p-3 space-y-2">
                  {[...mainFilters[category] || [], ...additionalFilters[category] || []].map((option) => {
                    const value = typeof option === 'object' ? option.value : option;
                    const label = typeof option === 'object' ? option.label : option;
                    return (
                      <button
                        key={`${category}-${label}`}
                        onClick={() => handleFilterClick(category, value)}
                        className={`block w-full px-4 py-2 rounded-full text-sm font-medium transition-colors text-center
                          ${isSelected(category, value)
                            ? 'bg-gray-500 text-white'
                            : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                          }`}
                        style={{ fontFamily: 'InterSB' }}
                      >
                        {label}
                      </button>
                    );
                  })}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default FilterSection;