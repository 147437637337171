import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import Footer from '../components/Footer';

const FAQItem = ({ question, answer, isOpen, toggleOpen }) => {
  return (
    <div className="border-b border-gray-200 last:border-none">
      <button
        className="w-full flex justify-between items-center py-4 text-left"
        onClick={toggleOpen}
      >
        <span className="text-lg font-medium text-gray-900" style={{ fontFamily: 'InterSB' }}>
          {question}
        </span>
        {isOpen ? (
          <ChevronUp className="h-5 w-5 text-gray-500" />
        ) : (
          <ChevronDown className="h-5 w-5 text-gray-500" />
        )}
      </button>
      {isOpen && (
        <div className="pb-4 pr-8">
          <p className="text-gray-600">{answer}</p>
        </div>
      )}
    </div>
  );
};

const FAQSection = ({ title, faqs }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="mb-8">
      <h2 className="text-2xl font-semibold mb-4" style={{ fontFamily: 'InterSB' }}>
        {title}
      </h2>
      <div className="bg-white rounded-lg shadow-lg px-6 py-2">
        {faqs.map((faq, index) => (
          <FAQItem
            key={index}
            question={faq.question}
            answer={faq.answer}
            isOpen={openIndex === index}
            toggleOpen={() => toggleFAQ(index)}
          />
        ))}
      </div>
    </div>
  );
};

const FAQPage = () => {
  const generalFAQs = [
    {
      question: "How do I create an account?",
      answer: "Creating an account is simple. Click the 'Log in' button in the top right corner and navigate to Sign up, fill in your details. Press add your listing, verify your age, and you're ready to go. Make sure to review our terms of service and privacy policy during registration."
    },
    {
      question: "How do I post an advertisement?",
      answer: "After logging in, click on 'Add Your Listing' from your dashboard. Fill in your profile details, upload photos, and select your preferred subscription plan. Your listing will be reviewed and published once approved."
    },
    {
      question: "What payment methods do you accept?",
      answer: "We accept major credit cards, and debit cards. All payments are processed securely through our payment gateway. For specific payment queries, please contact our support team."
    },
    {
      question: "How can I edit my listing?",
      answer: "Log into your account, go to your dashboard, and click the 'Edit' button next to your listing. You can update your information, photos, and contact details."
    }
  ];

  const verificationFAQs = [
    {
      question: "How do I get verified?",
      answer: "To get verified, click the 'Get Verified' button in your dashboard. You'll need to provide government-issued ID and complete our verification process. This typically takes 24-48 hours."
    },
    {
      question: "What documents are required for verification?",
      answer: "You'll need a valid government-issued photo ID and proof of age. All documents are handled securely and confidentially."
    }
  ];

  const subscriptionFAQs = [
    {
      question: "What subscription plans are available?",
      answer: "We offer Standard and Premium plans. Standard includes basic listing features, while Premium offers enhanced visibility, priority support, and additional promotional features."
    },
    {
      question: "Can I upgrade my subscription?",
      answer: "Yes, you can upgrade your subscription at any time from your dashboard. The price difference will be prorated for your current billing period."
    },
    {
      question: "What happens when my subscription expires?",
      answer: "Your listing will no longer be visible to the public, but will be preserved until deletion or an extended period of inactivity. To reactivate, simply renew your subscription through your dashboard."
    }
  ];

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="bg-white border-b">
        <div className="container mx-auto py-6 px-6">
          <h1 className="text-3xl font-light text-gray-900 pb-4" style={{
            fontFamily: 'InterSB'
          }}>Frequently Asked Questions</h1>
          <p className="text-gray-600">
            Find answers to common questions about our services
          </p>
        </div>
      </div>

      <div className="container mx-auto py-8 px-6">
        {/* Quick Links */}
        <div className="rounded-lg shadow-lg p-6 mb-8">
          <div className="grid md:grid-cols-3 gap-4">
            <div className="text-center">
              <h3 className="font-semibold mb-2" style={{ fontFamily: 'InterSB' }}>
                New to Working Girl?
              </h3>
              <p className="text-sm">Learn how to get started with our platform</p>
            </div>
            <div className="text-center">
              <h3 className="font-semibold mb-2" style={{ fontFamily: 'InterSB' }}>
                Need Support?
              </h3>
              <p className="text-sm">Contact our 24/7 support team</p>
            </div>
            <div className="text-center">
              <h3 className="font-semibold mb-2" style={{ fontFamily: 'InterSB' }}>
                Want to Advertise?
              </h3>
              <p className="text-sm">View our advertising options and rates</p>
            </div>
          </div>
        </div>

        {/* FAQ Sections */}
        <FAQSection title="General Questions" faqs={generalFAQs} />
        <FAQSection title="Verification" faqs={verificationFAQs} />
        <FAQSection title="Subscriptions" faqs={subscriptionFAQs} />

        {/* Still Need Help Section */}
        <div className="bg-gray-50 rounded-lg p-8 text-center">
          <h2 className="text-2xl font-semibold mb-4" style={{ fontFamily: 'InterSB' }}>
            Still Need Help?
          </h2>
          <p className="text-gray-600 mb-6">
            Can't find the answer you're looking for? Our support team is here to help.
          </p>
          <button 
            className="bg-gradient-to-b from-elgrad to-elink text-white px-6 py-2 rounded-full text-sm font-medium hover:opacity-90"
            style={{ fontFamily: 'InterSB' }}
            onClick={() => window.location.href = '/contact'}
          >
            Contact Support
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default FAQPage;