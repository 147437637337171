import React from 'react';
import { Mail, MapPin, Phone } from 'lucide-react';
import Footer from '../components/Footer';

const ContactPage = () => {
  return (
    <div className="min-h-screen bg-gray-100">
      <div className="bg-white border-b">
        <div className="container mx-auto py-6 px-6">
          <h1 className="text-3xl font-light text-gray-900 pb-4" style={{
            fontFamily: 'InterSB'
          }}>Contact Us</h1>
          <p className="text-gray-600">
            Get in touch with our team
          </p>
        </div>
      </div>

      <div className="container mx-auto py-8 px-6">
        <div className="bg-white rounded-lg shadow-lg p-8">
          <div className="max-w-3xl mx-auto">
            <div className="text-gray-700 mb-8">
              <p className="mt-4">
                <strong style={{ fontFamily: 'InterSB' }}>Workinggirl.co.nz Limited</strong> is a <strong>New Zealand</strong> business 
              </p>
            </div>

            <div className="grid md:grid-cols-2 gap-8">
              <div className="space-y-6">
                <div className="flex items-start space-x-4">
                  <div className="bg-gradient-to-b from-elgrad to-elink p-3 rounded-lg">
                    <MapPin className="h-6 w-6 text-white" />
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold mb-2" style={{ fontFamily: 'InterSB' }}>
                      Address
                    </h3>
                    <p className="text-gray-600">
                      24 Dress Circle<br />
                      Newlands<br />
                      Wellington, 6037<br />
                      New Zealand
                    </p>
                  </div>
                </div>

                <div className="flex items-start space-x-4">
                  <div className="bg-gradient-to-b from-elgrad to-elink p-3 rounded-lg">
                    <Mail className="h-6 w-6 text-white" />
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold mb-2" style={{ fontFamily: 'InterSB' }}>
                      Email Us
                    </h3>
                    <p className="text-gray-600">
                      General Inquiries and Support:<br />
                      workinggirlescorts@gmail.com<br />
                    </p>
                  </div>
                </div>

                <div className="flex items-start space-x-4">
                  <div className="bg-gradient-to-b from-elgrad to-elink p-3 rounded-lg">
                    <Phone className="h-6 w-6 text-white" />
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold mb-2" style={{ fontFamily: 'InterSB' }}>
                      Phone
                    </h3>
                    <p className="text-gray-600">
                      Customer Service:<br />
                      021 459 109
                    </p>
                  </div>
                </div>
              </div>

              <div className="bg-gray-50 p-6 rounded-lg">
                <h3 className="text-lg font-semibold mb-4" style={{ fontFamily: 'InterSB' }}>
                  Business Hours
                </h3>
                <div className="space-y-2 text-gray-600">
                  <p className="flex justify-between">
                    <span>Monday - Friday:</span>
                    <span>9:00 AM - 6:00 PM</span>
                  </p>
                  <p className="flex justify-between">
                    <span>Saturday:</span>
                    <span>10:00 AM - 4:00 PM</span>
                  </p>
                  <p className="flex justify-between">
                    <span>Sunday:</span>
                    <span>Closed</span>
                  </p>
                  <div className="pt-4 mt-4 border-t border-gray-200">
                    <p className="text-sm">
                      Note: Email support is available 24/7. Response times may vary outside of business hours.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-8 pt-8 border-t border-gray-200">
              <h3 className="text-lg font-semibold mb-4 text-center" style={{ fontFamily: 'InterSB' }}>
                Our Services
              </h3>
              <p className="text-gray-600 mb-4">
                Working Girl provides a professional online directory connecting adult service providers with clients 
                throughout New Zealand. Our services include:
              </p>
              <ul className="list-disc pl-6 text-gray-600 mb-6">
                <li>Profile listings for independent escorts and adult service providers</li>
                <li>Advertising platform for adult entertainment businesses</li>
                <li>Secure messaging system between providers and clients</li>
                <li>Verified reviews and ratings system</li>
                <li>Premium placement and promotional opportunities</li>
              </ul>
              <p className="text-center text-gray-600">
                For urgent matters, please email us at workinggirlescorts@gmail.com with "URGENT" in the subject line.
              </p>
            </div>
          </div>      
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ContactPage;