import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../components/logo.png';
import { useAuth } from '../context/AuthContext';
import '../styles/fonts.css';
import UserMenu from '../components/UserMenu';
import { Heart } from 'lucide-react';
import { heartEventEmitter, getHeartedModels } from './HeartSystem';

const DashboardHeader = ({ sidebarOpen, setSidebarOpen }) => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [heartedCount, setHeartedCount] = useState(getHeartedModels().length || 0);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const handleModelClick = (userId) => {
    navigate(`/profile/${userId}`);
  };

  React.useEffect(() => {
    const updateHeartedCount = () => {
      const count = getHeartedModels().length;
      setHeartedCount(count);
    };

    updateHeartedCount();
    const unsubscribe = heartEventEmitter.subscribe(updateHeartedCount);

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <header className="sticky top-0 bg-white border-b z-50 w-full">
      <nav className="px-4 sm:px-6 lg:px-4">
        <div className="flex h-16 items-center justify-between">
          <div className="flex items-center">
            <button 
              onClick={toggleSidebar} 
              className="mr-4 md:hidden flex items-center justify-center"
              aria-label="Toggle sidebar"
            >
              {sidebarOpen ? (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z" fill="#6A6A6A"/>
                </svg>
              ) : (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4 18H20V16H4V18ZM4 13H20V11H4V13ZM4 6V8H20V6H4Z" fill="#6A6A6A"/>
                </svg>
              )}
            </button>
            <Link to="/" className="flex items-center">
              <img src={logo} className="w-40" alt="Working Girl Logo" />
            </Link>
          </div>
          
          <div className="flex items-center gap-4">
            {user && (
              <UserMenu handleLogout={handleLogout} />
            )}
                        <Link 
              to="/favorites" 
              className="flex items-center gap-2 px-2 py-2 rounded-full hover:bg-gray-100 transition-colors"
            >
              <Heart 
                className={
                  heartedCount > 0 
                  ? 'w-5 h-5 text-heart fill-heart [&>path]:stroke-none' 
                  : 'w-5 h-5 text-gray-300 stroke-current'
                } 
              />
            </Link>
          </div>
          
        </div>
      </nav>
    </header>
  );
};

export default DashboardHeader;