

import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import '../styles/fonts.css';
import AvatarB from '../components/avatarb.svg';
import plussign from '../components/plussign.svg';
import logo from '../components/logo.png';
import menuIcon from '../components/menu-icon.svg';
import ModelSearch from './ModelSearch';
import { Heart } from 'lucide-react';
import { heartEventEmitter, getHeartedModels } from './HeartSystem';

const MobileHeader = ({ models }) => {
    const { user, logout } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    const [showNavbar, setShowNavbar] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [heartedCount, setHeartedCount] = useState(getHeartedModels().length || 0);

    const locations = ['Wellington', 'Auckland', 'South Island', 'Hamilton', 'Christchurch', 'Tauranga', 'Central North Island']; 

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const navigateToLocation = (locationName) => {
        navigate(`/locations/${locationName}`);
        setSidebarOpen(false); 
    };

    const handleLogout = async () => {
        try {
            await logout();
            navigate('/login');
        } catch (error) {
            console.error('Logout error:', error);
        }
    };

    const handleModelClick = (userId) => {
        navigate(`/profile/${userId}`);
        setSidebarOpen(false);
    };

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;
            if (currentScrollY > lastScrollY && currentScrollY > 100) {
                setShowNavbar(false); 
            } else {
                setShowNavbar(true); 
            }
            setLastScrollY(currentScrollY);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollY]);

      React.useEffect(() => {
        const updateHeartedCount = () => {
          const count = getHeartedModels().length;
          setHeartedCount(count);
        };
    
        updateHeartedCount();
        const unsubscribe = heartEventEmitter.subscribe(updateHeartedCount);
    
        return () => {
          unsubscribe();
        };
      }, []);

    return (
        <>
{sidebarOpen && (
  <div
    className="fixed inset-0 z-20 bg-black bg-opacity-50 transition duration-300"
    onClick={toggleSidebar}
  ></div>
)}

            
            <aside
                className={`fixed inset-y-0 left-0 z-50 w-64 bg-white transform ${
                    sidebarOpen ? 'translate-x-0' : '-translate-x-full'
                } transition-transform duration-300 shadow-lg`}
            >
                <div className="flex pl-2 h-16 items-center">
                <button onClick={toggleSidebar} className="mr-4">
                {sidebarOpen ? (
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z" fill="#6A6A6A"/>
                    </svg>
                    
                ) : (
                    <img src={menuIcon} className="w-6 h-6" alt="Open Menu" />
                )}
            </button>
            <Link to="/" onClick={() => setSidebarOpen(false)} className="flex items-center ${setSidebarOpen(false);}">
                <img src={logo} className="w-40" alt="Logo" />
                
            </Link>
            </div>
            <ul
                className="p-4 text-xl"
                style={{
                    fontFamily: 'InterSB',
                    lineHeight: '1.5',
                }}
            >
                <li className="py-2">
                    <Link
                        to="/"
                        onClick={() => setSidebarOpen(false)}
                        className="text-left text-black hover:text-gray-500 w-full"
                    >
                        All Escorts
                    </Link>
                </li>
                {locations.map((loc) => (
                    <li key={loc} className="py-2">
                        <button
                            onClick={() => navigateToLocation(loc)}
                            className="text-left text-black hover:text-gray-500 w-full"
                        >
                            {loc}
                        </button>
                    </li>
                ))}
            </ul>
            <div className="px-4">
            <ModelSearch 
                    models={models} 
                    onModelClick={handleModelClick} 
                />
                </div>
                <div className="absolute bottom-0 w-full p-4 border-t flex flex-col items-center space-y-2">
    {user ? (
        <div className="flex flex-col items-center space-y-2 w-full">
            <button
                onClick={handleLogout}
                className="w-full bg-white text-gray-500 px-4 py-2 rounded-full border border-solid border-gray-400 text-xs font-medium hover:bg-gray-200 flex items-center justify-center"
                style={{
                    fontFamily: 'InterSB',
                    lineHeight: '1.5',
                }}
            >
                <img src={AvatarB} className="w-4 h-4 me-2" alt="" />
                Sign Out
            </button>
            <Link to="/dashboard" className="w-full">
                <button
                    className="w-full bg-gradient-to-b from-elgrad to-elink text-white px-4 py-2 rounded-full text-xs font-medium hover:opacity-90 flex items-center justify-center"
                    style={{
                        fontFamily: 'InterSB',
                        lineHeight: '1.5',
                    }}
                >
                    <img src={plussign} className="w-4 h-4 me-2" alt="" />
                    Add Your Listing
                </button>
            </Link>
        </div>
    ) : (
        <Link
            to="/login"
            className="w-full bg-white text-gray-400 px-4 py-2 rounded-full border border-solid border-gray-400 text-sm font-medium hover:bg-gray-200 flex items-center justify-center"
        >
            Login
        </Link>
    )}
</div>
            </aside>

            {/* <header
    className={`md:hidden sticky top-0 bg-white border-b z-30 transition-transform duration-300 ${
        showNavbar ? 'transform translate-y-0' : 'transform -translate-y-full'
    }`}
>
    <nav className="px-4 sm:px-6 lg:px-8">
        <div className="flex h-16 items-center">
        <button onClick={toggleSidebar} className="mr-4">
                {sidebarOpen ? (
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z" fill="#6A6A6A"/>
                    </svg>
                    
                ) : (
                    <img src={menuIcon} className="w-6 h-6" alt="Open Menu" />
                )}
            </button>
            <Link to="/" className="flex items-center">
                <img src={logo} className="w-40" alt="Logo" />
            </Link>
            <Link 
              to="/favorites" 
              className="flex items-center gap-2 px-2 py-2 rounded-full hover:bg-gray-100 transition-colors"
            >
              <Heart 
                className={
                  heartedCount > 0 
                  ? 'w-5 h-5 text-heart fill-heart [&>path]:stroke-none' 
                  : 'w-5 h-5 text-gray-300 stroke-current'
                } 
              />
            </Link>
        </div>
        
    </nav>
</header> */}

<header
    className={`md:hidden sticky top-0 bg-white border-b z-30 transition-transform duration-300 ${
        showNavbar ? 'transform translate-y-0' : 'transform -translate-y-full'
    }`}
>
    <nav className="px-4 sm:px-6 lg:px-8">
        <div className="flex h-16 items-center justify-between">
            {/* Left side with menu button and logo */}
            <div className="flex items-center">
                <button onClick={toggleSidebar} className="mr-4">
                    {sidebarOpen ? (
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z" fill="#6A6A6A"/>
                        </svg>
                    ) : (
                        <img src={menuIcon} className="w-6 h-6" alt="Open Menu" />
                    )}
                </button>
                <Link to="/" className="flex items-center">
                    <img src={logo} className="w-40" alt="Logo" />
                </Link>
            </div>
            
            {/* Right side with heart icon */}
            <div className="flex items-center">
                <Link 
                    to="/favorites" 
                    className="flex items-center gap-2 px-2 py-2 rounded-full hover:bg-gray-100 transition-colors"
                >
                    <Heart 
                        className={
                            heartedCount > 0 
                            ? 'w-5 h-5 text-heart fill-heart [&>path]:stroke-none' 
                            : 'w-5 h-5 text-gray-300 stroke-current'
                        } 
                    />
                </Link>
            </div>
        </div>
    </nav>
</header>
        </>
    );
};

export default MobileHeader;