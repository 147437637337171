// // import React, { createContext, useContext, useEffect, useState } from 'react';

// // const AuthContext = createContext(null);

// // export const AuthProvider = ({ children }) => {
// //     const [user, setUser] = useState(null);
// //     const [token, setToken] = useState(null);
// //     const [loading, setLoading] = useState(true);

// //     useEffect(() => {
// //         const savedUser = localStorage.getItem('user');
// //         const savedToken = localStorage.getItem('token');
// //         if (savedUser && savedToken) {
// //             try {
// //                 setUser(JSON.parse(savedUser));
// //                 setToken(savedToken);
// //             } catch (error) {
// //                 console.error("Error parsing data", error);
// //                 localStorage.removeItem('user');
// //                 localStorage.removeItem('token');
// //             }
// //         }
// //         setLoading(false);
// //     }, []);

// // const login = (userData) => {
// //     // console.log('AuthContext login', userData);
// //     const userToken = userData.token;

// //     if (!userToken) {
// //         console.error('No token in UserData');
// //         return;
// //     }

// //     localStorage.setItem('user', JSON.stringify(userData));
// //     localStorage.setItem('token', userToken);

// //     setUser(userData);
// //     setToken(userToken);

// //     // console.log('Auth After login', {
// //     //     token: userToken,
// //     //     user: userData,
// //     //     isStored: {
// //     //         token: !!localStorage.getItem('token'),
// //     //         user: !!localStorage.getItem('user')
// //     //     }
// //     // });
// // };

// // const logout = () => {
// //     localStorage.removeItem('user');
// //     localStorage.removeItem('token');
// //     setUser(null);
// //     setToken(null);
// // };

// // const value = {
// //     user,
// //     token,
// //     login,
// //     logout,
// //     isAuthenticated: !!token
// // };

// // if (loading) {
// //     return <div>Loading...</div>;
// // }

// // return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
// // };

// // export const useAuth = () => {
// //     const context = useContext(AuthContext);
// //     if (!context) {
// //         throw new Error('useAuth must be used within an AuthProvider');
// //     }
// //     return context;
// // }

// // export default AuthContext;

// import React, { createContext, useContext, useEffect, useState } from 'react';
// import BASE_URL from '../config'; // Adjust this import path as needed

// const AuthContext = createContext(null);

// export const AuthProvider = ({ children }) => {
//     const [user, setUser] = useState(null);
//     const [isAuthenticated, setIsAuthenticated] = useState(false);
//     const [loading, setLoading] = useState(true);

//     // Check authentication status on initial load
//     useEffect(() => {
//         const checkAuthStatus = async () => {
//             try {
//                 // First check if we have saved user data (for user info only, not auth)
//                 const savedUser = localStorage.getItem('user');
//                 if (savedUser) {
//                     setUser(JSON.parse(savedUser));
//                 }

//                 // Make an API call to verify authentication status
//                 const response = await fetch(`${BASE_URL}/api/auth/status`, {
//                     method: 'GET',
//                     credentials: 'include', // Important for sending cookies
//                     headers: {
//                         'Accept': 'application/json'
//                     }
//                 });

//                 if (response.ok) {
//                     setIsAuthenticated(true);
//                     // Optionally refresh user data from server
//                     const userData = await response.json();
//                     if (userData) {
//                         setUser(userData);
//                         localStorage.setItem('user', JSON.stringify(userData));
//                     }
//                 } else {
//                     // Not authenticated or token expired
//                     setIsAuthenticated(false);
//                 }
//             } catch (error) {
//                 console.error("Auth check failed", error);
//                 setIsAuthenticated(false);
//             } finally {
//                 setLoading(false);
//             }
//         };

//         checkAuthStatus();
//     }, []);

//     const login = async (credentials) => {
//         try {
//             const response = await fetch(`${BASE_URL}/api/auth/login`, {
//                 method: 'POST',
//                 credentials: 'include', // Important for receiving cookies
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'Accept': 'application/json'
//                 },
//                 body: JSON.stringify(credentials)
//             });

//             if (!response.ok) {
//                 throw new Error('Login failed');
//             }

//             const userData = await response.json();
            
//             // Store user data in localStorage (but not the token)
//             localStorage.setItem('user', JSON.stringify(userData));
            
//             setUser(userData);
//             setIsAuthenticated(true);
            
//             return userData;
//         } catch (error) {
//             console.error('Login error:', error);
//             throw error;
//         }
//     };

//     const logout = async () => {
//         try {
//             // Call logout endpoint to clear the cookie
//             await fetch(`${BASE_URL}/api/auth/logout`, {
//                 method: 'POST',
//                 credentials: 'include',
//                 headers: {
//                     'Accept': 'application/json'
//                 }
//             });
//         } catch (error) {
//             console.error('Logout error:', error);
//         } finally {
//             // Clear local state regardless of API success
//             localStorage.removeItem('user');
//             setUser(null);
//             setIsAuthenticated(false);
//         }
//     };

//     const value = {
//         user,
//         login,
//         logout,
//         isAuthenticated
//     };

//     if (loading) {
//         return <div>Loading...</div>;
//     }

//     return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
// };

// export const useAuth = () => {
//     const context = useContext(AuthContext);
//     if (!context) {
//         throw new Error('useAuth must be used within an AuthProvider');
//     }
//     return context;
// };

// export default AuthContext;

import React, { createContext, useContext, useEffect, useState } from 'react';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);

    // On initial load, check if we have user data in localStorage
    useEffect(() => {
        const savedUser = localStorage.getItem('user');
        if (savedUser) {
            try {
                setUser(JSON.parse(savedUser));
                setIsAuthenticated(true);
            } catch (error) {
                console.error("Error parsing stored user data", error);
                localStorage.removeItem('user');
            }
        }
        setLoading(false);
    }, []);

    // This function is now called from the login page with user data
    const login = (userData) => {
        console.log('AuthContext login', userData);
        
        if (!userData || !userData.userId) {
            console.error('Invalid user data in login function');
            return;
        }
        
        // Store the user data (without token) in localStorage
        localStorage.setItem('user', JSON.stringify(userData));
        
        // Update the state
        setUser(userData);
        setIsAuthenticated(true);
        
        console.log('Auth state after login', {
            user: userData,
            isAuthenticated: true
        });
    };

    const logout = async () => {
        try {
            // Clear the auth cookie by calling the logout endpoint
            const response = await fetch(`${process.env.REACT_APP_API_URL || ''}/api/auth/logout`, {
                method: 'POST',
                credentials: 'include'
            });
            
            if (!response.ok) {
                console.warn('Logout endpoint returned error, continuing with local logout');
            }
        } catch (error) {
            console.error('Error during logout request:', error);
        } finally {
            // Always clear local state regardless of API response
            localStorage.removeItem('user');
            setUser(null);
            setIsAuthenticated(false);
        }
    };

    const value = {
        user,
        login,
        logout,
        isAuthenticated
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

export default AuthContext;