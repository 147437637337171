import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/fonts.css';
import Footer from '../components/Footer';

const ManageSubscription = () => {
  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      
      <div className="flex flex-1 overflow-hidden">
        <div className="flex-1 overflow-y-auto">
          <div className="w-full max-w-4xl p-4 sm:p-8 mx-auto">
            <h2 className="text-xl sm:text-2xl font-semibold mb-6">Subscription Management</h2>

            {/* Current Plan Card */}
            <div className="bg-white rounded-lg shadow-lg p-6 mb-6">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4">
                <div>
                  <h3 className="text-lg font-semibold mb-2">Current Plan</h3>
                  <div className="flex items-center gap-2">
                  <h4 className="font-medium mb-1">Unpaid</h4>
                  </div>
                </div>
                <Link 
                  to="/subscriptions"
                  className="mt-4 sm:mt-0 bg-upback px-6 py-2 rounded-full text-sm font-medium hover:opacity-90 flex items-center"
                >
                  <span className="text-upgrade" style={{ fontFamily: 'InterSB' }}>Upgrade Plan</span>
                </Link>
              </div>
              <p className="text-gray-500 text-sm">
                To access all features, please upgrade your plan.
              </p>
            </div>


            {/* Manage Subscription Section */}
            <div className="bg-white rounded-lg shadow-lg p-6">
              <h3 className="text-lg font-semibold mb-4 text-red-600">Cancellation</h3>
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center">
                <div className="mb-2 sm:mb-0">
                  <h4 className="font-medium mb-1">Cancel Subscription</h4>
                  <p className="text-gray-500 text-sm">
                    This will immediately remove access to all premium features
                  </p>
                </div>
                <button 
                  className="bg-gray-600 text-gray-200 px-6 py-2 rounded-full text-sm font-medium hover:opacity-90 mt-2 sm:mt-0"
                  style={{ fontFamily: 'InterSB' }}
                >
                  Cancel Subscription
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ManageSubscription;