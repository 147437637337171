// Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../components/logo.png';

const Footer = () => {
  return (
    <footer className="bg-white border-t">
      <div className="container mx-auto px-6 py-8 ">
      <div className="grid grid-cols-2 md:grid-cols-3 gap-8 mx-auto justify-items-center">
          <div>
            <h3 className="text-gray-800 font-semibold mb-4" style={{ fontFamily: 'InterSB' }}>
              Quick Links
            </h3>
            <ul className="space-y-2">
              <li>
                <Link to="/about" className="text-sm text-gray-600 hover:text-gray-900">
                  About Us
                </Link>
              </li>
              <li>
                <Link to="/contact" className="text-sm text-gray-600 hover:text-gray-900">
                  Contact
                </Link>
              </li>
              <li>
                <Link to="/faq" className="text-sm text-gray-600 hover:text-gray-900">
                  FAQ
                </Link>
              </li>
              <li>
                <Link to="/login" className="text-sm text-gray-600 hover:text-gray-900">
                  Become an Escort
                </Link>
              </li>
              <li>
                <Link to="/subscriptions" className="text-sm text-gray-600 hover:text-gray-900">
                  Advertiser Plans
                </Link>
              </li>
            </ul>
          </div>


{/* Locations */}
<div>
  <h3 className="text-gray-800 font-semibold mb-4" style={{ fontFamily: 'InterSB' }}>
    Locations
  </h3>
    <ul className="space-y-2">
      <li>
        <Link to="/locations/Auckland" className="text-sm text-gray-600 hover:text-gray-900">
          Auckland
        </Link>
      </li>
      <li>
        <Link to="/locations/Wellington" className="text-sm text-gray-600 hover:text-gray-900">
          Wellington
        </Link>
      </li>
      <li>
        <Link to="/locations/Christchurch" className="text-sm text-gray-600 hover:text-gray-900">
          Christchurch
        </Link>
      </li>
      <li>
        <Link to="/locations/Hamilton" className="text-sm text-gray-600 hover:text-gray-900">
          Hamilton
        </Link>
      </li>
    </ul>
    </div>


          {/* Legal */}
          <div>
            <h3 className="text-gray-800 font-semibold mb-4" style={{ fontFamily: 'InterSB' }}>
              Legal
            </h3>
            <ul className="space-y-2">
              <li>
                <Link to="/terms" className="text-sm text-gray-600 hover:text-gray-900">
                  Terms of Service
                </Link>
              </li>
              <li>
                <Link to="/privacy" className="text-sm text-gray-600 hover:text-gray-900">
                  Privacy Policy
                </Link>
              </li>

            </ul>
          </div>
        </div>

        {/* Bottom Section */}
        <div className="border-t mt-8 pt-8">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <p className="text-sm text-gray-600 mb-4 md:mb-0">
              © {new Date().getFullYear()} Workinggirl.co.nz Limited
            </p>
            <div className="flex items-baseline">
                                      <Link to="/" className="flex items-center">
                                          <img src={logo} className="w-44" alt="Logo" />
                                      </Link>
                                      <div
                                          className="hidden sm:ml-6 sm:flex sm:space-x-8 items-center h-full"
                                          style={{
                                              fontFamily: 'InterRegular',
                                              lineHeight: '1',
                                          }}
                                      >
            
                                      </div>
                                  </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;