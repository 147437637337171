import React from 'react';
import { Shield, Clock, CreditCard, Globe } from 'lucide-react';
import Footer from '../components/Footer';

const TermsOfService = () => {
  return (
    <div className="min-h-screen bg-gray-100">
      <div className="bg-white border-b">
        <div className="container mx-auto py-6 px-6">
          <h1 className="text-3xl font-light text-gray-900 pb-4" style={{
            fontFamily: 'InterSB'
          }}>Terms of Service</h1>
          <p className="text-gray-600">
            Last updated: March 20, 2025
          </p>
        </div>
      </div>

      <div className="container mx-auto py-8 px-6">
        <div className="bg-white rounded-lg shadow-lg p-8">
          <div className="max-w-3xl mx-auto">
            {/* 1. Acceptance of Terms */}
            <section className="mb-8 pb-4 border-b border-gray-200">
              <h2 className="text-2xl font-semibold mb-4" style={{ fontFamily: 'InterSB' }}>
                1. ACCEPTANCE OF TERMS
              </h2>
              <p className="text-gray-700 mb-4">
                Workinggirl.co.nz ("the Website") is a classified ads platform that allows independent third-party users to post advertisements related to adult services and erotic dating. By using this Website in any manner, you agree to comply with these Terms of Use ("Terms").
              </p>
              <p className="text-gray-700 mb-4">
                Workinggirl.co.nz is a hosting platform for user-generated content and does not produce, edit, or control the advertisements posted by independent advertisers. Advertisers are solely responsible for ensuring the legality, accuracy, and validity of their content.
              </p>
              <p className="text-gray-700">
                We reserve the right to remove or block any content that violates our Terms of Use and to provide information to law enforcement if illegal activity is detected.
              </p>
            </section>

            {/* 2. Modifications */}
            <section className="mb-8 pb-4 border-b border-gray-200">
              <h2 className="text-2xl font-semibold mb-4" style={{ fontFamily: 'InterSB' }}>
                2. MODIFICATIONS TO THIS AGREEMENT
              </h2>
              <p className="text-gray-700">
                We reserve the right to modify these Terms at any time. Any changes will take effect immediately upon posting. It is your responsibility to review these Terms periodically for updates.
              </p>
            </section>

            {/* 3. Content Responsibility */}
            <section className="mb-8 pb-4 border-b border-gray-200">
              <h2 className="text-2xl font-semibold mb-4" style={{ fontFamily: 'InterSB' }}>
                3. CONTENT RESPONSIBILITY
              </h2>
              <p className="text-gray-700 mb-4">
                All postings, messages, images, videos, and other materials ("Content") on the Website are the sole responsibility of the person from whom such Content originated.
              </p>
              <p className="text-gray-700 mb-4">
                By using the Website, you acknowledge that:
              </p>
              <ul className="list-disc pl-6 space-y-2 text-gray-700 mb-4">
                <li>You may be exposed to content that is offensive, misleading, or objectionable.</li>
                <li>Workinggirl.co.nz does not endorse or verify any content posted.</li>
                <li>You bear all risks associated with using any content on the Website.</li>
                <li>We reserve the right to remove any Content that violates our Terms or applicable laws.</li>
              </ul>
            </section>

            {/* 4. Third-Party Content */}
            <section className="mb-8 pb-4 border-b border-gray-200">
              <h2 className="text-2xl font-semibold mb-4" style={{ fontFamily: 'InterSB' }}>
                4. THIRD-PARTY CONTENT & SERVICES
              </h2>
              <p className="text-gray-700">
                The Website may contain links to third-party websites, services, or advertisements. Workinggirl.co.nz does not control or take responsibility for any third-party content or transactions. Any interactions between users and third parties are solely the responsibility of the parties involved.
              </p>
            </section>

            {/* 5. Copyright */}
            <section className="mb-8 pb-4 border-b border-gray-200">
              <h2 className="text-2xl font-semibold mb-4" style={{ fontFamily: 'InterSB' }}>
                5. COPYRIGHT & INTELLECTUAL PROPERTY CLAIMS
              </h2>
              <p className="text-gray-700 mb-4">
                If you believe your intellectual property rights have been violated, please contact us at workinggirlescorts@gmail.com with the following details:
              </p>
              <ul className="list-disc pl-6 space-y-2 text-gray-700">
                <li>Identification of the copyrighted material.</li>
                <li>Description of the alleged infringement.</li>
                <li>Contact details.</li>
              </ul>
            </section>

            {/* 6. Privacy Policy */}
            <section className="mb-8 pb-4 border-b border-gray-200">
              <h2 className="text-2xl font-semibold mb-4" style={{ fontFamily: 'InterSB' }}>
                6. PRIVACY POLICY & DISCLOSURE
              </h2>
              <p className="text-gray-700">
                By using the Website, you agree to our Privacy Policy. We may disclose user information (including IP addresses and email details) if required by law or to protect the rights, safety, or security of the Website, users, or the public.
              </p>
            </section>

            {/* 7. Governing Law */}
            <section className="mb-8 pb-4 border-b border-gray-200">
              <h2 className="text-2xl font-semibold mb-4" style={{ fontFamily: 'InterSB' }}>
                7. GOVERNING LAW
              </h2>
              <p className="text-gray-700">
                The Website is governed by New Zealand. Any illegal content will be reported to law enforcement. Users are responsible for compliance with applicable local laws, including the New Zealand Prostitution Reform Act 2003.
              </p>
            </section>

            {/* 8. Prohibited Content */}
            <section className="mb-8 pb-4 border-b border-gray-200">
              <h2 className="text-2xl font-semibold mb-4" style={{ fontFamily: 'InterSB' }}>
                8. PROHIBITED CONTENT & CONDUCT
              </h2>
              <p className="text-gray-700 mb-4">
                Users agree not to post, email, or otherwise distribute content that:
              </p>
              <ul className="list-disc pl-6 space-y-2 text-gray-700 mb-4">
                <li>Involves minors (under 18 years old) in any way.</li>
                <li>Violates the Prostitution Reform Act 2003 (e.g., offering unprotected services, non-resident advertising).</li>
                <li>Contains false, misleading, deceptive, or fraudulent information.</li>
                <li>Promotes illegal services, human trafficking, or pimping.</li>
                <li>Uses obscene, offensive, defamatory, or abusive language.</li>
                <li>Contains personal or identifying information without consent.</li>
                <li>Advertises controlled substances or illegal materials.</li>
                <li>Is posted from a non-New Zealand IP address or through a VPN.</li>
              </ul>
              <p className="text-gray-700">
                We reserve the right to remove any content that violates these guidelines and report illegal content to law enforcement.
              </p>
            </section>

            {/* 9. Paid Postings */}
            <section className="mb-8 pb-4 border-b border-gray-200">
              <h2 className="text-2xl font-semibold mb-4" style={{ fontFamily: 'InterSB' }}>
                9. PAID POSTINGS & PAYMENT TERMS
              </h2>
              <p className="text-gray-700">
                Posting a profile requires a non-refundable fee. Payments are processed securely through a third-party provider, and Workinggirl.co.nz does not store credit card details. Refunds are issued on a case-by-case basis. For refund requests, contact workinggirlescorts@gmail.com.
              </p>
            </section>

            {/* 10. Limitations on Service */}
            <section className="mb-8 pb-4 border-b border-gray-200">
              <h2 className="text-2xl font-semibold mb-4" style={{ fontFamily: 'InterSB' }}>
                10. LIMITATIONS ON SERVICE
              </h2>
              <p className="text-gray-700">
                We may impose limits on the use of the Website, including restrictions on posting frequency, content size, and account access. We reserve the right to modify or discontinue any part of the service at any time.
              </p>
            </section>

            {/* 11. Account Termination */}
            <section className="mb-8 pb-4 border-b border-gray-200">
              <h2 className="text-2xl font-semibold mb-4" style={{ fontFamily: 'InterSB' }}>
                11. ACCOUNT TERMINATION
              </h2>
              <p className="text-gray-700">
                We may suspend or terminate accounts at our sole discretion for violations of these Terms. If your account is terminated, you agree not to attempt further use of the Website.
              </p>
            </section>

            {/* 12. Disclaimer of Warranties */}
            <section className="mb-8 pb-4 border-b border-gray-200">
              <h2 className="text-2xl font-semibold mb-4" style={{ fontFamily: 'InterSB' }}>
                12. DISCLAIMER OF WARRANTIES
              </h2>
              <p className="text-gray-700">
                The Website is provided "as is" and "as available" without any warranties, express or implied. We do not guarantee the accuracy, reliability, or security of the Website or its content.
              </p>
            </section>

            {/* 13. Limitations of Liability */}
            <section className="mb-8 pb-4 border-b border-gray-200">
              <h2 className="text-2xl font-semibold mb-4" style={{ fontFamily: 'InterSB' }}>
                13. LIMITATIONS OF LIABILITY
              </h2>
              <p className="text-gray-700">
                Under no circumstances shall Workinggirl.co.nz be liable for any damages arising from the use of the Website, including loss of data, reputation, or financial damages.
              </p>
            </section>

            {/* 14. Verification Process */}
            <section className="mb-8 pb-4 border-b border-gray-200">
              <h2 className="text-2xl font-semibold mb-4" style={{ fontFamily: 'InterSB' }}>
                14. VERIFICATION PROCESS
              </h2>
              <p className="text-gray-700 mb-4">
                To ensure user safety, we require verification:
              </p>
              <ul className="list-disc pl-6 space-y-2 text-gray-700">
                <li>Phone verification: Users must sign up with a valid phone number.</li>
                <li>Age & ID verification: Users must confirm they are over 18 by submitting a government-issued ID to workinggirlescorts@gmail.com.</li>
              </ul>
            </section>

            {/* 15. Reporting Violations */}
            <section className="mb-8 pb-4 border-b border-gray-200">
              <h2 className="text-2xl font-semibold mb-4" style={{ fontFamily: 'InterSB' }}>
                15. REPORTING VIOLATIONS
              </h2>
              <p className="text-gray-700">
                To report a violation, click the "Report" button on the ad or email workinggirlescorts@gmail.com with details.
              </p>
            </section>

            {/* 16. Violation of Terms */}
            <section className="mb-8 pb-4 border-b border-gray-200">
              <h2 className="text-2xl font-semibold mb-4" style={{ fontFamily: 'InterSB' }}>
                16. VIOLATION OF TERMS & LIQUIDATED DAMAGES
              </h2>
              <p className="text-gray-700">
                If you believe someone has violated these Terms, report it using the "Report" function or email workinggirlescorts@gmail.com. Failure to act on a violation does not waive our right to enforce these Terms in the future.
              </p>
            </section>

            {/* 17. Reporting Profiles */}
            <section className="mb-8 pb-4 border-b border-gray-200">
              <h2 className="text-2xl font-semibold mb-4" style={{ fontFamily: 'InterSB' }}>
                17. REPORTING PROFILES & COMPLAINTS
              </h2>
              <p className="text-gray-700 mb-4">
                To report a profile for violations, use the "Report" button on the profile page. Provide as many details as possible.
              </p>
              <p className="text-gray-700">
                For general complaints, email workinggirlescorts@gmail.com with a detailed description of the issue. We will review each complaint thoroughly and take appropriate action, including content removal, suspension, or notification to law enforcement if necessary.
              </p>
            </section>

            {/* 18. Content Guidelines */}
            <section className="mb-8 pb-4 border-b border-gray-200">
              <h2 className="text-2xl font-semibold mb-4" style={{ fontFamily: 'InterSB' }}>
                18. CONTENT GUIDELINES
              </h2>
              <p className="text-gray-700 mb-4">
                Users take full responsibility for the content they post. By posting content on the platform, users agree to:
              </p>
              <ul className="list-disc pl-6 space-y-2 text-gray-700 mb-4">
                <li>Not post content involving minors or illegal services.</li>
                <li>Not use VPNs to post content.</li>
                <li>Ensure images comply with our policies and are not obscene.</li>
                <li>Not link to illegal prostitution or human trafficking content.</li>
                <li>Maintain respectful, non-discriminatory language.</li>
                <li>Avoid false, misleading, or defamatory content.</li>
                <li>Adhere to all New Zealand laws, including the Prostitution Reform Act 2003.</li>
              </ul>
              <p className="text-gray-700">
                Violations may result in content removal, account suspension, or legal action.
              </p>
            </section>
            
            {/* Footer Text */}
            <section>
              <p className="text-gray-700 font-medium">
                By using Workinggirl.co.nz, you acknowledge that you have read, understood, and agreed to these Terms of Use.
              </p>
            </section>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermsOfService;