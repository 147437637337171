import React, { useState, useEffect } from 'react';

const AgeVerification = () => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const hasVerified = localStorage.getItem('ageVerified');
    if (!hasVerified) {
      setShowModal(true);
      // Prevent scrolling when modal is shown
      document.body.style.overflow = 'hidden';
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('ageVerified', 'true');
    setShowModal(false);
    document.body.style.overflow = 'unset';
  };

  const handleDecline = () => {
    window.location.href = 'https://www.google.com';
  };

  if (!showModal) return null;

  return (
    <div className="fixed inset-0 z-50">
    <div className="absolute inset-0 backdrop-blur-md bg-black/30" />
    
    <div className="relative h-full flex items-center justify-center p-4">
      <div 
        className="bg-white rounded-lg max-w-md w-full p-6 shadow-xl" 
        onClick={(e) => e.stopPropagation()}
      >
        <div className="text-center">
          <h2 className="text-2xl mb-4" style={{ fontFamily: 'InterSB' }}>
            Adult Content Warning
            </h2>
            
            <p className="text-gray-600 mb-6">
              The contents of this website are suitable to adults only. You must be 18 years or older to enter.
            </p>

            <div className="space-y-3">
              <button
                onClick={handleAccept}
                className="w-full bg-gradient-to-b from-elgrad to-elink text-white px-6 py-2.5 rounded-full text-sm font-medium hover:opacity-90 transition-opacity"
                style={{ fontFamily: 'InterSB' }}
              >
                I am 18+ years old
              </button>

              <button
                onClick={handleDecline}
                className="w-full bg-gray-100 text-gray-600 px-6 py-2.5 rounded-full text-sm font-medium hover:bg-gray-200 transition-colors"
                style={{ fontFamily: 'InterSB' }}
              >
                Exit Site
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgeVerification;