import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AvatarB from '../components/avatarb.svg';
import plussign from '../components/plussign.svg';

const UserMenu = ({ handleLogout }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="flex items-center space-x-2 relative">
      <div className="relative">
        <button
          onClick={toggleDropdown}
          className="bg-white text-gray-500 p-2 rounded-full border border-solid border-gray-400 hover:bg-gray-200"
        >
          <img src={AvatarB} className="w-4 h-4" alt="avatar" />
        </button>
        
        {isOpen && (
          <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
            <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
              <Link
                to="/dashboard"
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                role="menuitem"
                onClick={() => setIsOpen(false)}
              >
                Dashboard
              </Link>
              <button
                onClick={() => {
                  handleLogout();
                  setIsOpen(false);
                }}
                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                role="menuitem"
              >
                Sign Out
              </button>
            </div>
          </div>
        )}
      </div>
      
      <Link to="/dashboard">
        {/* <button
          className="bg-gradient-to-b from-elgrad to-elink text-white px-4 py-2 rounded-full text-sm font-medium hover:opacity-90 flex items-center"
          style={{
            fontFamily: 'InterSB',
            lineHeight: '1.5',
          }}
        >
          <img src={plussign} className="w-4 h-4 me-2" alt="" />
          Add Your Listing
        </button> */}
        <button
  className="bg-gradient-to-b from-elgrad to-elink text-white rounded-full text-sm font-medium hover:opacity-90 flex items-center justify-center p-2 md:px-4 md:py-2"
  style={{
    fontFamily: 'InterSB',
    lineHeight: '1.5',
  }}
>
  <img src={plussign} className="w-4 h-4 md:mr-2" alt="Add" />
  <span className="hidden md:inline">Add Your Listing</span>
</button>
      </Link>

    </div>
  );
};

export default UserMenu;