import React, { useState, useEffect } from 'react';
import { Heart, HeartOff } from 'lucide-react';

// Create a custom event emitter for heart updates
export const heartEventEmitter = {
  listeners: new Set(),
  emit() {
    this.listeners.forEach(listener => listener());
  },
  subscribe(listener) {
    this.listeners.add(listener);
    return () => this.listeners.delete(listener);
  }
};

const HeartSystem = ({ modelId }) => {
  const [isHearted, setIsHearted] = useState(false);

  useEffect(() => {
    const heartedModels = getHeartedModels();
    setIsHearted(heartedModels.includes(modelId));
  }, [modelId]);

  const toggleHeart = (e) => {
    e.stopPropagation(); 
    const heartedModels = getHeartedModels();
    
    if (isHearted) {
      const updatedHearts = heartedModels.filter(id => id !== modelId);
      sessionStorage.setItem('heartedModels', JSON.stringify(updatedHearts));
      setIsHearted(false);
    } else {

      heartedModels.push(modelId);
      sessionStorage.setItem('heartedModels', JSON.stringify(heartedModels));
      setIsHearted(true);
    }
    
    heartEventEmitter.emit();
  };

//   return (
//     <button 
//       onClick={toggleHeart}
//       className="absolute top-2 right-2 z-10  transition-colors duration-200"
//     >
//       {isHearted ? (
//         <Heart className="w-5 h-5 text-heart fill-heart" />
//       ) : (
//         <Heart className="w-5 h-5 text-gray-300" />
//       )}
//     </button>
//   );
// };
return (
  <button 
    onClick={toggleHeart}
    className="flex items-center justify-center w-full h-full p-0 m-0 transition-colors duration-200"
  >
    {isHearted ? (
      <Heart className="w-5 h-5 text-heart fill-heart m-0" />
    ) : (
      <Heart className="w-5 h-5 text-gray-300 m-0" />
    )}
  </button>
);
};

export const getHeartedModels = () => {
  const stored = sessionStorage.getItem('heartedModels');
  return stored ? JSON.parse(stored) : [];
};

export const useHeartedModels = (allModels) => {
  const [heartedModels, setHeartedModels] = useState([]);

  useEffect(() => {
    const updateHeartedModels = () => {
      const heartedIds = getHeartedModels();
      const heartedModelData = allModels.filter(model => 
        heartedIds.includes(model.modelId)
      );
      setHeartedModels(heartedModelData);
    };

    updateHeartedModels();
    
    const unsubscribe = heartEventEmitter.subscribe(updateHeartedModels);
    
    return () => {
      unsubscribe();
    };
  }, [allModels]);

  return heartedModels;
};

export default HeartSystem;