// import React, { useState, useEffect, useCallback } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import { jwtDecode } from 'jwt-decode';
// import '../styles/fonts.css';
// import BASE_URL from '../config';
// import plussign from '../components/plussign.svg';
// import ProfileActionsMenu from '../components/ProfileActionsMenu.js';
// import { CACHE_KEY } from '../constants/cache';
// import { useAuth } from '../context/AuthContext';

// const SidebarItem = ({ icon, label, onClick, section, show = true }) => {
//   if (!show) return null;
  
//   return (
//     <div 
//       className="flex items-center px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer"
//       onClick={onClick}
//     >
//       <div className="mr-3">{icon}</div>
//       <span>{label}</span>
//     </div>
//   );
// };

// const Skeleton = () => {
//   return (
//     <div className="flex min-h-screen bg-gray-100">

//       <div className="w-64 bg-white border-r">
//         <div className="p-4 border-b">
//           <div className="h-4 bg-gray-200 rounded w-1/2 mb-2 animate-pulse"></div>
//           <div className="h-4 bg-gray-200 rounded w-3/4 animate-pulse"></div>
//         </div>
//         <nav className="mt-4">
//           {[1, 2, 3].map((i) => (
//             <div key={i} className="flex items-center px-4 py-2">
//               <div className="w-6 h-6 bg-gray-200 rounded mr-3 animate-pulse"></div>
//               <div className="h-4 bg-gray-200 rounded w-2/3 animate-pulse"></div>
//             </div>
//           ))}
//         </nav>
//       </div>

//       <div className="flex-1 flex flex-col items-center overflow-y-auto">
//         <div className="w-full max-w-4xl p-8">
//           <div className="h-8 bg-gray-200 rounded w-1/4 mb-6 animate-pulse"></div>

//           <div className="bg-white rounded-lg shadow-lg p-4 mb-4">

//             <div className="flex justify-between items-center mb-4">
//               <div className="w-16 h-6 bg-gray-200 rounded-full animate-pulse"></div>
//               <div className="w-11 h-6 bg-gray-200 rounded-full animate-pulse"></div>
//             </div>

//             <div className="flex space-x-8">
//               <div className="flex flex-col space-y-2 w-full">
//                 <div className="flex space-x-6">

//                   <div className="flex-shrink-0 pb-3">
//                     <div className="w-48 h-48 bg-gray-200 rounded-lg animate-pulse"></div>
//                   </div>

//                   <div className="flex flex-col justify-between w-full">
//                     <div>
//                       <div className="h-6 bg-gray-200 rounded w-1/3 mb-2 animate-pulse"></div>
//                       <div className="h-4 bg-gray-200 rounded w-1/4 animate-pulse"></div>
//                     </div>

//                     <div className="flex gap-4 w-full">
//                       {[1, 2, 3].map((i) => (
//                         <div key={i} className="flex-1 h-8 bg-gray-200 rounded-full animate-pulse"></div>
//                       ))}
//                     </div>
//                   </div>
//                 </div>

//                 <div className="flex items-center gap-1 pb-1">
//                   <div className="w-4 h-4 bg-gray-200 rounded animate-pulse"></div>
//                   <div className="h-4 bg-gray-200 rounded w-32 animate-pulse"></div>
//                 </div>

//                 <div className="grid grid-cols-3 gap-4 w-full">
//                   {[1, 2, 3].map((i) => (
//                     <div key={i} className="bg-white border p-4 rounded-lg">
//                       <div className="h-8 bg-gray-200 rounded w-16 mb-2 animate-pulse"></div>
//                       <div className="h-4 bg-gray-200 rounded w-24 animate-pulse"></div>
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// // const Dashboard = () => {
// //   const [models, setModels] = useState([]);
// //   const [userData, setUserData] = useState({ userId: null, isAgency: false });
// //   const [loading, setLoading] = useState(true);
// //   const [error] = useState(null);
// //   const [userId] = useState(null);

// //   const navigate = useNavigate();


// //   useEffect(() => {
// //     const token = localStorage.getItem('token');
// //     if (token) {
// //       const { userId } = jwtDecode(token);
// //       setUserData(prev => ({ ...prev, userId }));
// //     }
// //   }, []);

// //   const fetchData = useCallback(async () => {
// //     if (!userData.userId) return;
    
// //     setLoading(true);
// //     try {
// //       const [accountResponse, modelsResponse] = await Promise.all([
// //         fetch(`${BASE_URL}/api/userAccounts/${userData.userId}`),
// //         fetch(`${BASE_URL}/api/modelswvisits/${userData.userId}`)
// //       ]);

// //       const [accountData, modelsData] = await Promise.all([
// //         accountResponse.json(),
// //         modelsResponse.json()
// //       ]);

// //       setUserData(prev => ({ 
// //         ...prev, 
// //         isAgency: accountData[0]?.agency === 'true' 
// //       }));

      

// //       const processedModels = Array.isArray(modelsData) ? modelsData : [modelsData];
// //       setModels(processedModels.map(model => ({
// //         ...model,
// //         profileClicks: model.profileClicks || 0,
// //         contactClicks: model.contactClicks || 0,
// //         likes: model.likes || 0
// //       })));

// //     } catch (err) {
// //       console.error('Error fetching data:', err);
// //       setModels([]);
// //     } finally {
// //       setLoading(false);
// //     }
// //   }, [userData.userId]);

// //   useEffect(() => {
// //     fetchData();
// //   }, [fetchData]);

// //   const handleLiveToggle = async (modelId, currentLive) => {
// //     try {
// //       setModels(models.map(model => 
// //         model.modelId === modelId 
// //           ? { ...model, live: !currentLive }
// //           : model
// //       ));
      
// //       const response = await fetch(`${BASE_URL}/api/profiles/${modelId}/live`, {
// //         method: 'PUT',
// //         headers: {
// //           'Authorization': `Bearer ${localStorage.getItem('token')}`,
// //           'Content-Type': 'application/json'
// //         }
// //       });
  
// //       if (!response.ok) {
// //         throw new Error('Failed to update live status');
// //       }
// //       // Clear landing page cache after successful toggle
// //       localStorage.removeItem(CACHE_KEY);
// //       // Set update timestamp
// //       localStorage.setItem('profileUpdated', Date.now());

// //     } catch (error) {
// //       console.error('Error updating live status:', error);
// //     }
// //   };

// //   const handleActiveToggle = async (modelId, currentActive) => {
// //     try {
// //       setModels(models.map(model => 
// //         model.modelId === modelId 
// //           ? { ...model, active: !currentActive }
// //           : model
// //       ));
      
// //       const response = await fetch(`${BASE_URL}/api/profiles/${modelId}/active`, {
// //         method: 'PUT',
// //         headers: {
// //           'Authorization': `Bearer ${localStorage.getItem('token')}`,
// //           'Content-Type': 'application/json'
// //         }
// //       });
  
// //       if (!response.ok) {
// //         throw new Error('Failed to update active status');
// //       }
// //           // Clear landing page cache after successful toggle
// //     localStorage.removeItem(CACHE_KEY);
// //     // Set update timestamp
// //     localStorage.setItem('profileUpdated', Date.now());
  

// //     } catch (error) {
// //       console.error('Error updating active status:', error);
// //     }
// //   };


// //   const handleAddListing = () => {
// //     localStorage.removeItem('currentProfile');
// //     navigate('/profile/new');
// //   };
  

// //   const handleDelete = async (modelId, deleteType) => {
// //     try {
// //       const token = localStorage.getItem('token');
// //       const endpoint = deleteType === 'listing' 
// //         ? `${BASE_URL}/api/profiles/${modelId}/listing`
// //         : `${BASE_URL}/api/profiles/${userId}/account`;
  
// //       const response = await fetch(endpoint, {
// //         method: 'DELETE',
// //         headers: {
// //           'Authorization': `Bearer ${token}`,
// //           'Accept': 'application/json'
// //         }
// //       });
  
// //       if (!response.ok) {
// //         throw new Error('Failed to delete');
// //       }
  
// //       if (deleteType === 'account') {
// //         localStorage.clear();
// //         navigate('/');
// //       } else {
// //         await fetchData();
// //       }
// //     } catch (error) {
// //       console.error('Error deleting:', error);
// //     }
// //   };
// const Dashboard = () => {
//   const [models, setModels] = useState([]);
//   const [userData, setUserData] = useState({ userId: null, isAgency: false });
//   const [loading, setLoading] = useState(true);
//   const [error] = useState(null);
//   const navigate = useNavigate();
//   const { user } = useAuth(); // Get user from auth context

//   // Set user ID from Auth Context
//   useEffect(() => {
//     if (user && user.userId) {
//       setUserData(prev => ({ ...prev, userId: user.userId }));
//     }
//   }, [user]);

//   const fetchData = useCallback(async () => {
//     if (!userData.userId) return;
    
//     setLoading(true);
//     try {
//       const [accountResponse, modelsResponse] = await Promise.all([
//         fetch(`${BASE_URL}/api/userAccounts/${userData.userId}`, {
//           credentials: 'include' // Include cookies
//         }),
//         fetch(`${BASE_URL}/api/modelswvisits/${userData.userId}`, {
//           credentials: 'include' // Include cookies
//         })
//       ]);

//       // Check for auth errors
//       if (accountResponse.status === 401 || modelsResponse.status === 401) {
//         // If either request is unauthorized, redirect to login
//         navigate('/login');
//         return;
//       }

//       const [accountData, modelsData] = await Promise.all([
//         accountResponse.json(),
//         modelsResponse.json()
//       ]);

//       setUserData(prev => ({ 
//         ...prev, 
//         isAgency: accountData[0]?.agency === 'true' 
//       }));

//       const processedModels = Array.isArray(modelsData) ? modelsData : [modelsData];
//       setModels(processedModels.map(model => ({
//         ...model,
//         profileClicks: model.profileClicks || 0,
//         contactClicks: model.contactClicks || 0,
//         likes: model.likes || 0
//       })));

//     } catch (err) {
//       console.error('Error fetching data:', err);
//       setModels([]);
//     } finally {
//       setLoading(false);
//     }
//   }, [userData.userId, navigate]);

//   useEffect(() => {
//     fetchData();
//   }, [fetchData]);

//   const handleLiveToggle = async (modelId, currentLive) => {
//     try {
//       setModels(models.map(model => 
//         model.modelId === modelId 
//           ? { ...model, live: !currentLive }
//           : model
//       ));
      
//       const response = await fetch(`${BASE_URL}/api/profiles/${modelId}/live`, {
//         method: 'PUT',
//         credentials: 'include', // Include cookies
//         headers: {
//           'Content-Type': 'application/json'
//         }
//       });
  
//       if (!response.ok) {
//         throw new Error('Failed to update live status');
//       }
      
//       // Clear landing page cache after successful toggle
//       localStorage.removeItem(CACHE_KEY);
//       // Set update timestamp
//       localStorage.setItem('profileUpdated', Date.now());

//     } catch (error) {
//       console.error('Error updating live status:', error);
//       // Revert the optimistic update on error
//       fetchData();
//     }
//   };

//   const handleActiveToggle = async (modelId, currentActive) => {
//     try {
//       setModels(models.map(model => 
//         model.modelId === modelId 
//           ? { ...model, active: !currentActive }
//           : model
//       ));
      
//       const response = await fetch(`${BASE_URL}/api/profiles/${modelId}/active`, {
//         method: 'PUT',
//         credentials: 'include', // Include cookies
//         headers: {
//           'Content-Type': 'application/json'
//         }
//       });
  
//       if (!response.ok) {
//         throw new Error('Failed to update active status');
//       }
      
//       // Clear landing page cache after successful toggle
//       localStorage.removeItem(CACHE_KEY);
//       // Set update timestamp
//       localStorage.setItem('profileUpdated', Date.now());
  
//     } catch (error) {
//       console.error('Error updating active status:', error);
//       // Revert the optimistic update on error
//       fetchData();
//     }
//   };

//   const handleAddListing = () => {
//     localStorage.removeItem('currentProfile');
//     navigate('/profile/new');
//   };
  
//   const handleDelete = async (modelId, deleteType) => {
//     try {
//       const endpoint = deleteType === 'listing' 
//         ? `${BASE_URL}/api/profiles/${modelId}/listing`
//         : `${BASE_URL}/api/profiles/${userData.userId}/account`;
  
//       const response = await fetch(endpoint, {
//         method: 'DELETE',
//         credentials: 'include', // Include cookies
//         headers: {
//           'Accept': 'application/json'
//         }
//       });
  
//       if (!response.ok) {
//         throw new Error('Failed to delete');
//       }
  
//       if (deleteType === 'account') {
//         localStorage.clear();
//         navigate('/');
//       } else {
//         await fetchData();
//       }
//     } catch (error) {
//       console.error('Error deleting:', error);
//     }
//   };
  


//   if (loading) {
//     return <Skeleton />;
//   }

//   if (error) {
//     return (
//       <div className="flex min-h-screen bg-gray-100 items-center justify-center">
//         <div className="text-red-600">Error: {error}</div>
//       </div>
//     );
//   }

//   return (
//     <div className="flex min-h-screen bg-gray-100">
//       <div className="w-64 bg-white border-r">
//         <div className="p-4 border-b">
//           <span style={{ fontFamily: 'InterSB' }}>
//             {models.length === 1 ? 'Hi, ' + models[0].firstName : 'Hello, '}
//           </span>
//           <br />
//           <span>
//             Welcome to Working Girl! New Zealands online adult worker directory.
//           </span>
//         </div>
//         <nav className="mt-4">
//           <SidebarItem
//             icon={<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
//               <path d="M22 6V18C22 18.55 21.8042 19.0208 21.4125 19.4125C21.0208 19.8042 20.55 20 20 20H4C3.45 20 2.97917 19.8042 2.5875 19.4125C2.19583 19.0208 2 18.55 2 18V6C2 5.45 2.19583 4.97917 2.5875 4.5875C2.97917 4.19583 3.45 4 4 4H20C20.55 4 21.0208 4.19583 21.4125 4.5875C21.8042 4.97917 22 5.45 22 6ZM4 8H20V6H4V8ZM4 12V18H20V12H4Z" fill="black"/>
//               </svg>}
//             label="Subscriptions"
//             section="subscriptions"
//             onClick={() => navigate('/subscriptions')}
//           />
//           <SidebarItem
//             icon={<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
//               <path d="M9.24922 22L8.84922 18.8C8.63255 18.7167 8.42839 18.6167 8.23672 18.5C8.04505 18.3833 7.85755 18.2583 7.67422 18.125L4.69922 19.375L1.94922 14.625L4.52422 12.675C4.50755 12.5583 4.49922 12.4458 4.49922 12.3375V11.6625C4.49922 11.5542 4.50755 11.4417 4.52422 11.325L1.94922 9.375L4.69922 4.625L7.67422 5.875C7.85755 5.74167 8.04922 5.61667 8.24922 5.5C8.44922 5.38333 8.64922 5.28333 8.84922 5.2L9.24922 2H14.7492L15.1492 5.2C15.3659 5.28333 15.5701 5.38333 15.7617 5.5C15.9534 5.61667 16.1409 5.74167 16.3242 5.875L19.2992 4.625L22.0492 9.375L19.4742 11.325C19.4909 11.4417 19.4992 11.5542 19.4992 11.6625V12.3375C19.4992 12.4458 19.4826 12.5583 19.4492 12.675L22.0242 14.625L19.2742 19.375L16.3242 18.125C16.1409 18.2583 15.9492 18.3833 15.7492 18.5C15.5492 18.6167 15.3492 18.7167 15.1492 18.8L14.7492 22H9.24922ZM12.0492 15.5C13.0159 15.5 13.8409 15.1583 14.5242 14.475C15.2076 13.7917 15.5492 12.9667 15.5492 12C15.5492 11.0333 15.2076 10.2083 14.5242 9.525C13.8409 8.84167 13.0159 8.5 12.0492 8.5C11.0659 8.5 10.2367 8.84167 9.56172 9.525C8.88672 10.2083 8.54922 11.0333 8.54922 12C8.54922 12.9667 8.88672 13.7917 9.56172 14.475C10.2367 15.1583 11.0659 15.5 12.0492 15.5Z" fill="black"/>
//               </svg>}
//             label="Settings"
//             section="settings"
//           />
//           <SidebarItem
//             icon={<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
//               <path d="M11.95 18C12.3 18 12.5958 17.8792 12.8375 17.6375C13.0792 17.3958 13.2 17.1 13.2 16.75C13.2 16.4 13.0792 16.1042 12.8375 15.8625C12.5958 15.6208 12.3 15.5 11.95 15.5C11.6 15.5 11.3042 15.6208 11.0625 15.8625C10.8208 16.1042 10.7 16.4 10.7 16.75C10.7 17.1 10.8208 17.3958 11.0625 17.6375C11.3042 17.8792 11.6 18 11.95 18ZM11.05 14.15H12.9C12.9 13.6 12.9625 13.1667 13.0875 12.85C13.2125 12.5333 13.5667 12.1 14.15 11.55C14.5833 11.1167 14.925 10.7042 15.175 10.3125C15.425 9.92083 15.55 9.45 15.55 8.9C15.55 7.96667 15.2083 7.25 14.525 6.75C13.8417 6.25 13.0333 6 12.1 6C11.15 6 10.3792 6.25 9.7875 6.75C9.19583 7.25 8.78333 7.85 8.55 8.55L10.2 9.2C10.2833 8.9 10.4708 8.575 10.7625 8.225C11.0542 7.875 11.5 7.7 12.1 7.7C12.6333 7.7 13.0333 7.84583 13.3 8.1375C13.5667 8.42917 13.7 8.75 13.7 9.1C13.7 9.43333 13.6 9.74583 13.4 10.0375C13.2 10.3292 12.95 10.6 12.65 10.85C11.9167 11.5 11.4667 11.9917 11.3 12.325C11.1333 12.6583 11.05 13.2667 11.05 14.15ZM12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22Z" fill="black"/>
//               </svg>}
//             label="Help"
//             section="help"
//           />
//           <SidebarItem
//             icon={<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#000000"><path d="m344-60-76-128-144-32 14-148-98-112 98-112-14-148 144-32 76-128 136 58 136-58 76 128 144 32-14 148 98 112-98 112 14 148-144 32-76 128-136-58-136 58Zm94-278 226-226-56-58-170 170-86-84-56 56 142 142Z"/></svg>}
//             label="Get Verified"
//             section="verification"
//             show={!models.every(model => model.verified)}
//             onClick={() => navigate('/verification')}
//           />
//         </nav>
//       </div>
  
//       <div className="flex-1 flex flex-col items-center overflow-y-auto">
//         <div className="w-full max-w-4xl p-8">
//           {models.length > 0 && (
//             <h2 className="text-2xl font-semibold mb-6">
//               {userData.isAgency ? "Your Listings" : "Your Listing"}
//             </h2>
//           )}

  
// {models.map((model) => (
//   <div key={model.modelId} className="bg-white rounded-lg shadow-lg p-4 mb-4">
//     {model.verified ? (
//       <div className="flex justify-between items-center mb-4">
//         <div className="flex items-center space-x-2">
//           <span className={`px-2 py-1 bg-gray-200 rounded-full inline-flex items-center ${
//             model.live ? 'text-gray-600' : 'text-gray-500'
//           }`} style={{ fontFamily: 'InterSB' }}>
//             {model.live ? 'LIVE' : 'Hidden'}
//           </span>
//         </div>
//         <ProfileActionsMenu
//   model={model}
//   onToggleLive={handleLiveToggle}
//   onToggleActive={handleActiveToggle}
//   onDelete={handleDelete}
//   isAgency={userData.isAgency}
// />
        
//       </div>
//          ) : (
//           <div className="flex justify-between items-center mb-4">
//           <div className="flex items-center space-x-2">
//             <span className={`px-2 py-1 bg-gray-200 rounded-full inline-flex items-center ${
//               model.live ? 'text-gray-600' : 'text-gray-500'
//             }`} style={{ fontFamily: 'InterSB' }}>
//               {model.live ? 'LIVE' : 'Hidden - Get Verified'}
//             </span>
//           </div>
//           <ProfileActionsMenu
//     model={model}
//     onToggleLive={handleLiveToggle}
//     onToggleActive={handleActiveToggle}
//     onDelete={handleDelete}
//     isAgency={userData.isAgency}
//     isVerified={model.verified}
//   />
          
//         </div>

//          )}

//               <div className="flex space-x-8">
                
//                 <div className="flex flex-col space-y-2 w-full">
                  
//                   <div className="flex space-x-6">
//                     <div className="flex-shrink-0 pb-3 relative">
                      
//                     {model.ImageUrls ? (
//                       <img
//                         src={process.env.NODE_ENV === 'production' 
//                           ? model.ImageUrls.split(',')[0]
//                           : `http://localhost:3001${model.ImageUrls.split(',')[0]}`
//                         }
//                         alt="Profile"
//                         className="w-48 h-48 object-cover rounded-lg"
//                         onError={(e) => {
//                           e.target.src = '/api/placeholder/192/192';
//                         }}
//                       />
//                     ) : (
//                       <div className="w-48 h-48 bg-gray-200 rounded-lg flex items-center justify-center">
//                         <span className="text-gray-500">No Image</span>
//                       </div>
//                     )}
//                     </div>
//                     <div className="flex flex-col justify-between w-full">
//                     <div>
//                       <h3 className="text-xl font-semibold">{model.firstName} {model.lastName}</h3>
//                       {/* <p className="text-sm text-gray-500">Active · Standard Listing</p> */}
//                       <p className="text-sm text-gray-500">
//                       {model.active ? 'Active' : 'Inactive'} · Standard Listing
//                     </p>
//                     </div>
//                     <div className="flex gap-4 w-full">
//                       <Link 
//                         to={`/profile/edit/${model.modelId}`}
//                         className="flex-1"
//                       >
//                         <button
//                           className="w-full bg-gray-200 px-4 py-1 rounded-full text-sm font-medium hover:opacity-90 flex items-center justify-center"
//                           style={{
//                             fontFamily: 'InterSB',
//                             lineHeight: '1.5',
//                           }}
//                         >
//                         <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
//                         <path d="M3.95833 15.0417H5.08646L12.825 7.30312L11.6969 6.175L3.95833 13.9135V15.0417ZM2.375 16.625V13.2604L12.825 2.83021C12.9833 2.68507 13.1582 2.57292 13.3495 2.49375C13.5408 2.41458 13.742 2.375 13.9531 2.375C14.1642 2.375 14.3687 2.41458 14.5667 2.49375C14.7646 2.57292 14.9361 2.69167 15.0813 2.85L16.1698 3.95833C16.3281 4.10347 16.4436 4.275 16.5161 4.47292C16.5887 4.67083 16.625 4.86875 16.625 5.06667C16.625 5.27778 16.5887 5.47899 16.5161 5.67031C16.4436 5.86163 16.3281 6.03646 16.1698 6.19479L5.73958 16.625H2.375ZM12.251 6.74896L11.6969 6.175L12.825 7.30312L12.251 6.74896Z" fill="black"/>
//                         </svg>
//                         <span className="ms-2">Edit</span>
//                         </button>
//                       </Link>
//                       {model.ImageUrls ? (
//                         <>
//                       <Link 
//                         to={`/view/${model.modelId}`}
//                         className="flex-1" >
//                       <button
//                         className="w-full bg-gray-200 px-4 py-1 rounded-full text-sm font-medium hover:opacity-90 flex items-center justify-center"
//                         style={{
//                           fontFamily: 'InterSB',
//                           lineHeight: '1.5',
//                         }}
//                       >
//                         <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
//                         <path d="M9.5013 12.667C10.4909 12.667 11.332 12.3206 12.0247 11.6279C12.7174 10.9352 13.0638 10.0941 13.0638 9.10449C13.0638 8.11491 12.7174 7.27376 12.0247 6.58105C11.332 5.88835 10.4909 5.54199 9.5013 5.54199C8.51172 5.54199 7.67057 5.88835 6.97786 6.58105C6.28516 7.27376 5.9388 8.11491 5.9388 9.10449C5.9388 10.0941 6.28516 10.9352 6.97786 11.6279C7.67057 12.3206 8.51172 12.667 9.5013 12.667ZM9.5013 11.242C8.90755 11.242 8.40286 11.0342 7.98724 10.6186C7.57161 10.2029 7.3638 9.69824 7.3638 9.10449C7.3638 8.51074 7.57161 8.00605 7.98724 7.59043C8.40286 7.1748 8.90755 6.96699 9.5013 6.96699C10.0951 6.96699 10.5997 7.1748 11.0154 7.59043C11.431 8.00605 11.6388 8.51074 11.6388 9.10449C11.6388 9.69824 11.431 10.2029 11.0154 10.6186C10.5997 11.0342 10.0951 11.242 9.5013 11.242ZM9.5013 15.042C7.57491 15.042 5.82005 14.5043 4.23672 13.429C2.65339 12.3536 1.50547 10.9121 0.792969 9.10449C1.50547 7.29685 2.65339 5.85536 4.23672 4.78001C5.82005 3.70467 7.57491 3.16699 9.5013 3.16699C11.4277 3.16699 13.1826 3.70467 14.7659 4.78001C16.3492 5.85536 17.4971 7.29685 18.2096 9.10449C17.4971 10.9121 16.3492 12.3536 14.7659 13.429C13.1826 14.5043 11.4277 15.042 9.5013 15.042Z" fill="black"/>
//                         </svg>
//                         <span className="ms-2">View</span>
//                       </button>
//                       </Link>
//                       </>
//                       ) : null}
//                       <Link 
//                         to={`/subscriptions`}
//                         className="flex-1" >
//                       <button
//                         className="w-full bg-upback px-4 py-1 rounded-full text-sm font-medium hover:opacity-90 flex items-center justify-center"
//                         style={{
//                           fontFamily: 'InterSB',
//                           lineHeight: '1.5',
//                         }}
//                       >
//                         <div className="bg-custom-gold p-0.5 rounded-full">
//                         <svg width="17" height="17" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
//                           <path d="M6.33203 14.2497L9.4987 11.8351L12.6654 14.2497L11.4779 10.3309L14.6445 8.07467H10.7654L9.4987 3.95801L8.23203 8.07467H4.35286L7.51953 10.3309L6.33203 14.2497ZM9.4987 17.4163C8.40356 17.4163 7.37439 17.2085 6.4112 16.7929C5.448 16.3773 4.61016 15.8132 3.89766 15.1007C3.18516 14.3882 2.62109 13.5504 2.20547 12.5872C1.78984 11.624 1.58203 10.5948 1.58203 9.49967C1.58203 8.40454 1.78984 7.37537 2.20547 6.41217C2.62109 5.44898 3.18516 4.61113 3.89766 3.89863C4.61016 3.18613 5.448 2.62207 6.4112 2.20645C7.37439 1.79082 8.40356 1.58301 9.4987 1.58301C10.5938 1.58301 11.623 1.79082 12.5862 2.20645C13.5494 2.62207 14.3872 3.18613 15.0997 3.89863C15.8122 4.61113 16.3763 5.44898 16.7919 6.41217C17.2076 7.37537 17.4154 8.40454 17.4154 9.49967C17.4154 10.5948 17.2076 11.624 16.7919 12.5872C16.3763 13.5504 15.8122 14.3882 15.0997 15.1007C14.3872 15.8132 13.5494 16.3773 12.5862 16.7929C11.623 17.2085 10.5938 17.4163 9.4987 17.4163Z" fill="#6A5300"/>
//                           </svg>
//                           </div>
//                         <span className="ms-2 text-upgrade">Upgrade</span>
//                       </button>
//                       </Link>
//                     </div>
//                   </div>
//                   </div>
  
//                   <div className="flex items-center gap-1 pb-1">
//                     <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
//                       <path d="M2 14V12.6667L3.33333 11.3333V14H2ZM4.66667 14V10L6 8.66667V14H4.66667ZM7.33333 14V8.66667L8.66667 10.0167V14H7.33333ZM10 14V10.0167L11.3333 8.68333V14H10ZM12.6667 14V7.33333L14 6V14H12.6667ZM2 10.55V8.66667L6.66667 4L9.33333 6.66667L14 2V3.88333L9.33333 8.55L6.66667 5.88333L2 10.55Z" fill="#6A6A6A"/>
//                     </svg>
//                     <span className="text-gray-500 text-sm">
//                       Monthly Performance
//                     </span>
//                   </div>
  
//                   <div className="grid grid-cols-3 gap-4 w-full">
//                     <div className="bg-white border p-4 rounded-lg">
//                       <div className="flex items-center">
//                         <div>
//                           <p className="text-2xl font-bold">{Math.floor(model?.profileClicks/2) || 0}</p>
//                           <p className="text-sm text-gray-500">Views on listing</p>
//                         </div>
//                       </div>
//                     </div>
  
//                     <div className="bg-white border p-4 rounded-lg">
//                       <div className="flex items-center">
//                         <div>
//                           <p className="text-2xl font-bold">{model.contactClicks || 0}</p>
//                           <p className="text-sm text-gray-500">Calls and SMS opened</p>
//                         </div>
//                       </div>
//                     </div>
  
//                     <div className="bg-white border p-4 rounded-lg">
//                       <div className="flex items-center">
//                         <div>
//                           <p className="text-2xl font-bold">{model.likes || 0}</p>
//                           <p className="text-sm text-gray-500">Likes</p>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           ))}
  
//           {(!models.length || userData.isAgency) && (
//             <div className="flex justify-center gap-2 mt-4">
//               {/* <Link to="/profile/new"> */}
//                 <button
//                 onClick={handleAddListing}
//                   className="bg-gradient-to-b from-elgrad to-elink text-white px-4 py-2 rounded-full text-sm font-medium hover:opacity-90 flex items-center"
//                   style={{
//                     fontFamily: 'InterSB',
//                     lineHeight: '1.5',
//                   }}
//                 >
//                   <img src={plussign} className="w-4 h-4 me-2" alt="" />
//                   Add Your Listing
//                 </button>
//               {/* </Link> */}
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Dashboard;

import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../styles/fonts.css';
import BASE_URL from '../config';
import plussign from '../components/plussign.svg';
import ProfileActionsMenu from '../components/ProfileActionsMenu.js';
import { CACHE_KEY } from '../constants/cache';
import { useAuth } from '../context/AuthContext';
import DashboardHeader from '../components/DashboardHeader';
import Footer from '../components/Footer';

const SidebarItem = ({ icon, label, onClick, section, show = true }) => {
  if (!show) return null;
  
  return (
    <div 
      className="flex items-center px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer"
      onClick={onClick}
    >
      <div className="mr-3">{icon}</div>
      <span>{label}</span>
    </div>
  );
};

const Skeleton = () => {
  return (
    <div className="flex min-h-screen bg-gray-100">
      {/* Sidebar skeleton - modified to extend full height */}
      <div className="hidden md:block w-64 bg-white border-r h-screen min-h-full sticky top-0">
        <div className="p-4 border-b">
          <div className="h-4 bg-gray-200 rounded w-1/2 mb-2 animate-pulse"></div>
          <div className="h-4 bg-gray-200 rounded w-3/4 animate-pulse"></div>
        </div>
        <nav className="mt-4">
          {[1, 2, 3].map((i) => (
            <div key={i} className="flex items-center px-4 py-2">
              <div className="w-6 h-6 bg-gray-200 rounded mr-3 animate-pulse"></div>
              <div className="h-4 bg-gray-200 rounded w-2/3 animate-pulse"></div>
            </div>
          ))}
        </nav>
      </div>

      <div className="flex-1 flex flex-col items-center overflow-y-auto">
        <div className="w-full max-w-4xl p-4 sm:p-8">
          <div className="h-8 bg-gray-200 rounded w-1/4 mb-6 animate-pulse"></div>

          {/* Card skeleton */}
          <div className="bg-white rounded-lg shadow-lg p-4 mb-4">
            <div className="flex justify-between items-center mb-4">
              <div className="w-16 h-6 bg-gray-200 rounded-full animate-pulse"></div>
              <div className="w-11 h-6 bg-gray-200 rounded-full animate-pulse"></div>
            </div>

            {/* Mobile card layout skeleton */}
            <div className="sm:hidden">
              <div className="flex items-center space-x-4 mb-4">
                <div className="w-20 h-20 bg-gray-200 rounded-lg animate-pulse"></div>
                <div className="flex-1">
                  <div className="h-6 bg-gray-200 rounded w-1/2 mb-2 animate-pulse"></div>
                  <div className="h-4 bg-gray-200 rounded w-1/3 animate-pulse"></div>
                </div>
              </div>
              
              <div className="grid grid-cols-2 gap-2 mb-4">
                <div className="h-10 bg-gray-200 rounded-full animate-pulse"></div>
                <div className="h-10 bg-gray-200 rounded-full animate-pulse"></div>
              </div>
              
              <div className="mt-4">
                <div className="h-10 bg-gray-200 rounded-full animate-pulse mb-4"></div>
                <div className="grid grid-cols-3 gap-2">
                  {[1, 2, 3].map((i) => (
                    <div key={i} className="bg-gray-200 p-3 rounded-lg animate-pulse h-20"></div>
                  ))}
                </div>
              </div>
            </div>

            {/* Desktop layout skeleton */}
            <div className="hidden sm:flex space-x-8">
              <div className="flex flex-col space-y-2 w-full">
                <div className="flex space-x-6">
                  <div className="flex-shrink-0 pb-3">
                    <div className="w-48 h-48 bg-gray-200 rounded-lg animate-pulse"></div>
                  </div>

                  <div className="flex flex-col justify-between w-full">
                    <div>
                      <div className="h-6 bg-gray-200 rounded w-1/3 mb-2 animate-pulse"></div>
                      <div className="h-4 bg-gray-200 rounded w-1/4 animate-pulse"></div>
                    </div>

                    <div className="flex gap-4 w-full">
                      {[1, 2, 3].map((i) => (
                        <div key={i} className="flex-1 h-8 bg-gray-200 rounded-full animate-pulse"></div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="flex items-center gap-1 pb-1">
                  <div className="w-4 h-4 bg-gray-200 rounded animate-pulse"></div>
                  <div className="h-4 bg-gray-200 rounded w-32 animate-pulse"></div>
                </div>

                <div className="grid grid-cols-3 gap-4 w-full">
                  {[1, 2, 3].map((i) => (
                    <div key={i} className="bg-white border p-4 rounded-lg">
                      <div className="h-8 bg-gray-200 rounded w-16 mb-2 animate-pulse"></div>
                      <div className="h-4 bg-gray-200 rounded w-24 animate-pulse"></div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Dashboard = () => {
  const [models, setModels] = useState([]);
  const [userData, setUserData] = useState({ userId: null, isAgency: false });
  const [loading, setLoading] = useState(true);
  const [error] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    if (user && user.userId) {
      setUserData(prev => ({ ...prev, userId: user.userId }));
    }
  }, [user]);

  const fetchData = useCallback(async () => {
    if (!userData.userId) return;
    
    setLoading(true);
    try {
      const [accountResponse, modelsResponse] = await Promise.all([
        fetch(`${BASE_URL}/api/userAccounts/${userData.userId}`, {
          credentials: 'include'
        }),
        fetch(`${BASE_URL}/api/modelswvisits/${userData.userId}`, {
          credentials: 'include'
        })
      ]);

      if (accountResponse.status === 401 || modelsResponse.status === 401) {
        navigate('/login');
        return;
      }

      const [accountData, modelsData] = await Promise.all([
        accountResponse.json(),
        modelsResponse.json()
      ]);

      setUserData(prev => ({ 
        ...prev, 
        isAgency: accountData[0]?.agency === 'true' 
      }));

      const processedModels = Array.isArray(modelsData) ? modelsData : [modelsData];
      setModels(processedModels.map(model => ({
        ...model,
        profileClicks: model.profileClicks || 0,
        contactClicks: model.contactClicks || 0,
        likes: model.likes || 0
      })));

    } catch (err) {
      console.error('Error fetching data:', err);
      setModels([]);
    } finally {
      setLoading(false);
    }
  }, [userData.userId, navigate]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleLiveToggle = async (modelId, currentLive) => {
    try {
      setModels(models.map(model => 
        model.modelId === modelId 
          ? { ...model, live: !currentLive }
          : model
      ));
      
      const response = await fetch(`${BASE_URL}/api/profiles/${modelId}/live`, {
        method: 'PUT',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        }
      });
  
      if (!response.ok) {
        throw new Error('Failed to update live status');
      }
      
      localStorage.removeItem(CACHE_KEY);
      localStorage.setItem('profileUpdated', Date.now());

    } catch (error) {
      console.error('Error updating live status:', error);
      fetchData();
    }
  };

  const handleActiveToggle = async (modelId, currentActive) => {
    try {
      setModels(models.map(model => 
        model.modelId === modelId 
          ? { ...model, active: !currentActive }
          : model
      ));
      
      const response = await fetch(`${BASE_URL}/api/profiles/${modelId}/active`, {
        method: 'PUT',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        }
      });
  
      if (!response.ok) {
        throw new Error('Failed to update active status');
      }
      
      localStorage.removeItem(CACHE_KEY);
      localStorage.setItem('profileUpdated', Date.now());
  
    } catch (error) {
      console.error('Error updating active status:', error);
      fetchData();
    }
  };

  const handleAddListing = () => {
    localStorage.removeItem('currentProfile');
    navigate('/profile/new');
  };
  
  const handleDelete = async (modelId, deleteType) => {
    try {
      const endpoint = deleteType === 'listing' 
        ? `${BASE_URL}/api/profiles/${modelId}/listing`
        : `${BASE_URL}/api/profiles/${userData.userId}/account`;
  
      const response = await fetch(endpoint, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          'Accept': 'application/json'
        }
      });
  
      if (!response.ok) {
        throw new Error('Failed to delete');
      }
  
      if (deleteType === 'account') {
        localStorage.clear();
        navigate('/');
      } else {
        await fetchData();
      }
    } catch (error) {
      console.error('Error deleting:', error);
    }
  };

  if (loading) {
    return <Skeleton />;
  }

  if (error) {
    return (
      <div className="flex min-h-screen bg-gray-100 items-center justify-center">
        <div className="text-red-600">Error: {error}</div>
      </div>
    );
  }

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      {/* Dashboard Header */}
      <DashboardHeader sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      
      <div className="flex flex-1 overflow-hidden">
        {/* Overlay for mobile when sidebar is open */}
        {sidebarOpen && (
          <div 
            className="fixed inset-0 bg-black bg-opacity-50 z-40 md:hidden" 
            onClick={() => setSidebarOpen(false)}
            style={{ pointerEvents: sidebarOpen ? 'auto' : 'none' }}
          />
        )}
        
        {/* Sidebar - will slide in on mobile */}
        <div 
          className={`
            fixed md:relative md:block w-64 bg-white  border-r z-40 transition-transform duration-300 ease-in-out
            ${sidebarOpen ? 'translate-x-0' : '-translate-x-full md:translate-x-0'} 
          `}
          style={{ minHeight: '100%' }}
        >
          <div className="p-4 border-b">
            <span style={{ fontFamily: 'InterSB' }}>
              {models.length === 1 ? 'Hi, ' + models[0].firstName : 'Hello, '}
            </span>
            <br />
            <span>
              Welcome to Working Girl! New Zealand's online adult worker directory.
            </span>
          </div>
          <nav className="mt-4">
            <SidebarItem
              icon={<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M22 6V18C22 18.55 21.8042 19.0208 21.4125 19.4125C21.0208 19.8042 20.55 20 20 20H4C3.45 20 2.97917 19.8042 2.5875 19.4125C2.19583 19.0208 2 18.55 2 18V6C2 5.45 2.19583 4.97917 2.5875 4.5875C2.97917 4.19583 3.45 4 4 4H20C20.55 4 21.0208 4.19583 21.4125 4.5875C21.8042 4.97917 22 5.45 22 6ZM4 8H20V6H4V8ZM4 12V18H20V12H4Z" fill="black"/>
                </svg>}
              label="Subscriptions"
              section="subscriptions"
              onClick={() => navigate('/subscriptions')}
            />
            {/* <SidebarItem
              icon={<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.24922 22L8.84922 18.8C8.63255 18.7167 8.42839 18.6167 8.23672 18.5C8.04505 18.3833 7.85755 18.2583 7.67422 18.125L4.69922 19.375L1.94922 14.625L4.52422 12.675C4.50755 12.5583 4.49922 12.4458 4.49922 12.3375V11.6625C4.49922 11.5542 4.50755 11.4417 4.52422 11.325L1.94922 9.375L4.69922 4.625L7.67422 5.875C7.85755 5.74167 8.04922 5.61667 8.24922 5.5C8.44922 5.38333 8.64922 5.28333 8.84922 5.2L9.24922 2H14.7492L15.1492 5.2C15.3659 5.28333 15.5701 5.38333 15.7617 5.5C15.9534 5.61667 16.1409 5.74167 16.3242 5.875L19.2992 4.625L22.0492 9.375L19.4742 11.325C19.4909 11.4417 19.4992 11.5542 19.4992 11.6625V12.3375C19.4992 12.4458 19.4826 12.5583 19.4492 12.675L22.0242 14.625L19.2742 19.375L16.3242 18.125C16.1409 18.2583 15.9492 18.3833 15.7492 18.5C15.5492 18.6167 15.3492 18.7167 15.1492 18.8L14.7492 22H9.24922ZM12.0492 15.5C13.0159 15.5 13.8409 15.1583 14.5242 14.475C15.2076 13.7917 15.5492 12.9667 15.5492 12C15.5492 11.0333 15.2076 10.2083 14.5242 9.525C13.8409 8.84167 13.0159 8.5 12.0492 8.5C11.0659 8.5 10.2367 8.84167 9.56172 9.525C8.88672 10.2083 8.54922 11.0333 8.54922 12C8.54922 12.9667 8.88672 13.7917 9.56172 14.475C10.2367 15.1583 11.0659 15.5 12.0492 15.5Z" fill="black"/>
                </svg>}
              label="Settings"
              section="settings"
            /> */}
            <SidebarItem
              icon={<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.24922 22L8.84922 18.8C8.63255 18.7167 8.42839 18.6167 8.23672 18.5C8.04505 18.3833 7.85755 18.2583 7.67422 18.125L4.69922 19.375L1.94922 14.625L4.52422 12.675C4.50755 12.5583 4.49922 12.4458 4.49922 12.3375V11.6625C4.49922 11.5542 4.50755 11.4417 4.52422 11.325L1.94922 9.375L4.69922 4.625L7.67422 5.875C7.85755 5.74167 8.04922 5.61667 8.24922 5.5C8.44922 5.38333 8.64922 5.28333 8.84922 5.2L9.24922 2H14.7492L15.1492 5.2C15.3659 5.28333 15.5701 5.38333 15.7617 5.5C15.9534 5.61667 16.1409 5.74167 16.3242 5.875L19.2992 4.625L22.0492 9.375L19.4742 11.325C19.4909 11.4417 19.4992 11.5542 19.4992 11.6625V12.3375C19.4992 12.4458 19.4826 12.5583 19.4492 12.675L22.0242 14.625L19.2742 19.375L16.3242 18.125C16.1409 18.2583 15.9492 18.3833 15.7492 18.5C15.5492 18.6167 15.3492 18.7167 15.1492 18.8L14.7492 22H9.24922ZM12.0492 15.5C13.0159 15.5 13.8409 15.1583 14.5242 14.475C15.2076 13.7917 15.5492 12.9667 15.5492 12C15.5492 11.0333 15.2076 10.2083 14.5242 9.525C13.8409 8.84167 13.0159 8.5 12.0492 8.5C11.0659 8.5 10.2367 8.84167 9.56172 9.525C8.88672 10.2083 8.54922 11.0333 8.54922 12C8.54922 12.9667 8.88672 13.7917 9.56172 14.475C10.2367 15.1583 11.0659 15.5 12.0492 15.5Z" fill="black"/>
                </svg>}
              label="Manage Subscription"
              section="ManageSubscription"
              onClick={() => navigate('/managesub')}
            />

            <SidebarItem
              icon={<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.95 18C12.3 18 12.5958 17.8792 12.8375 17.6375C13.0792 17.3958 13.2 17.1 13.2 16.75C13.2 16.4 13.0792 16.1042 12.8375 15.8625C12.5958 15.6208 12.3 15.5 11.95 15.5C11.6 15.5 11.3042 15.6208 11.0625 15.8625C10.8208 16.1042 10.7 16.4 10.7 16.75C10.7 17.1 10.8208 17.3958 11.0625 17.6375C11.3042 17.8792 11.6 18 11.95 18ZM11.05 14.15H12.9C12.9 13.6 12.9625 13.1667 13.0875 12.85C13.2125 12.5333 13.5667 12.1 14.15 11.55C14.5833 11.1167 14.925 10.7042 15.175 10.3125C15.425 9.92083 15.55 9.45 15.55 8.9C15.55 7.96667 15.2083 7.25 14.525 6.75C13.8417 6.25 13.0333 6 12.1 6C11.15 6 10.3792 6.25 9.7875 6.75C9.19583 7.25 8.78333 7.85 8.55 8.55L10.2 9.2C10.2833 8.9 10.4708 8.575 10.7625 8.225C11.0542 7.875 11.5 7.7 12.1 7.7C12.6333 7.7 13.0333 7.84583 13.3 8.1375C13.5667 8.42917 13.7 8.75 13.7 9.1C13.7 9.43333 13.6 9.74583 13.4 10.0375C13.2 10.3292 12.95 10.6 12.65 10.85C11.9167 11.5 11.4667 11.9917 11.3 12.325C11.1333 12.6583 11.05 13.2667 11.05 14.15ZM12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22Z" fill="black"/>
                </svg>}
              label="Help"
              section="help"
              onClick={() => navigate('/faq')}
            />

            <SidebarItem
              icon={<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#000000"><path d="m344-60-76-128-144-32 14-148-98-112 98-112-14-148 144-32 76-128 136 58 136-58 76 128 144 32-14 148 98 112-98 112 14 148-144 32-76 128-136-58-136 58Zm94-278 226-226-56-58-170 170-86-84-56 56 142 142Z"/></svg>}
              label="Get Verified"
              section="verification"
              show={!models.every(model => model.verified)}
              onClick={() => navigate('/verification')}
            />
          </nav>
        </div>
    
        <div className="flex-1 overflow-y-auto">
          <div className="w-full max-w-4xl p-4 sm:p-8 mx-auto">
            {models.length > 0 && (
              <h2 className="text-xl sm:text-2xl font-semibold mb-4 sm:mb-6">
                {userData.isAgency ? "Your Listings" : "Your Listing"}
              </h2>
            )}
    
            {/* Responsively laid out model cards */}
            {models.map((model) => (
              <div key={model.modelId} className="bg-white rounded-lg shadow-lg p-4 mb-4">
                <div className="flex justify-between items-center mb-4">
                  <div className="flex items-center space-x-2">
                    <span className={`px-2 py-1 bg-gray-200 rounded-full inline-flex items-center text-sm ${
                      model.live ? 'text-gray-600' : 'text-gray-500'
                    }`} style={{ fontFamily: 'InterSB' }}>
                      {model.live ? 'LIVE' : model.verified ? 'Hidden' : 'Hidden - Get Verified'}
                    </span>
                  </div>
                  <ProfileActionsMenu
                    model={model}
                    onToggleLive={handleLiveToggle}
                    onToggleActive={handleActiveToggle}
                    onDelete={handleDelete}
                    isAgency={userData.isAgency}
                    isVerified={model.verified}
                  />
                </div>
    
                {/* Mobile layout */}
                <div className="sm:hidden">
                  <div className="flex items-center space-x-4 mb-4">
                    {model.ImageUrls ? (
                      <img
                        src={process.env.NODE_ENV === 'production' 
                          ? model.ImageUrls.split(',')[0]
                          : `http://localhost:3001${model.ImageUrls.split(',')[0]}`
                        }
                        alt="Profile"
                        className="w-20 h-20 object-cover rounded-lg"
                        onError={(e) => {
                          e.target.src = '/api/placeholder/80/80';
                        }}
                      />
                    ) : (
                      <div className="w-20 h-20 bg-gray-200 rounded-lg flex items-center justify-center">
                        <span className="text-gray-500 text-xs">No Image</span>
                      </div>
                    )}
                    <div>
                      <h3 className="text-lg font-semibold">{model.firstName} {model.lastName}</h3>
                      <p className="text-xs text-gray-500">
                        {model.active ? 'Active' : 'Inactive'} · Standard
                      </p>
                    </div>
                  </div>
                  
                  {/* Action buttons */}
                  <div className="grid grid-cols-2 gap-2 mb-4">
                    <Link 
                      to={`/profile/edit/${model.modelId}`}
                      className="w-full"
                    >
                      <button
                        className="w-full bg-gray-200 px-3 py-2 rounded-full text-sm font-medium hover:opacity-90 flex items-center justify-center"
                        style={{
                          fontFamily: 'InterSB',
                        }}
                      >
                        <svg width="16" height="16" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-1">
                          <path d="M3.95833 15.0417H5.08646L12.825 7.30312L11.6969 6.175L3.95833 13.9135V15.0417ZM2.375 16.625V13.2604L12.825 2.83021C12.9833 2.68507 13.1582 2.57292 13.3495 2.49375C13.5408 2.41458 13.742 2.375 13.9531 2.375C14.1642 2.375 14.3687 2.41458 14.5667 2.49375C14.7646 2.57292 14.9361 2.69167 15.0813 2.85L16.1698 3.95833C16.3281 4.10347 16.4436 4.275 16.5161 4.47292C16.5887 4.67083 16.625 4.86875 16.625 5.06667C16.625 5.27778 16.5887 5.47899 16.5161 5.67031C16.4436 5.86163 16.3281 6.03646 16.1698 6.19479L5.73958 16.625H2.375ZM12.251 6.74896L11.6969 6.175L12.825 7.30312L12.251 6.74896Z" fill="black"/>
                        </svg>
                        Edit
                      </button>
                    </Link>
                    
                    {model.ImageUrls && (
                      <Link 
                        to={`/view/${model.modelId}`}
                        className="w-full"
                      >
                        <button
                          className="w-full bg-gray-200 px-3 py-2 rounded-full text-sm font-medium hover:opacity-90 flex items-center justify-center"
                          style={{
                            fontFamily: 'InterSB',
                          }}
                        >
                          <svg width="16" height="16" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-1">
                            <path d="M9.5013 12.667C10.4909 12.667 11.332 12.3206 12.0247 11.6279C12.7174 10.9352 13.0638 10.0941 13.0638 9.10449C13.0638 8.11491 12.7174 7.27376 12.0247 6.58105C11.332 5.88835 10.4909 5.54199 9.5013 5.54199C8.51172 5.54199 7.67057 5.88835 6.97786 6.58105C6.28516 7.27376 5.9388 8.11491 5.9388 9.10449C5.9388 10.0941 6.28516 10.9352 6.97786 11.6279C7.67057 12.3206 8.51172 12.667 9.5013 12.667ZM9.5013 11.242C8.90755 11.242 8.40286 11.0342 7.98724 10.6186C7.57161 10.2029 7.3638 9.69824 7.3638 9.10449C7.3638 8.51074 7.57161 8.00605 7.98724 7.59043C8.40286 7.1748 8.90755 6.96699 9.5013 6.96699C10.0951 6.96699 10.5997 7.1748 11.0154 7.59043C11.431 8.00605 11.6388 8.51074 11.6388 9.10449C11.6388 9.69824 11.431 10.2029 11.0154 10.6186C10.5997 11.0342 10.0951 11.242 9.5013 11.242ZM9.5013 15.042C7.57491 15.042 5.82005 14.5043 4.23672 13.429C2.65339 12.3536 1.50547 10.9121 0.792969 9.10449C1.50547 7.29685 2.65339 5.85536 4.23672 4.78001C5.82005 3.70467 7.57491 3.16699 9.5013 3.16699C11.4277 3.16699 13.1826 3.70467 14.7659 4.78001C16.3492 5.85536 17.4971 7.29685 18.2096 9.10449C17.4971 10.9121 16.3492 12.3536 14.7659 13.429C13.1826 14.5043 11.4277 15.042 9.5013 15.042Z" fill="black"/>
                          </svg>
                          View
                        </button>
                      </Link>
                    )}
                  </div>
                  
                  {/* Upgrade button */}
                  <Link 
                    to={`/subscriptions`}
                    className="w-full mb-4"
                  >
                    <button
                      className="w-full bg-upback px-3 py-2 rounded-full text-sm font-medium hover:opacity-90 flex items-center justify-center"
                      style={{
                        fontFamily: 'InterSB',
                      }}
                    >
                      <div className="bg-custom-gold p-0.5 rounded-full mr-1">
                        <svg width="14" height="14" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M6.33203 14.2497L9.4987 11.8351L12.6654 14.2497L11.4779 10.3309L14.6445 8.07467H10.7654L9.4987 3.95801L8.23203 8.07467H4.35286L7.51953 10.3309L6.33203 14.2497ZM9.4987 17.4163C8.40356 17.4163 7.37439 17.2085 6.4112 16.7929C5.448 16.3773 4.61016 15.8132 3.89766 15.1007C3.18516 14.3882 2.62109 13.5504 2.20547 12.5872C1.78984 11.624 1.58203 10.5948 1.58203 9.49967C1.58203 8.40454 1.78984 7.37537 2.20547 6.41217C2.62109 5.44898 3.18516 4.61113 3.89766 3.89863C4.61016 3.18613 5.448 2.62207 6.4112 2.20645C7.37439 1.79082 8.40356 1.58301 9.4987 1.58301C10.5938 1.58301 11.623 1.79082 12.5862 2.20645C13.5494 2.62207 14.3872 3.18613 15.0997 3.89863C15.8122 4.61113 16.3763 5.44898 16.7919 6.41217C17.2076 7.37537 17.4154 8.40454 17.4154 9.49967C17.4154 10.5948 17.2076 11.624 16.7919 12.5872C16.3763 13.5504 15.8122 14.3882 15.0997 15.1007C14.3872 15.8132 13.5494 16.3773 12.5862 16.7929C11.623 17.2085 10.5938 17.4163 9.4987 17.4163Z" fill="#6A5300"/>
                        </svg>
                      </div>
                      <span className="text-upgrade">Upgrade</span>
                    </button>
                  </Link>
                  
                  {/* Stats section */}
                  <div className="border-t pt-3">
                    <div className="flex items-center gap-1 mb-3">
                      <svg width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 14V12.6667L3.33333 11.3333V14H2ZM4.66667 14V10L6 8.66667V14H4.66667ZM7.33333 14V8.66667L8.66667 10.0167V14H7.33333ZM10 14V10.0167L11.3333 8.68333V14H10ZM12.6667 14V7.33333L14 6V14H12.6667ZM2 10.55V8.66667L6.66667 4L9.33333 6.66667L14 2V3.88333L9.33333 8.55L6.66667 5.88333L2 10.55Z" fill="#6A6A6A"/>
                      </svg>
                      <span className="text-gray-500 text-xs">
                        Monthly Performance
                      </span>
                    </div>
                    
                    <div className="grid grid-cols-3 gap-2">
                      <div className="bg-white border p-2 rounded-lg">
                        <p className="text-lg font-bold">{Math.floor(model?.profileClicks/2) || 0}</p>
                        <p className="text-xs text-gray-500">Views</p>
                      </div>
                      <div className="bg-white border p-2 rounded-lg">
                        <p className="text-lg font-bold">{model.contactClicks || 0}</p>
                        <p className="text-xs text-gray-500">Contacts</p>
                      </div>
                      <div className="bg-white border p-2 rounded-lg">
                        <p className="text-lg font-bold">{model.likes || 0}</p>
                        <p className="text-xs text-gray-500">Likes</p>
                      </div>
                    </div>
                  </div>
                </div>
    
                {/* Desktop layout */}
                <div className="hidden sm:flex space-x-8">
                  <div className="flex flex-col space-y-2 w-full">
                    <div className="flex space-x-6">
                      <div className="flex-shrink-0 pb-3 relative">
                        {model.ImageUrls ? (
                          <img
                            src={process.env.NODE_ENV === 'production' 
                              ? model.ImageUrls.split(',')[0]
                              : `http://localhost:3001${model.ImageUrls.split(',')[0]}`
                            }
                            alt="Profile"
                            className="w-48 h-48 object-cover rounded-lg"
                            onError={(e) => {
                              e.target.src = '/api/placeholder/192/192';
                            }}
                          />
                        ) : (
                          <div className="w-48 h-48 bg-gray-200 rounded-lg flex items-center justify-center">
                            <span className="text-gray-500">No Image</span>
                          </div>
                        )}
                      </div>
                      <div className="flex flex-col justify-between w-full">
                        <div>
                          <h3 className="text-xl font-semibold">{model.firstName} {model.lastName}</h3>
                          <p className="text-sm text-gray-500">
                            {model.active ? 'Active' : 'Inactive'} · Standard Listing
                          </p>
                        </div>
                        <div className="flex gap-4 w-full">
                          <Link 
                            to={`/profile/edit/${model.modelId}`}
                            className="flex-1"
                          >
                            <button
                              className="w-full bg-gray-200 px-4 py-1 rounded-full text-sm font-medium hover:opacity-90 flex items-center justify-center"
                              style={{
                                fontFamily: 'InterSB',
                                lineHeight: '1.5',
                              }}
                            >
                              <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.95833 15.0417H5.08646L12.825 7.30312L11.6969 6.175L3.95833 13.9135V15.0417ZM2.375 16.625V13.2604L12.825 2.83021C12.9833 2.68507 13.1582 2.57292 13.3495 2.49375C13.5408 2.41458 13.742 2.375 13.9531 2.375C14.1642 2.375 14.3687 2.41458 14.5667 2.49375C14.7646 2.57292 14.9361 2.69167 15.0813 2.85L16.1698 3.95833C16.3281 4.10347 16.4436 4.275 16.5161 4.47292C16.5887 4.67083 16.625 4.86875 16.625 5.06667C16.625 5.27778 16.5887 5.47899 16.5161 5.67031C16.4436 5.86163 16.3281 6.03646 16.1698 6.19479L5.73958 16.625H2.375ZM12.251 6.74896L11.6969 6.175L12.825 7.30312L12.251 6.74896Z" fill="black"/>
                              </svg>
                              <span className="ms-2">Edit</span>
                            </button>
                          </Link>
                          {model.ImageUrls ? (
                            <Link 
                              to={`/view/${model.modelId}`}
                              className="flex-1"
                            >
                              <button
                                className="w-full bg-gray-200 px-4 py-1 rounded-full text-sm font-medium hover:opacity-90 flex items-center justify-center"
                                style={{
                                  fontFamily: 'InterSB',
                                  lineHeight: '1.5',
                                }}
                              >
                                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M9.5013 12.667C10.4909 12.667 11.332 12.3206 12.0247 11.6279C12.7174 10.9352 13.0638 10.0941 13.0638 9.10449C13.0638 8.11491 12.7174 7.27376 12.0247 6.58105C11.332 5.88835 10.4909 5.54199 9.5013 5.54199C8.51172 5.54199 7.67057 5.88835 6.97786 6.58105C6.28516 7.27376 5.9388 8.11491 5.9388 9.10449C5.9388 10.0941 6.28516 10.9352 6.97786 11.6279C7.67057 12.3206 8.51172 12.667 9.5013 12.667ZM9.5013 11.242C8.90755 11.242 8.40286 11.0342 7.98724 10.6186C7.57161 10.2029 7.3638 9.69824 7.3638 9.10449C7.3638 8.51074 7.57161 8.00605 7.98724 7.59043C8.40286 7.1748 8.90755 6.96699 9.5013 6.96699C10.0951 6.96699 10.5997 7.1748 11.0154 7.59043C11.431 8.00605 11.6388 8.51074 11.6388 9.10449C11.6388 9.69824 11.431 10.2029 11.0154 10.6186C10.5997 11.0342 10.0951 11.242 9.5013 11.242ZM9.5013 15.042C7.57491 15.042 5.82005 14.5043 4.23672 13.429C2.65339 12.3536 1.50547 10.9121 0.792969 9.10449C1.50547 7.29685 2.65339 5.85536 4.23672 4.78001C5.82005 3.70467 7.57491 3.16699 9.5013 3.16699C11.4277 3.16699 13.1826 3.70467 14.7659 4.78001C16.3492 5.85536 17.4971 7.29685 18.2096 9.10449C17.4971 10.9121 16.3492 12.3536 14.7659 13.429C13.1826 14.5043 11.4277 15.042 9.5013 15.042Z" fill="black"/>
                                </svg>
                                <span className="ms-2">View</span>
                              </button>
                            </Link>
                          ) : null}
                          <Link 
                            to={`/subscriptions`}
                            className="flex-1"
                          >
                            <button
                              className="w-full bg-upback px-4 py-1 rounded-full text-sm font-medium hover:opacity-90 flex items-center justify-center"
                              style={{
                                fontFamily: 'InterSB',
                                lineHeight: '1.5',
                              }}
                            >
                              <div className="bg-custom-gold p-0.5 rounded-full">
                                <svg width="17" height="17" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M6.33203 14.2497L9.4987 11.8351L12.6654 14.2497L11.4779 10.3309L14.6445 8.07467H10.7654L9.4987 3.95801L8.23203 8.07467H4.35286L7.51953 10.3309L6.33203 14.2497ZM9.4987 17.4163C8.40356 17.4163 7.37439 17.2085 6.4112 16.7929C5.448 16.3773 4.61016 15.8132 3.89766 15.1007C3.18516 14.3882 2.62109 13.5504 2.20547 12.5872C1.78984 11.624 1.58203 10.5948 1.58203 9.49967C1.58203 8.40454 1.78984 7.37537 2.20547 6.41217C2.62109 5.44898 3.18516 4.61113 3.89766 3.89863C4.61016 3.18613 5.448 2.62207 6.4112 2.20645C7.37439 1.79082 8.40356 1.58301 9.4987 1.58301C10.5938 1.58301 11.623 1.79082 12.5862 2.20645C13.5494 2.62207 14.3872 3.18613 15.0997 3.89863C15.8122 4.61113 16.3763 5.44898 16.7919 6.41217C17.2076 7.37537 17.4154 8.40454 17.4154 9.49967C17.4154 10.5948 17.2076 11.624 16.7919 12.5872C16.3763 13.5504 15.8122 14.3882 15.0997 15.1007C14.3872 15.8132 13.5494 16.3773 12.5862 16.7929C11.623 17.2085 10.5938 17.4163 9.4987 17.4163Z" fill="#6A5300"/>
                                </svg>
                              </div>
                              <span className="ms-2 text-upgrade">Upgrade</span>
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
    
                    <div className="flex items-center gap-1 pb-1">
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 14V12.6667L3.33333 11.3333V14H2ZM4.66667 14V10L6 8.66667V14H4.66667ZM7.33333 14V8.66667L8.66667 10.0167V14H7.33333ZM10 14V10.0167L11.3333 8.68333V14H10ZM12.6667 14V7.33333L14 6V14H12.6667ZM2 10.55V8.66667L6.66667 4L9.33333 6.66667L14 2V3.88333L9.33333 8.55L6.66667 5.88333L2 10.55Z" fill="#6A6A6A"/>
                      </svg>
                      <span className="text-gray-500 text-sm">
                        Monthly Performance
                      </span>
                    </div>
    
                    <div className="grid grid-cols-3 gap-4 w-full">
                      <div className="bg-white border p-4 rounded-lg">
                        <div className="flex items-center">
                          <div>
                            <p className="text-2xl font-bold">{Math.floor(model?.profileClicks/2) || 0}</p>
                            <p className="text-sm text-gray-500">Views on listing</p>
                          </div>
                        </div>
                      </div>
    
                      <div className="bg-white border p-4 rounded-lg">
                        <div className="flex items-center">
                          <div>
                            <p className="text-2xl font-bold">{model.contactClicks || 0}</p>
                            <p className="text-sm text-gray-500">Calls and SMS opened</p>
                          </div>
                        </div>
                      </div>
    
                      <div className="bg-white border p-4 rounded-lg">
                        <div className="flex items-center">
                          <div>
                            <p className="text-2xl font-bold">{model.likes || 0}</p>
                            <p className="text-sm text-gray-500">Likes</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
    
            {/* Add listing button */}
            {(!models.length || userData.isAgency) && (
              <div className="flex justify-center mt-4">
                <button
                  onClick={handleAddListing}
                  className="bg-gradient-to-b from-elgrad to-elink text-white px-4 py-3 rounded-full text-sm sm:text-base font-medium hover:opacity-90 flex items-center"
                  style={{
                    fontFamily: 'InterSB',
                  }}
                >
                  <img src={plussign} className="w-4 h-4 mr-2" alt="" />
                  Add Your Listing
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Dashboard;