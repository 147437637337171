import React from 'react';
import { Shield, Globe, Users, Heart } from 'lucide-react';
import Footer from '../components/Footer';

const AboutPage = () => {
  return (
    <div className="min-h-screen bg-gray-100">
      {/* Header Section */}
      <div className="bg-white border-b">
        <div className="container mx-auto py-6 px-6">
          <h1 className="text-3xl font-light text-gray-900 pb-4" style={{
            fontFamily: 'InterSB'
          }}>About Us</h1>
          <p className="text-gray-600">
            New Zealand's Premier Adult Services Directory
          </p>
        </div>
      </div>

      <div className="container mx-auto py-8 px-6">
        {/* Mission Statement */}
        <div className=" rounded-lg shadow-lg p-8 mb-8 ">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-2xl font-semibold mb-4" style={{ fontFamily: 'InterSB' }}>
              Our Mission
            </h2>
            <p className="text-lg">
              To provide a safe, professional, and user-friendly platform connecting adult service providers 
              with clients throughout New Zealand, while maintaining the highest standards of privacy and security.
            </p>
          </div>
        </div>

        {/* Main Content */}
        <div className="bg-white rounded-lg shadow-lg p-8 mb-8">
          <div className="max-w-3xl mx-auto">
            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4" style={{ fontFamily: 'InterSB' }}>
                Who We Are
              </h2>
              <p className="text-gray-700 mb-4">
                Working Girl is New Zealand's leading online directory for adult services and entertainment. 
                Established with a commitment to professionalism and discretion, we provide a comprehensive 
                platform that connects verified service providers with clients across the country.
              </p>
              <p className="text-gray-700">
                Our team consists of dedicated professionals who work tirelessly to maintain a secure, 
                reliable, and user-friendly environment for all our users. We understand the unique needs 
                of our community and strive to provide the best possible service.
              </p>
            </section>

            {/* Core Values */}
            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-6" style={{ fontFamily: 'InterSB' }}>
                Our Core Values
              </h2>
              <div className="grid md:grid-cols-2 gap-6">
                <div className="flex items-start space-x-4">
                  <div className="bg-gray-50 p-3 rounded-lg">
                    <Shield className="h-6 w-6 text-elink" />
                  </div>
                </div>

                <div className="flex items-start space-x-4">
                  <div className="bg-gray-50 p-3 rounded-lg">
                    <Users className="h-6 w-6 text-elink" />
                  </div>
                  <div>
                    <h3 className="text-lg font-medium mb-2" style={{ fontFamily: 'InterSB' }}>
                      Community Focus
                    </h3>
                    <p className="text-gray-600">
                      We support and empower our community through professional resources and support.
                    </p>
                  </div>
                </div>

                <div className="flex items-start space-x-4">
                  <div className="bg-gray-50 p-3 rounded-lg">
                    <Globe className="h-6 w-6 text-elink" />
                  </div>
                  <div>
                    <h3 className="text-lg font-medium mb-2" style={{ fontFamily: 'InterSB' }}>
                      Nationwide Access
                    </h3>
                    <p className="text-gray-600">
                      We connect providers and clients across all major cities in New Zealand.
                    </p>
                  </div>
                </div>

                <div className="flex items-start space-x-4">
                  <div className="bg-gray-50 p-3 rounded-lg">
                    <Heart className="h-6 w-6 text-elink" />
                  </div>
                  <div>
                    <h3 className="text-lg font-medium mb-2" style={{ fontFamily: 'InterSB' }}>
                      Respect & Discretion
                    </h3>
                    <p className="text-gray-600">
                      We maintain the highest standards of privacy and professional conduct.
                    </p>
                  </div>
                </div>
              </div>
            </section>

            {/* Coverage Section */}
            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4" style={{ fontFamily: 'InterSB' }}>
                Our Coverage
              </h2>
              <p className="text-gray-700 mb-4">
                We provide comprehensive coverage across New Zealand, including:
              </p>
              <div className="grid md:grid-cols-3 gap-4">
                {['Auckland', 'Wellington', 'Christchurch', 'Hamilton', 'Tauranga', 'South Island'].map((city) => (
                  <div key={city} className="bg-gray-50 p-4 rounded-lg text-center">
                    <span className="text-gray-700 font-medium" style={{ fontFamily: 'InterSB' }}>
                      {city}
                    </span>
                  </div>
                ))}
              </div>
            </section>
          </div>
        </div>

        {/* Join Us CTA */}
        <div className="bg-gray-50 rounded-lg p-8 text-center">
          <h2 className="text-2xl font-semibold mb-4" style={{ fontFamily: 'InterSB' }}>
            Join Our Community
          </h2>
          <p className="text-gray-600 mb-6">
            Whether you're a service provider or a client, become part of New Zealand's most trusted adult services platform.
          </p>
          <button 
            className="bg-gradient-to-b from-elgrad to-elink text-white px-6 py-2 rounded-full text-sm font-medium hover:opacity-90"
            style={{ fontFamily: 'InterSB' }}
            onClick={() => window.location.href = '/login'}
          >
            Get Started
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutPage;