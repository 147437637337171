import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import BASE_URL from '../config';

const VerificationPage = () => {
  const navigate = useNavigate();
  const [models, setModels] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedModel, setSelectedModel] = useState(null);
  const [frontLicense, setFrontLicense] = useState(null);
  const [backLicense, setBackLicense] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchModels = async () => {
      try {
        const token = localStorage.getItem('token');
        const userId = JSON.parse(localStorage.getItem('user')).userId;
        
        const response = await fetch(`${BASE_URL}/api/modelswvisits/${userId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) throw new Error('Failed to fetch models');
        
        const data = await response.json();
        // Filter out already verified models
        setModels(data.filter(model => !model.verified));
      } catch (error) {
        console.error('Error fetching models:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchModels();
  }, []);

  const handleModelSelect = (model) => {
    setSelectedModel(model);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedModel || !frontLicense || !backLicense) {
      alert('Please select a model and upload both license images');
      return;
    }

    setIsSubmitting(true);
    const formData = new FormData();
    formData.append('frontLicense', frontLicense);
    formData.append('backLicense', backLicense);

    try {
      const response = await fetch(`${BASE_URL}/api/verify/request/${selectedModel.modelId}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: formData
      });

      if (!response.ok) throw new Error('Verification request failed');

      alert('Verification request sent successfully');
      navigate('/dashboard');

    } catch (error) {
      console.error('Error:', error);
      alert('Failed to submit verification request');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isLoading) {
    return <div className="p-8 text-center">Loading...</div>;
  }

  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        <div className="text-center mb-8">
          <h2 className="text-3xl font-bold">Get Verified</h2>
          <p className="mt-2 text-gray-600">
            Select a profile and upload your license to get verified
          </p>
        </div>

        {/* Model Selection */}
        <div className="grid grid-cols-1 gap-6 mb-8">
          {models.map((model) => (
            <div 
              key={model.modelId}
              className={`bg-white rounded-lg shadow p-4 cursor-pointer transition-all ${
                selectedModel?.modelId === model.modelId 
                  ? 'ring-2 ring-elink' 
                  : 'hover:shadow-lg'
              }`}
              onClick={() => handleModelSelect(model)}
            >
              <div className="flex space-x-6">
                <div className="flex-shrink-0">
                  <img
                    src={process.env.NODE_ENV === 'production' 
                      ? model.ImageUrls?.split(',')[0]
                      : `http://localhost:3001${model.ImageUrls?.split(',')[0]}`
                    }
                    alt={`${model.firstName} ${model.lastName}`}
                    className="w-32 h-32 object-cover rounded-lg"
                    onError={(e) => {
                      e.target.src = "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTI4IiBoZWlnaHQ9IjEyOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cmVjdCB3aWR0aD0iMTI4IiBoZWlnaHQ9IjEyOCIgZmlsbD0iI2YzZjRmNiIvPjx0ZXh0IHg9IjUwJSIgeT0iNTAlIiBkb21pbmFudC1iYXNlbGluZT0ibWlkZGxlIiB0ZXh0LWFuY2hvcj0ibWlkZGxlIiBmaWxsPSIjOWNhM2FmIj5ObyBJbWFnZTwvdGV4dD48L3N2Zz4=";
                    }}
                  />
                </div>
                
                <div className="flex-1">
                  <h3 className="text-xl font-semibold">
                    {model.firstName} {model.lastName}
                  </h3>
                  <p className="text-sm text-gray-500">{model.location}</p>
                  
                  {selectedModel?.modelId === model.modelId && (
                    <div className="mt-4 space-y-4">
                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          License Front
                        </label>
                        <input
                          type="file"
                          accept="image/*"
                          onChange={(e) => setFrontLicense(e.target.files[0])}
                          className="mt-1 w-full py-2 px-3 border border-gray-300 rounded-md"
                          required
                        />
                      </div>

                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          License Back
                        </label>
                        <input
                          type="file"
                          accept="image/*"
                          onChange={(e) => setBackLicense(e.target.files[0])}
                          className="mt-1 w-full py-2 px-3 border border-gray-300 rounded-md"
                          required
                        />
                      </div>

                      <button
                        onClick={handleSubmit}
                        disabled={isSubmitting || !frontLicense || !backLicense}
                        className="w-full bg-gradient-to-b from-elgrad to-elink text-white py-2 px-4 rounded-full hover:opacity-90 disabled:opacity-50"
                      >
                        {isSubmitting ? 'Submitting...' : 'Submit for Verification'}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>

        {models.length === 0 && (
          <div className="text-center text-gray-500">
            No profiles available for verification
          </div>
        )}

        <div className="text-sm text-gray-500 mt-8">
          <p>Your license photos will:</p>
          <ul className="list-disc pl-5 mt-2">
            <li>Be securely sent to our admin team</li>
            <li>Only be used for verification purposes</li>
            <li>Never be stored or shared</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default VerificationPage;