import React from 'react';
import Footer from '../components/Footer';

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen bg-gray-100">
      <div className="bg-white border-b">
        <div className="container mx-auto py-6 px-6">
          <h1 className="text-3xl font-light text-gray-900 pb-4" style={{
            fontFamily: 'InterSB'
          }}>Privacy Policy</h1>
          <p className="text-gray-600">
            Last updated: February 12, 2025
          </p>
        </div>
      </div>

      <div className="container mx-auto py-8 px-6">
        <div className="bg-white rounded-lg shadow-lg p-8 mb-8">
        <h2 className="text-2xl font-semibold mb-4" style={{ fontFamily: 'InterSB' }}>
                6. Privacy and Data Protection
              </h2>
              <div className="space-y-4 text-gray-700">
                <p>We are committed to protecting user privacy in accordance with the Privacy Act 2020. By using our service, you agree to our privacy practices as outlined below:</p>
                
                <h3 className="text-xl font-medium mb-2 mt-4" style={{ fontFamily: 'InterSB' }}>
                  6.1 Information We Collect
                </h3>
                <p>We collect the following types of information:</p>
                <ul className="list-disc pl-6 space-y-2">
                  <li><strong>Account Information:</strong> When you create an account, we collect your name, email address, phone number, and password.</li>
                  <li><strong>Profile Information:</strong> For service providers, we collect professional details, photos, service descriptions, and rates that you choose to provide.</li>
                  <li><strong>Payment Information:</strong> When you make a payment, our payment processor (Paystation) collects your payment card details. We do not store this information on our servers.</li>
                  <li><strong>Usage Information:</strong> We collect information about how you use our platform, including log data, device information, and browsing activity.</li>
                </ul>
                
                <h3 className="text-xl font-medium mb-2 mt-4" style={{ fontFamily: 'InterSB' }}>
                  6.2 How We Use Your Information
                </h3>
                <p>We use your information for the following purposes:</p>
                <ul className="list-disc pl-6 space-y-2">
                  <li>To provide and maintain our services</li>
                  <li>To process payments and manage subscriptions</li>
                  <li>To verify identity and prevent fraud</li>
                  <li>To communicate with you about service updates or inquiries</li>
                  <li>To improve our platform and user experience</li>
                  <li>To comply with legal obligations</li>
                </ul>
                
                <h3 className="text-xl font-medium mb-2 mt-4" style={{ fontFamily: 'InterSB' }}>
                  6.3 Data Security
                </h3>
                <p className="mb-2">
                  We implement appropriate technical and organizational measures to protect your personal 
                  information against unauthorized access, alteration, disclosure, or destruction. These 
                  measures include:
                </p>
                <ul className="list-disc pl-6 space-y-2">
                  <li>Encryption of sensitive data</li>
                  <li>Security assessments</li>
                  <li>Access controls and authentication requirements</li>
                  <li>Employee training on data protection practices</li>
                </ul>
                <p className="mt-2">
                  For payment processing, we use Paystation, a fully PCI DSS compliant certified Solutions 
                  Provider, to ensure your payment information is handled according to the highest security standards.
                </p>
                
                <h3 className="text-xl font-medium mb-2 mt-4" style={{ fontFamily: 'InterSB' }}>
                  6.4 Data Sharing and Disclosure
                </h3>
                <p className="mb-2">
                  We do not sell your personal information to third parties. We may share your information in 
                  the following circumstances:
                </p>
                <ul className="list-disc pl-6 space-y-2">
                  <li><strong>Service Providers:</strong> We share information with trusted service providers who help us operate our platform, such as payment processors and hosting services.</li>
                  <li><strong>Legal Requirements:</strong> We may disclose information if required by law, regulation, or legal process.</li>
                  <li><strong>Business Transfers:</strong> If our business is acquired or merged with another company, your information may be transferred as part of that transaction.</li>
                </ul>
                
                <h3 className="text-xl font-medium mb-2 mt-4" style={{ fontFamily: 'InterSB' }}>
                  6.5 Your Rights
                </h3>
                <p className="mb-2">
                  Under the Privacy Act 2020, you have the following rights:
                </p>
                <ul className="list-disc pl-6 space-y-2">
                  <li>The right to access your personal information</li>
                  <li>The right to correct inaccurate information</li>
                  <li>The right to request deletion of your information</li>
                  <li>The right to be informed about the collection and use of your data</li>
                  <li>The right to object to processing of your data in certain circumstances</li>
                </ul>
                <p className="mt-2">
                  To exercise these rights, please contact us at workinggirlescorts@gmail.com.
                </p>
              </div>
              </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;