import React from 'react';
import { Shield, Star, Award } from 'lucide-react';
import { useNavigate, useLocation } from 'react-router-dom';
import Footer from '../components/Footer';
import '../styles/fonts.css';
import { useAuth } from '../context/AuthContext';

const SubscriptionPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated } = useAuth();

  const tiers = [
    {
      name: 'Standard',
      price: '35.00 NZD',
      interval: ' week',
      icon: <Shield className="w-6 h-6 text-blue-500" />,
      description: 'Perfect for independent escorts',
      features: [
        'Basic profile listing',
        'Up to 10 photos',
        'Analytics',
        'Standard support'
      ],
      color: 'from-gray-500 to-black',
      recommended: false
    },
    {
      name: 'Featured',
      price: '75.00 NZD',
      interval: ' week',
      icon: <Star className="w-6 h-6 text-purple-500" />,
      description: 'Enhanced visibility and premium features',
      features: [
        'Priority placement in search results',
        'Priority placement on homepage',
        'Up to 20 photos',
        'Analytics',
        'Premium support',
        'Featured tag on listing'
      ],
      color: 'from-elgrad to-elink',
      recommended: true
    }
  ];

  const handleSubscribe = (tierName) => {
    // const token = localStorage.getItem('token');
    
    if (!isAuthenticated) {
      // Save the current page URL in state when redirecting
      navigate('/login', { 
        state: { 
          from: location.pathname,
          subscribeTier: tierName 
        } 
      });
      return;
    }

    // If user is logged in, proceed with subscription
    console.log(`Subscribing to ${tierName} tier`);
    // Add payment integration logic here
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="max-w-5xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h1 
            className="text-3xl font-light text-gray-900 pb-4"
            style={{ fontFamily: 'InterSB' }}
          >
            Choose Your Subscription
          </h1>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Select the perfect plan for your profile listing. All plans include basic profile features
            and can be cancelled at any time.
          </p>
        </div>

        <div className="mt-12 grid gap-8 lg:grid-cols-2">
          {tiers.map((tier) => (
            <div
              key={tier.name}
              className={`relative rounded-2xl bg-white shadow-lg`}
            >
              {tier.recommended && (
                <div className="absolute -top-4 left-0 right-0 mx-auto w-32">
                  <div className="bg-gradient-to-b from-elgrad to-elink text-white text-sm py-1 px-4 rounded-full text-center font-medium">
                    Recommended
                  </div>
                </div>
              )}
              
              <div className="p-8 flex flex-col h-full">
                <div className="flex items-center justify-between">
                  <div>
                    <h3 
                      className="text-xl font-semibold text-gray-900"
                      style={{ fontFamily: 'InterSB' }}
                    >
                      {tier.name}
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">{tier.description}</p>
                  </div>
                  {tier.icon}
                </div>

                <div className="mt-6">
                  <p className="flex items-baseline">
                    <span 
                      className="text-4xl font-semibold text-gray-900"
                      style={{ fontFamily: 'InterSB' }}
                    >
                      ${tier.price}
                    </span>
                    <span className="ml-2 text-gray-500">/{tier.interval}</span>
                  </p>
                </div>

                <ul className="mt-8 space-y-4 flex-grow">
                  {tier.features.map((feature) => (
                    <li key={feature} className="flex items-center">
                      <svg className="w-5 h-5 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                      </svg>
                      <span className="ml-3 text-gray-600">{feature}</span>
                    </li>
                  ))}
                </ul>

                <div className="mt-8">
                  <button
                    onClick={() => handleSubscribe(tier.name)}
                    className={`w-full bg-gradient-to-b ${tier.color} text-white px-6 py-3 rounded-full text-sm font-medium hover:opacity-90 transition-opacity`}
                    style={{ fontFamily: 'InterSB' }}
                  >
                    Subscribe to {tier.name}
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Additional required information */}
        <div className="mt-12 bg-white rounded-lg shadow-lg p-8">
          <div className="max-w-3xl mx-auto">
            <h2 className="text-xl font-semibold mb-6" style={{ fontFamily: 'InterSB' }}>
              Subscription Information
            </h2>
            
            <div className="space-y-6">
              <div>
                <h3 className="text-lg font-medium mb-2" style={{ fontFamily: 'InterSB' }}>
                  Service Activation
                </h3>
                <p className="text-gray-600">
                  Your subscription will be activated immediately upon successful payment processing. 
                  Profile listings will be reviewed within 48 hours before being published, should the profile not meet our terms of service, you will be notified of this and advised on compliance. If you fail to respond within 24 hours, or do not wish to proceed with the nessasary changes, your subscription will be terminated as per our terms of service.
                </p>
              </div>
              
              <div>
                <h3 className="text-lg font-medium mb-2" style={{ fontFamily: 'InterSB' }}>
                  Cancellation Policy
                </h3>
                <p className="text-gray-600">
                  You may cancel your subscription at any time. Cancellations will take effect at the end of your 
                  current billing period. No partial refunds are provided for unused subscription time. We reserve the right to cancel
                  subscriptions at any time for violation of our terms of service without refund. See our terms of service for more information.
                </p>
              </div>
              
              <div>
                <h3 className="text-lg font-medium mb-2" style={{ fontFamily: 'InterSB' }}>
                  Additional Charges
                </h3>
                <p className="text-gray-600">
                  All prices are inclusive of GST. There are no hidden fees or additional charges beyond the 
                  subscription price displayed.
                </p>
              </div>
              
              <div>
                <h3 className="text-lg font-medium mb-2" style={{ fontFamily: 'InterSB' }}>
                  Regional Restrictions
                </h3>
                <p className="text-gray-600">
                  Our services are exclusively for providers and clients within New Zealand. International clients 
                  may browse the directory, but service arrangements must comply with all applicable New Zealand laws.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-8 bg-white rounded-lg shadow-lg p-8 text-center">
          <h2 className="text-xl font-semibold mb-4" style={{ fontFamily: 'InterSB' }}>
            Secure Payment Processing
          </h2>
          
          <p className="text-gray-600 mb-6">
            Your payment details have the security offered by Paystation, a fully PCI DSS compliant certified Solutions Provider.
            Prices are in NZD and include all applicable taxes.
          </p>
          
          <div className="flex justify-center space-x-6 mb-6">
            <img src="/images/visa-logo.png" alt="Visa" className="h-10" />
            <img src="/images/mc-logo.png" alt="Mastercard" className="h-12" />
            <img src="/images/unionpay-logo.png" alt="UnionPay" className="h-12" />
          </div>
          
          <p className="text-sm text-gray-500">
            Workinggirl.co.nz Limited is a business operating in New Zealand. Our principal place of business is located at 
            24 Dress Circle, Newlands, Wellington, 6037, New Zealand.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SubscriptionPage;