// import React, { useState } from 'react';
// import fireEmoji from './fireemoji.png';
// import verified from './verified.svg';

// const AdminModelCards = ({ filteredModels, onModelClick }) => {
//   const [modelsState, setModelsState] = useState(filteredModels);

//   const handleHotToggle = async (userId, currentHotState) => {
//     try {
//       const response = await fetch(`http://localhost:3001/api/models/${userId}/hot`, {
//         method: 'PUT',
//         headers: {
//           'Authorization': `Bearer ${localStorage.getItem('token')}`,
//           'Content-Type': 'application/json'

//         },
//         body: JSON.stringify({ hot: !currentHotState })
//       });

//       if (response.ok) {
//         setModelsState(prevModels =>
//           prevModels.map(model =>
//             model.userId === userId ? { ...model, hot: (!currentHotState).toString() } : model
//           )
//         );
//       }
//     } catch (error) {
//       console.error('Error updating hot status:', error);
//     }
//   };

//   const handlePositionChange = async (userId, imageUrl, newPosition) => {
//     try {
//       const response = await fetch(`http://localhost:3001/api/models/${userId}/position`, {
//         method: 'PUT',
//         headers: {
//           'Authorization': `Bearer ${localStorage.getItem('token')}`,
//           'Content-Type': 'application/json'
//         },
//         body: JSON.stringify({ imageUrl, position: newPosition })
//       });

//       if (response.ok) {
//         const updatedResponse = await fetch('http://localhost:3001/api/models');
//         const updatedModels = await updatedResponse.json();
//         setModelsState(updatedModels);
//       }
//     } catch (error) {
//       console.error('Error updating position:', error);
//     }
//   };

//   if (!modelsState || modelsState.length === 0) {
//     return <div className="p-6">No models available</div>;
//   }

//   const processedModels = modelsState.map(model => {
//     const imageUrlArray = model.ImageUrls ? model.ImageUrls.split(',') : [];
//     const positionsArray = model.positions ? model.positions.split(',').map(Number) : [];
    
//     let primaryImageUrl = imageUrlArray[0];
//     if (positionsArray.includes(1)) {
//       const primaryIndex = positionsArray.indexOf(1);
//       primaryImageUrl = imageUrlArray[primaryIndex];
//     }

//     return {
//       ...model,
//       ImageUrl: primaryImageUrl,
//       allImages: imageUrlArray,
//       positions: positionsArray
//     };
//   });

//   return (
//     <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 px-4 max-w-7xl mx-auto">
//       {processedModels.map((model) => (
//         <div key={model.userId} className="bg-white overflow-hidden rounded-lg shadow-lg">
//           <div className="p-4 bg-gray-50 border-b">
//             <div className="flex items-center justify-between mb-4">
//               <h3 className="text-lg font-semibold text-gray-800">Admin Controls</h3>
//             </div>
            
//             <div className="flex items-center gap-4 mb-4">
//               <div className="flex items-center gap-2">
//                 <label className="text-sm font-medium text-gray-700">Hot Status:</label>
//                 <button
//                   onClick={() => handleHotToggle(model.userId, model.hot === 'true')}
//                   className={`relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 ${
//                     model.hot === 'true' ? 'bg-elink' : 'bg-gray-400'
//                   }`}
//                 >
//                   <span
//                     className={`inline-block h-4 w-4 transform rounded-full bg-white transition-transform ${
//                       model.hot === 'true' ? 'translate-x-6' : 'translate-x-1'
//                     }`}
//                   />
//                 </button>
//               </div>
//             </div>

//           </div>

//           <div className="aspect-[3/4] relative overflow-hidden">
//             {model.accountType === 'featured' && (
//               <div className="bg-custom-gold h-22 absolute top-2 left-2 z-10 flex items-center gap-1 rounded-full px-1 py-0">
//                 <div className='w-5 h-5 rounded-full flex items-center justify-center text-dgold'>
//                   <svg width="18" height="18" viewBox="0 0 19 19" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
//                     <path d="M6.33398 14.2502L9.50065 11.8356L12.6673 14.2502L11.4798 10.3314L14.6465 8.07516H10.7673L9.50065 3.9585L8.23398 8.07516H4.35482L7.52148 10.3314L6.33398 14.2502ZM9.50065 17.4168C8.40551 17.4168 7.37635 17.209 6.41315 16.7934C5.44996 16.3778 4.61211 15.8137 3.89961 15.1012C3.18711 14.3887 2.62305 13.5509 2.20742 12.5877C1.7918 11.6245 1.58398 10.5953 1.58398 9.50016C1.58398 8.40502 1.7918 7.37586 2.20742 6.41266C2.62305 5.44947 3.18711 4.61162 3.89961 3.89912C4.61211 3.18662 5.44996 2.62256 6.41315 2.20693C7.37635 1.79131 8.40551 1.5835 9.50065 1.5835C10.5958 1.5835 11.625 1.79131 12.5882 2.20693C13.5513 2.62256 14.3892 3.18662 15.1017 3.89912C15.8142 4.61162 16.3783 5.44947 16.7939 6.41266C17.2095 7.37586 17.4173 8.40502 17.4173 9.50016C17.4173 10.5953 17.2095 11.6245 16.7939 12.5877C16.3783 13.5509 15.8142 14.3887 15.1017 15.1012C14.3892 15.8137 13.5513 16.3778 12.5882 16.7934C11.625 17.209 10.5958 17.4168 9.50065 17.4168Z"/>
//                   </svg>
//                 </div>
//                 <span className="text-gold" style={{ fontFamily: 'InterSB' }}>FEATURED</span>
//               </div>
//             )}
//             {model.hot === 'true' && (
//               <div className="bg-white shadow-md shadow-lightred w-97 h-22 absolute top-2 left-2 z-10 flex items-center gap-2 rounded-full px-2 py-0">
//                 <img src={fireEmoji} className="w-3" alt="hot"/>
//                 <span className="text-red" style={{ fontFamily: 'InterSB' }}>Hot</span>
//               </div>
//             )}
//             <img
//               src={`http://localhost:3001${model.ImageUrl}`}
//               alt={`${model.firstName || 'Model'}`}
//               className="object-cover w-full h-full"
//               onError={(e) => {
//                 e.target.src = '/api/placeholder/300/400';
//               }}
//             />
//           </div>

//           <div className="p-4">
//             <div className="flex justify-between items-center">
//               <h3 className="text-xl font-semibold text-gray-800 flex items-center gap-2">
//                 {model.firstName} {model.lastName}
//                 <span className="text-gray-500 flex items-center gap-1">
//                   {model.age}
//                   {model.verified === 'true' && (
//                     <img src={verified} className="w-5 h-5 inline-block" alt="verified"/>
//                   )}
//                 </span>
//               </h3>
//             </div>
//             <p className="text-gray-600 mt-2">{model.tagLine}</p>
//             <div className="mt-4 flex justify-between items-center">
//               <span className="text-gray-500">{model.location}</span>
//               <span className="text-black font-medium">${model.rate60}/hr</span>
//             </div>
//           </div>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default AdminModelCards;






import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import fireEmoji from './fireemoji.png';
import verified from './verified.svg';


const AdminModelCards = ({ filteredModels, onModelClick }) => {
  const [models, setModels] = useState([]);
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    if (!filteredModels || filteredModels.length === 0) {
      setIsLoading(false);
      return;
    }

    const processedModels = filteredModels.map((model, index) => {
      const imageUrlArray = model.ImageUrls ? model.ImageUrls.split(',') : [];
      const positionsArray = model.positions ? model.positions.split(',').map(Number) : [];
      
      let primaryImageUrl = imageUrlArray[0];
      if (positionsArray.includes(1)) {
        const primaryIndex = positionsArray.indexOf(1);
        primaryImageUrl = imageUrlArray[primaryIndex];
      }

      // Ensure userId exists and is converted to string
      const safeUserId = model.userId ? model.userId.toString() : `temp-${index}`;

      return {
        ...model,
        id: safeUserId, // Add an id field for react-beautiful-dnd
        userId: safeUserId,
        ImageUrl: primaryImageUrl,
        allImages: imageUrlArray,
        positions: positionsArray,
        order: model.position || index + 1
      };
    });

    const sortedModels = processedModels.sort((a, b) => a.order - b.order);
    setModels(sortedModels);
    setIsLoading(false);
  }, [filteredModels]);

  const handleDragEnd = async (result) => {
    if (!result.destination) return;

    const items = Array.from(models);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    const updatedItems = items.map((item, index) => ({
      ...item,
      order: index + 1
    }));

    setModels(updatedItems);

    try {
      const token = localStorage.getItem('token');
      const updates = updatedItems.map((item) => ({
        userId: item.userId,
        position: item.order
      }));

      const response = await fetch('http://localhost:3001/api/models/positions', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ updates })
      });

      if (!response.ok) {
        throw new Error('Failed to update positions');
      }
    } catch (error) {
      console.error('Error updating positions:', error);
    }
  };

  const handleHotToggle = async (userId, currentHotState) => {
    try {
      const response = await fetch(`http://localhost:3001/api/models/${userId}/hot`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ hot: !currentHotState })
      });

      if (response.ok) {
        setModels(prevModels =>
          prevModels.map(model =>
            model.userId === userId ? { ...model, hot: (!currentHotState).toString() } : model
          )
        );
      }
    } catch (error) {
      console.error('Error updating hot status:', error);
    }
  };

  if (isLoading) {
    return <div className="p-6">Loading...</div>;
  }

  if (!models || models.length === 0) {
    return <div className="p-6">No models available</div>;
  }


  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="models">
        {(provided) => (
          <div 
            {...provided.droppableProps} 
            ref={provided.innerRef}
            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 px-4 max-w-7xl mx-auto"
          >
            {models.map((model, index) => (
                <Draggable 
                    key={model.userId}
                    draggableId={model.userId}
                    index={index}
>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    className={`bg-white rounded-lg shadow-lg overflow-hidden ${
                      snapshot.isDragging ? 'shadow-2xl ring-2 ring-blue-500' : ''
                    }`}
                  >
                    <div 
                      {...provided.dragHandleProps}
                      className="p-2 bg-gray-50 border-b cursor-move hover:bg-gray-100 transition-colors"
                    >
                      <div className="flex items-center justify-between">
                        <span className="models text-sm font-medium text-gray-600">Position: {model.order}</span>
                        <div className="flex items-center gap-2">
                          <button
                            onClick={() => handleHotToggle(model.userId, model.hot === 'true')}
                            className={`relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 ${
                              model.hot === 'true' ? 'bg-elink' : 'bg-gray-400'
                            }`}
                          >
                            <span
                              className={`inline-block h-4 w-4 transform rounded-full bg-white transition-transform ${
                                model.hot === 'true' ? 'translate-x-6' : 'translate-x-1'
                              }`}
                            />
                          </button>
                          <span className="text-gray-400">⋮⋮</span>
                        </div>
                      </div>
                    </div>

                    <div className="aspect-[3/4] relative overflow-hidden">
                    {model.accountType === 'featured' && (
              <div className="bg-custom-gold h-22 absolute top-2 left-2 z-10 flex items-center gap-1 rounded-full px-1 py-0">
                <div className='w-5 h-5 rounded-full flex items-center justify-center text-dgold'>
                  <svg width="18" height="18" viewBox="0 0 19 19" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.33398 14.2502L9.50065 11.8356L12.6673 14.2502L11.4798 10.3314L14.6465 8.07516H10.7673L9.50065 3.9585L8.23398 8.07516H4.35482L7.52148 10.3314L6.33398 14.2502ZM9.50065 17.4168C8.40551 17.4168 7.37635 17.209 6.41315 16.7934C5.44996 16.3778 4.61211 15.8137 3.89961 15.1012C3.18711 14.3887 2.62305 13.5509 2.20742 12.5877C1.7918 11.6245 1.58398 10.5953 1.58398 9.50016C1.58398 8.40502 1.7918 7.37586 2.20742 6.41266C2.62305 5.44947 3.18711 4.61162 3.89961 3.89912C4.61211 3.18662 5.44996 2.62256 6.41315 2.20693C7.37635 1.79131 8.40551 1.5835 9.50065 1.5835C10.5958 1.5835 11.625 1.79131 12.5882 2.20693C13.5513 2.62256 14.3892 3.18662 15.1017 3.89912C15.8142 4.61162 16.3783 5.44947 16.7939 6.41266C17.2095 7.37586 17.4173 8.40502 17.4173 9.50016C17.4173 10.5953 17.2095 11.6245 16.7939 12.5877C16.3783 13.5509 15.8142 14.3887 15.1017 15.1012C14.3892 15.8137 13.5513 16.3778 12.5882 16.7934C11.625 17.209 10.5958 17.4168 9.50065 17.4168Z"/>
                  </svg>
                </div>
                <span className="text-gold" style={{ fontFamily: 'InterSB' }}>FEATURED</span>
              </div>
            )}
                    {model.hot === 'true' && (
                    <div className="bg-white shadow-md shadow-lightred w-97 h-22 absolute top-2 left-2 z-10 flex items-center gap-2 rounded-full px-2 py-0">
                        <img src={fireEmoji} className="w-3" alt="hot"/>
                        <span className="text-red" style={{ fontFamily: 'InterSB' }}>Hot</span>
                    </div>
                    )}
                      <img
                        src={`http://localhost:3001${model.ImageUrl}`}
                        alt={`${model.firstName || 'Model'}`}
                        className="object-cover w-full h-full"
                        onError={(e) => {
                          e.target.src = '/api/placeholder/300/400';
                        }}
                      />
                    </div>

                    <div className="p-4">
                      <div className="flex justify-between items-center">
                        <h3 className="text-xl font-semibold text-gray-800 flex items-center gap-2">
                          {model.firstName} {model.lastName}
                          <span className="text-gray-500 flex items-center gap-1">
                            {model.age}
                            {model.verified === 'true' && (
                              <img src={verified} className="w-5 h-5 inline-block" alt="verified"/>
                            )}
                          </span>
                        </h3>
                      </div>
                      <p className="text-gray-600 mt-2">{model.tagLine}</p>
                      <div className="mt-4 flex justify-between items-center">
                        <span className="text-gray-500">{model.location}</span>
                        <span className="text-black font-medium">${model.rate60}/hr</span>
                      </div>
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default AdminModelCards;