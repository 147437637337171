import React, { useState, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ModelCards from './ModelCards'; 
import BASE_URL from '../config';

const calculateMatchScore = (searchTerm, model) => {
  if (!searchTerm || !model) return 0;

  const normalizedSearch = searchTerm.toLowerCase().trim();
  const fullName = `${model.firstName} ${model.lastName}`.toLowerCase();
  const reversedFullName = `${model.lastName} ${model.firstName}`.toLowerCase();


  if (fullName === normalizedSearch || reversedFullName === normalizedSearch) {
    return 1000; 
  }
  
  if (normalizedSearch.length === 1) {
    return fullName.includes(normalizedSearch) ? 1 : 0;
  }

  let hasConsecutiveLetters = false;
  for (let i = 0; i < normalizedSearch.length - 1; i++) {
    const consecutiveLetters = normalizedSearch.slice(i, i + 2);
    if (fullName.includes(consecutiveLetters)) {
      hasConsecutiveLetters = true;
      break;
    }
  }

  if (!hasConsecutiveLetters) return 0;
  
  if (fullName.startsWith(normalizedSearch)) return 900;
  
  if (fullName.includes(normalizedSearch)) return 800;

  let consecutiveScore = 0;
  for (let i = 0; i < normalizedSearch.length - 1; i++) {
    const consecutiveLetters = normalizedSearch.slice(i, i + 2);
    if (fullName.includes(consecutiveLetters)) {
      consecutiveScore += 50;
    }
  }

  const matchedChars = normalizedSearch.split('').filter(char => fullName.includes(char)).length;
  const partialScore = matchedChars * 10;
  
  return consecutiveScore + partialScore;
};

const SearchResultsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [models, setModels] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');


  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const term = searchParams.get('q') || '';
    setSearchTerm(term);

    const fetchModels = async () => {
      try {
        const response = await fetch(`${BASE_URL}/api/models`);
        const data = await response.json();
        setModels(data);
        console.log('Fetched models:', data);
      } catch (error) {
        console.error('Error fetching models:', error);
      }
    };

    fetchModels();
  }, [location.search]);

  const searchResults = useMemo(() => {
    if (!searchTerm || !models.length) return [];

    const scoredResults = models
      .map(model => ({
        ...model,
        score: calculateMatchScore(searchTerm, model)
      }))
      .filter(model => model.score > 0)
      .sort((a, b) => b.score - a.score);

    const exactMatches = scoredResults.filter(model => model.score === 1000);
    return exactMatches.length > 0 ? exactMatches : scoredResults;
  }, [searchTerm, models]);

  const handleModelClick = (userId) => {
    navigate(`/profile/${userId}`);
  };

  console.log('Search Term:', searchTerm);
  console.log('Search Results:', searchResults);

  return (
    <div className="min-h-screen bg-white p-4">
      <div className="container mx-auto max-w-7xl">
        <h1 className="text-2xl font-bold mb-6">
          Search Results for "{searchTerm}"
        </h1>

        {searchResults.length === 0 ? (
          <div className="text-center text-gray-600">
            No models found matching your search.
          </div>
        ) : (
          <ModelCards 
            filteredModels={searchResults} 
            onModelClick={handleModelClick}
            searchTerm={searchTerm}
          />
        )}
      </div>
    </div>
  );
};

export default SearchResultsPage;