import React from 'react'; 
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import LoginPage from './pages/LoginPage';
import Dashboard from './pages/Dashboard';
import LandingPage from './pages/LandingPage';
import { AuthProvider } from './context/AuthContext';
import { useAuth } from './context/AuthContext';
import Header from './components/Header';
import ProfileView from './components/ProfileView';
import LocationPage from './components/LocationPage';
import SearchResultsPage from './components/SearchResults';
import FavoritesPage from './components/FavoritesPage';
import SubscriptionPage from './components/SubscriptionsPage';
import VerificationPage from './pages/VerificationPage';
import ResetPassword from './pages/ResetPassword';
import ScrollToTop from './components/ScrollToTop';
import AgeVerification from './components/Verification';
import PrivacyPolicy from './pages/Privacy';
import ContactPage from './pages/Contact';
import FAQPage from './pages/Faq';
import AboutPage from './pages/About';
import TermsOfService from './pages/Terms';
import ManageSubscription from './pages/ManageSubscription';
import './index.css';
import './App.css';
import EscortProfile from './components/Profile';

// const ProtectedRoute = ({ children }) => {
//   const token = localStorage.getItem('token');
//   const location = useLocation();

//   if (!token) {
//     return <Navigate to="/login" state={{ from: location.pathname }} replace />;
//   }

//   return children;
// };

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location.pathname }} replace />;
  }

  return children;
};

const Layout = ({ children }) => {
  const location = useLocation();
  const isProfilePage = location.pathname.startsWith('/profile');
  const isDashboardRoute = location.pathname.toLowerCase().startsWith('/dashboard');
  
  return (
    <>
      {!isProfilePage && !isDashboardRoute && <Header />}
      {children}
    </>
  );
};
function App() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>WorkingGirl</title>
        <meta name="description" content="WorkingGirl - New Zealands Leading Escorts Directory" />
        <meta property="og:title" content="Open Graph Title" />
        <meta property="og:description" content="Open Graph description" />
      </Helmet>
    <AuthProvider>
    <AgeVerification />
      <Router>
      <ScrollToTop /> 
        <Layout>
        <main className="flex-grow">
            <Routes>
            <Route path="/" element={<LandingPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/locations/:location" element={<LocationPage />} />
              <Route path="/search" element={<SearchResultsPage />} />
              <Route path="/view/:modelId" element={<ProfileView />} />
              <Route path="/favorites" element={<FavoritesPage />} />
              <Route path="/reset-password/:token" element={<ResetPassword />} />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="/faq" element={<FAQPage />} />
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/terms" element={<TermsOfService />} />
              <Route path="/subscriptions" element={<SubscriptionPage />} />

              {/* Protected Routes */}
              <Route path="/dashboard" element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              } />
              <Route path="/verification" element={
                <ProtectedRoute>
                  <VerificationPage />
                </ProtectedRoute>
              } />
              <Route path="/managesub" element={
                <ProtectedRoute>
                  <ManageSubscription />
                </ProtectedRoute>
              } />
              <Route path="/profile/new" element={
                <ProtectedRoute>
                  <EscortProfile />
                </ProtectedRoute>
              } />
              <Route path="/profile/edit/:userId" element={
                <ProtectedRoute>
                  <EscortProfile />
                </ProtectedRoute>
              } />
              <Route path="/profile/edit/:modelId" element={
                <ProtectedRoute>
                  <EscortProfile />
                </ProtectedRoute>
              } />
              {/* <Route path="/subscriptions" element={
                <ProtectedRoute>
                  <SubscriptionPage />
                </ProtectedRoute>
              } /> */}

              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </main>
        </Layout>
      </Router>
    </AuthProvider>
  </HelmetProvider>
  );
}

export default App;