// import React, { useState, useEffect } from 'react';
// import { useParams, useNavigate } from 'react-router-dom';
// import { Camera, Users, Award, Mail } from 'lucide-react';
// import { jwtDecode } from 'jwt-decode';
// import '../styles/fonts.css';
// import fireEmoji from '../components/fireemoji.png';
// import verified from '../components/verified.svg';
// import AdminModelCards from '../components/AdminModelCards';
// import ModelCards from '../components/ModelCards';
// import BASE_URL from '../config';
  


// const LocationPage = () => {
//   const { location } = useParams();
//   const [models, setModels] = useState([]);
//   const [isAdmin, setIsAdmin] = useState(false);
//   const navigate = useNavigate();

//   const handleModelClick = (modelId) => {
//     navigate(`/view/${modelId}`);
//   };

//   useEffect(() => {
//     const fetchLocationModels = async () => {
//       try {
//         const response = await fetch(`${BASE_URL}/api/LPmodels`);
//         const data = await response.json();
//         const locationModels = data.filter(model => model.location === location);
//         setModels(locationModels);
//       } catch (error) {
//         console.error('Error fetching models:', error);
//       }
//     };

//     const checkAdminStatus = async () => {
//       const token = localStorage.getItem('token');
//       if (token) {
//         const decodedToken = jwtDecode(token);
//         try {
//           const response = await fetch(`/api/type/${decodedToken.userId}`);
//           const data = await response.json();
//           setIsAdmin(data[0].accountType === 'admin');
//         } catch (error) {
//           console.error('Error checking admin status:', error);
//         }
//       }
//     };

//     fetchLocationModels();
//     checkAdminStatus();
//   }, [location]);

//   return (
//     <div className="min-h-screen bg-gray-100">
//       <div className="bg-white">
//         <div className="container mx-auto py-6">
//           <h1 
//             className="text-3xl font-light text-elblack text-left px-6 pb-4" 
//             style={{ fontFamily: 'InterSB' }}
//           >
//             {location} Escorts
//           </h1>
          
//           <p className="text-gray-600 px-6">
//             Explore escort advertisements from independent New Zealand escorts 
//             in the {location} area.
//           </p>
//         </div>
//       </div>

//       <div className="min-h-screen bg-white">
//         <div className="container mx-auto  md:px-6 py-0 h-6">
//           {isAdmin ? (
//             <AdminModelCards 
//               filteredModels={models} 
//               onModelClick={handleModelClick} 
//             />
//           ) : (
//             <ModelCards 
//               filteredModels={models} 
//               onModelClick={handleModelClick} 
//             />
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default LocationPage;
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Camera, Users, Award, Mail } from 'lucide-react';
import { jwtDecode } from 'jwt-decode';
import '../styles/fonts.css';
import fireEmoji from '../components/fireemoji.png';
import verified from '../components/verified.svg';
import AdminModelCards from '../components/AdminModelCards';
import ModelCards from '../components/ModelCards';
import BASE_URL from '../config';
import { CACHE_KEY, CACHE_DURATION } from '../constants/cache';
import Footer from '../components/Footer';

const LocationPage = () => {
  const { location } = useParams();
  const [models, setModels] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const handleModelClick = (modelId) => {
    navigate(`/view/${modelId}`);
  };

  const getModels = async () => {
    try {
      setIsLoading(true);
      
      // Check cache first
      const cachedData = localStorage.getItem(CACHE_KEY);
      if (cachedData) {
        const { timestamp, data } = JSON.parse(cachedData);
        const isExpired = Date.now() - timestamp > CACHE_DURATION;
        
        if (!isExpired && Array.isArray(data)) {
          console.log('Using cached data for location');
          const locationModels = data.filter(model => model.location === location);
          setModels(locationModels);
          setIsLoading(false);
          return;
        }
      }

      // Fetch fresh data if cache is expired or empty
      const response = await fetch(`${BASE_URL}/api/LPmodels`);
      const data = await response.json();
      
      // Update cache with all models
      localStorage.setItem(CACHE_KEY, JSON.stringify({
        timestamp: Date.now(),
        data: Array.isArray(data) ? data : []
      }));
      
      // Filter for current location
      const locationModels = data.filter(model => model.location === location);
      setModels(locationModels);
    } catch (error) {
      console.error('Error fetching models:', error);
      setModels([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const checkRecentUpdate = () => {
      const lastUpdate = localStorage.getItem('profileUpdated');
      if (lastUpdate) {
        const updateTime = parseInt(lastUpdate);
        const fiveMinutesAgo = Date.now() - (5 * 60 * 1000);
        
        if (updateTime > fiveMinutesAgo) {
          // Clear both flags and fetch fresh data
          localStorage.removeItem('profileUpdated');
          localStorage.removeItem(CACHE_KEY);
          getModels();
        }
      }
    };

    checkRecentUpdate();
    getModels();
  }, [location]);

  useEffect(() => {
    const checkAdminStatus = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        const decodedToken = jwtDecode(token);
        try {
          const response = await fetch(`/api/type/${decodedToken.userId}`);
          const data = await response.json();
          setIsAdmin(data[0].accountType === 'admin');
        } catch (error) {
          console.error('Error checking admin status:', error);
        }
      }
    };

    checkAdminStatus();
  }, []);

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <div className="bg-white">
        <div className="w-full bg-white">
          <div className="container mx-auto py-6 px-6 sm:px-6 lg:px-4">
            <h1 
              className="text-3xl font-light text-elblack text-left pb-4"
              style={{ fontFamily: 'InterSB' }}
            >
              {location} Escorts
            </h1>
            
            <p className="text-gray-600">
              Explore escort advertisements from independent New Zealand escorts 
              in the {location} area. Find adult and escort advertisements from agencies,
              sensual massage providers and other adult entertainers.
            </p>
          </div>
        </div>
      </div>

      <div className="bg-white border-b py-2">
        <div className="container mx-auto py-2 px-4">
          <div className="flex items-center gap-2">
            <div className="w-6 h-6 bg-custom-gold rounded-full flex items-center justify-center text-dgold">
              <svg width="19" height="19" viewBox="0 0 19 19" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.33398 14.2502L9.50065 11.8356L12.6673 14.2502L11.4798 10.3314L14.6465 8.07516H10.7673L9.50065
                3.9585L8.23398 8.07516H4.35482L7.52148 10.3314L6.33398 14.2502ZM9.50065 17.4168C8.40551 17.4168 7.37635
                17.209 6.41315 16.7934C5.44996 16.3778 4.61211 15.8137 3.89961 15.1012C3.18711 14.3887 2.62305 13.5509
                2.20742 12.5877C1.7918 11.6245 1.58398 10.5953 1.58398 9.50016C1.58398 8.40502 1.7918 7.37586 2.20742
                6.41266C2.62305 5.44947 3.18711 4.61162 3.89961 3.89912C4.61211 3.18662 5.44996 2.62256 6.41315
                2.20693C7.37635 1.79131 8.40551 1.5835 9.50065 1.5835C10.5958 1.5835 11.625 1.79131 12.5882
                2.20693C13.5513 2.62256 14.3892 3.18662 15.1017 3.89912C15.8142 4.61162 16.3783 5.44947
                16.7939 6.41266C17.2095 7.37586 17.4173 8.40502 17.4173 9.50016C17.4173 10.5953 17.2095
                11.6245 16.7939 12.5877C16.3783 13.5509 15.8142 14.3887 15.1017 15.1012C14.3892 15.8137
                13.5513 16.3778 12.5882 16.7934C11.625 17.209 10.5958 17.4168 9.50065 17.4168Z"/>
              </svg>
            </div>
            <h1>
              <span 
                className="text-2xl font-semibold text-gray-800" 
                style={{ fontFamily: 'InterSB' }}
              >
                Featured Girls
              </span>
            </h1>
          </div>
        </div>
      </div>

      <div className="bg-gray-100 bg-white flex-grow">
        <div className="container md:px-6 mx-auto py-0 pb-12">
          {isAdmin ? (
            <AdminModelCards
              filteredModels={models}
              onModelClick={handleModelClick}
              isLoading={isLoading}
            />
          ) : (
            <ModelCards
              filteredModels={models}
              onModelClick={handleModelClick}
              isLoading={isLoading}
            />
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LocationPage;