// import React, { useState, useEffect } from 'react';
// import ModelCards from './ModelCards';
// import { getHeartedModels } from './HeartSystem';
// import { useNavigate } from 'react-router-dom';
// import BASE_URL from '../config';

// const FavoritesPage = () => {
//     const [heartedModels, setHeartedModels] = useState([]);
//     const [isLoading, setIsLoading] = useState(true);
//     const navigate = useNavigate();

//     useEffect(() => {
//         const loadHeartedModels = async () => {
//             try {
//                 const heartedIds = getHeartedModels();
//                 if (heartedIds.length === 0) {
//                     setHeartedModels([]);
//                     setIsLoading(false);
//                     return;
//                 }

//                 const response = await fetch(`${BASE_URL}/api/LPmodels`);
//                 const allModels = await response.json();
                
//                 const heartedModelData = allModels.filter(model => 
//                     heartedIds.includes(model.modelId)
//                 );
                
//                 setHeartedModels(heartedModelData);
//             } catch (error) {
//                 console.error('Error loading hearted models:', error);
//             } finally {
//                 setIsLoading(false);
//             }
//         };

//         loadHeartedModels();

//         const handleStorageChange = () => loadHeartedModels();
//         window.addEventListener('heartedModelsUpdated', handleStorageChange);
        
//         return () => {
//             window.removeEventListener('heartedModelsUpdated', handleStorageChange);
//         };
//     }, []);

//     if (isLoading) {
//         return <div className="min-h-screen bg-gray-100 flex items-center justify-center">
//             Loading...
//         </div>;
//     }

//     return (
//     <div className="min-h-screen bg-gray-100">
//       <div className="bg-white">
//         <div className="container mx-auto py-6">
//           <h1 
//             className="text-3xl font-light text-elblack text-left px-6 pb-4" 
//             style={{ fontFamily: 'InterSB' }}
//           >
//             Favourites
//           </h1>
//           <p className="text-gray-600 px-6">
//             Reach out to one of your favorites from todays search.
//           </p>
//         </div>
//       </div>
//                 {heartedModels.length > 0 ? (
//                     <div className="bg-white p-2 rounded-sm md:px-6 shadow-lg">
//                     <ModelCards 
//                         filteredModels={heartedModels} 
//                         onModelClick={(modelId) => navigate(`/view/${modelId}`)} 
//                     /></div>
//                 ) : (
//                     <div className="text-center text-gray-500 py-12">
//                         No favorite models yet
//                     </div>
//                 )}
//             </div>
//     );
// };

// export default FavoritesPage;
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ModelCards from './ModelCards';
import { getHeartedModels } from './HeartSystem';
import BASE_URL from '../config';
import { CACHE_KEY, CACHE_DURATION } from '../constants/cache';
import Footer from '../components/Footer';

const FavoritesPage = () => {
    const [heartedModels, setHeartedModels] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    const loadHeartedModels = async () => {
        try {
            setIsLoading(true);
            const heartedIds = getHeartedModels();
            
            if (heartedIds.length === 0) {
                setHeartedModels([]);
                return;
            }

            // Check cache first
            const cachedData = localStorage.getItem(CACHE_KEY);
            if (cachedData) {
                const { timestamp, data } = JSON.parse(cachedData);
                const isExpired = Date.now() - timestamp > CACHE_DURATION;
                
                if (!isExpired && Array.isArray(data)) {
                    console.log('Using cached data for favorites');
                    const heartedModelData = data.filter(model => 
                        heartedIds.includes(model.modelId)
                    );
                    setHeartedModels(heartedModelData);
                    return;
                }
            }

            // Fetch fresh data if cache is expired or empty
            const response = await fetch(`${BASE_URL}/api/LPmodels`);
            const allModels = await response.json();
            
            // Update cache with all models
            localStorage.setItem(CACHE_KEY, JSON.stringify({
                timestamp: Date.now(),
                data: Array.isArray(allModels) ? allModels : []
            }));
            
            const heartedModelData = allModels.filter(model => 
                heartedIds.includes(model.modelId)
            );
            
            setHeartedModels(heartedModelData);
        } catch (error) {
            console.error('Error loading hearted models:', error);
            setHeartedModels([]);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const checkRecentUpdate = () => {
            const lastUpdate = localStorage.getItem('profileUpdated');
            if (lastUpdate) {
                const updateTime = parseInt(lastUpdate);
                const fiveMinutesAgo = Date.now() - (5 * 60 * 1000);
                
                if (updateTime > fiveMinutesAgo) {
                    // Clear both flags and fetch fresh data
                    localStorage.removeItem('profileUpdated');
                    localStorage.removeItem(CACHE_KEY);
                    loadHeartedModels();
                }
            }
        };

        checkRecentUpdate();
        loadHeartedModels();

        const handleStorageChange = () => loadHeartedModels();
        window.addEventListener('heartedModelsUpdated', handleStorageChange);
        
        return () => {
            window.removeEventListener('heartedModelsUpdated', handleStorageChange);
        };
    }, []);

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col">
            <div className="bg-white">
                <div className="w-full bg-white">
                    <div className="container mx-auto py-6 px-6 sm:px-6 lg:px-4">
                        <h1 
                            className="text-3xl font-light text-elblack text-left pb-4"
                            style={{ fontFamily: 'InterSB' }}
                        >
                            Favourites
                        </h1>
                        <p className="text-gray-600">
                            Reach out to one of your favorites from today's search.
                        </p>
                    </div>
                </div>
            </div>

            {/* <div className="bg-white border-b py-2">
                <div className="container mx-auto py-2 px-4">
                    <div className="flex items-center gap-2">
                        <h1>
                            <span 
                                className="text-2xl font-semibold text-gray-800" 
                                style={{ fontFamily: 'InterSB' }}
                            >
                                Your Favourites
                            </span>
                        </h1>
                    </div>
                </div>
            </div> */}

            <div className="bg-gray-100 bg-white flex-grow">
                <div className="container md:px-6 mx-auto py-0 pb-12">
                    {isLoading ? (
                        <div className="flex items-center justify-center py-12">
                            <div className="animate-pulse space-y-4">
                                <div className="h-4 bg-gray-200 rounded w-72"></div>
                                <div className="h-4 bg-gray-200 rounded w-64"></div>
                                <div className="h-4 bg-gray-200 rounded w-80"></div>
                            </div>
                        </div>
                    ) : heartedModels.length > 0 ? (
                        <ModelCards 
                            filteredModels={heartedModels} 
                            onModelClick={(modelId) => navigate(`/view/${modelId}`)}
                            isLoading={isLoading}
                        />
                    ) : (
                        <div className="text-center text-gray-500 py-12">
                            No favorite models yet
                        </div>
                    )}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default FavoritesPage;